import React, { useState } from "react";
import {httpsCallable } from "firebase/functions";
import { functions } from "../../firebaseConfig";
import PauseAccess from '../Members/Dialogs/PauseAccess'
import RevokeAccess from "../Members/Dialogs/RevokeAccess";
const ApproveCard = (props) => {
  const { setTimeStamp, data } = props;
  const [open,setOpen] = useState(false)
  const [openRevoke,setOpenRevoke] = useState(false)
  const [clicked, setClicked] = useState("");
  const handleClose = () => {
    setOpenRevoke(false)
    setOpen(false);
  };

  const handleClick = async (action) => {
    handleClose()
    setClicked("Processing");
    if (action === "approve") {
      // console.log("data",data);
      const pauseOrgAccess = httpsCallable(functions, "pauseOrgAccess");
      // console.log("Calling Pause Access");
      await pauseOrgAccess({
        orgId: data.requestId,
      });
      // console.log("Pause Access Called");
      setTimeStamp(Date.now);
      setClicked("Approved");
    } else if (action === "reject") {
      console.log("Revoking");
      const revokeAccess = httpsCallable(functions, "revokeAccess");
      await revokeAccess({
        orgId: data.requestId,
      });
      setClicked("Revoked");
      setTimeStamp(Date.now);
      console.log("Revoked");
    }
    else{
      console.log("handleClick Called without Parameter")
    }
  };

  return (
    <div className="request-card">
      <div className="company-info">
        <p>Organisation:</p>
        {data.orgName}
        <br />
        <p>Industry:</p>
        {data.industry}
        <br />
        <p>Organisation Size: </p>
        {data.orgSize}
        <br />
        <p>Contact Person:</p>
        {data.adminName}
        <br />
        <p>Email:</p>
        {data.adminEmail}
        <br />
        <p>Phone: </p>
        {data.phoneNo}
        <br />
      </div>
      {clicked === "" ? (
        <div className="button-container">
          <button
          aria-label="approve request"
          onClick={() =>  setOpen(true)}
          className="button-approve"
          >
            {data.status === "Active" ? "Pause" : "Resume"}
          </button>
          <button
            aria-label="reject request"
            onClick={() => setOpenRevoke(true)}
            className="button-reject"
          >
            Revoke
          </button>
        </div>
      ) : (
        <div className={clicked}>
          <p>{clicked}</p>
        </div>
      )}
      <PauseAccess name={data.orgName} uid={data.requestId} status={data.status} handleClick={handleClick} onClose={handleClose} open={open}/>
      <RevokeAccess open={openRevoke} handleClick={handleClick} name={data.orgName} uid={data.requestId} onClose={handleClose}/>
      <br />
    </div>
      
  );
};

export default ApproveCard;
