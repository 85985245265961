import React, { useState, useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { functions } from "../../../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { AuthContext } from "../../Context/AuthContext";

const InviteMember = (props) => {
  const { onClose, open } = props;
  const { clicked, setClicked } = useContext(AuthContext);
  const handleClose = () => {
    setClicked(false);
    onClose();
  };
  const createMember = httpsCallable(functions, "createMember");
  const [details, setDetails] = useState({
    email: "",
    role: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [res, setRes] = useState({});
  const createUser = async () => {
    // console.log(details);
    setClicked(true);
    // useAPI to create team
    await createMember({ ...details }).then((resp) => setRes(resp.data));

    // console.log("res", res);
    setClicked(false);
    onClose();
    setDetails({
      email: "",
      role: "",
    });
    return;
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "400px",
          padding: "16px",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <h1
        style={{
          flexDirection: "row",
          padding: "1.2rem",
          alignItems: "center",
          color: "#11047A",
          display: "flex",
          fontSize: "25px",
        }}
      >
        <p>Invite Member</p>
        <button
          onClick={handleClose}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "12px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </h1>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <TextField
          name="email"
          label="Email"
          value={details.email}
          onChange={handleInput}
          sx={{
            width: "100%",
            mt: "1em",
            color: "#11047A",
            background: "#F4F7FE",
          }}
        />

        <TextField
          select
          name="role"
          label="Role"
          value={details.role}
          onChange={handleInput}
          sx={{
            width: "100%",
            mt: "1em",
            color: "#11047A",
            background: "#F4F7FE",
          }}
        >
          <MenuItem value={""}></MenuItem>
          <MenuItem value={"Admin"}>Admin</MenuItem>
          <MenuItem value={"Manager"}>Manager</MenuItem>
          <MenuItem value={"Developer"}>Developer</MenuItem>
          <MenuItem value={"Tester"}>Tester</MenuItem>
          <MenuItem value={"Viewer"}>Viewer</MenuItem>
        </TextField>

        <Button
          fullWidth
          variant="contained"
          disabled={clicked}
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          onClick={createUser}
        >
          Send Invite
        </Button>
      </Box>
    </Dialog>
  );
};

export default InviteMember;
