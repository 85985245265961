import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";
import "./Automated.css";
import IssueSnippet from "./IssueSnippet";

import Arrow from "./Arrow";
import _ from "lodash";

export default function Automated(props) {
  // Group the issues by their category
  const group = _.groupBy(props.Fails, (value) => {
    // console.log("inside automated fails", value.id);
    return (value.category + "").toLowerCase();
  });

  // console.log("group",group);
  const url = props.url;
  let changeURL = "";
  console.log("inside automated", url);
  for (let k = 0; k < url.length; k++) {
    if (url[k] !== "/") {
      changeURL = changeURL.concat(url[k]);
    } else if (k !== url.length - 1) {
      changeURL = changeURL.concat("_");
    }
  }

  // Group the screenshots by Xpath (if applicable)
  const SSgroup = _.groupBy(props.Ss, "Xpath");

  return (
    <div className="accord_collection">
      {Object.keys(group).map((category) => {
        const group2 = _.groupBy(group[category], "ruleId");
        const name = category.charAt(0).toUpperCase() + category.slice(1);
        const rows = [];

        function createData(rule, description, severity, issue) {
          return { rule, description, severity, issue };
        }

        Object.keys(group2).map((ruleId) => {
          const row = createData(
            group2[ruleId].length + " " + ruleId,
            group2[ruleId][0].ruleDescription,
            group2[ruleId][0].severityLevel,
            group2[ruleId]
          );
          rows.push(row);
        });
        {/* console.log("rows inside automated",rows); */}

        return (
          <>
            <Accordion
              disableGutters
              sx={{
                backgroundColor: "#ffffff",
                border: "none",
                paddingTop: "24px",
                "&:before": {
                  display: "none",
                },
              }}
              className="accordion"
              elevation={0}
            >
              <AccordionSummary
                expandIcon={<Arrow />}
                sx={{ display: "inline-flex", cursor: "pointer" }}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h1 className="accordion_heading">
                  {group[category].length + " " + name}
                </h1>
              </AccordionSummary>
              <AccordionDetails>
                  <IssueSnippet
                    latestRequest={props.latestRequest}
                    userID={props.userID}
                    change={changeURL}
                    to={props.to}
                    badUrl={props.badUrl}
                    rows={rows}
                    url={props.url}
                    siteName={props.siteName}
                    issueArr={group[category]}
                    Ss={SSgroup}
                    Xpath={group[category].Xpath}
                    projectId={props.projectId}
                    fix={group[category].fix}
                    // issueId={row.issue.map(issue => issue.id)} // Pass the list of issue ids
                    path={props.path}
                  />
              </AccordionDetails>
            </Accordion>
            <br />
            <hr color="#E9EDF7" />
          </>
        );
      })}
    </div>
  );
}
