import React, { useContext, useEffect, useState } from "react";
import "./Security.css";
import Sidebar from "../Sidebar/Sidebar";
import { db, resetPassword, userStateValues } from "../../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Button } from "@mui/material";
import AdminSecurity from "./AdminSecurity";
import TesterSecurity from "./TesterSecurity";
import InviteOwner from "../Members/Dialogs/InviteOwner";

export default function Security() {
  const navigate = useNavigate();
  const { userState, setClicked } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchRequestsOwner = async () => {
    const colRef = collection(db, "Users");
    const queryRef = query(colRef, where("role", "==", "Owner"));
    const snap = await getDocs(queryRef);
    setUsers([]);
    for (let i = 0; i < snap.docs.length; i++) {
      let temp = snap.docs[i].data();
      // console.log("snap Data",temp);
      setUsers((prev) => [...prev, temp]);
    }
  };
  // console.log(users);

  const goToLogin = () => navigate("/");

  useEffect(() => {
    if (userState.uid === "") {
      goToLogin();
    }
    if (userState.role == "Owner") {
      fetchRequestsOwner();
    }
  }, []);
  const handleOpen = () => {
    setClicked(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = async () => {
    // console.log(userState);
    resetPassword(userState.email);
    window.alert("Sent Successfully");
    // console.log("Sent");
  };

  return (
    userState.uid !== "" && (
      <>
        <Sidebar />
        <main>
          <div className="security">
            <div className="top-item">
              <h1 className="heading">Security</h1>
            </div>

            {userState.role == "Owner" && (
              <>
                <div className="deatils">
                  <h3 className="accessEmail">
                    Active email IDs with owner access
                  </h3>
                  <div className="accessEmaiList">
                    {users?.map((data) => {
                      return <span className="emails">{data.email}</span>;
                    })}
                  </div>
                  <br />
                  <Button
                    onClick={handleOpen}
                    style={{
                      borderRadius: "70px",
                      width: "439px",
                      height: "46px",
                      border: "1px solid #A3AED0",
                    }}
                    variant="outlined"
                  >
                    Add Owner
                  </Button>
                  <br />
                  <h3 className="accessEmail">Reset Password</h3>
                  <Button
                    onClick={handleClick}
                    style={{
                      borderRadius: "70px",
                      width: "439px",
                      height: "46px",
                      border: "1px solid #A3AED0",
                    }}
                    variant="outlined"
                  >
                    Reset My Password
                  </Button>
                </div>

                <InviteOwner open={open} onClose={handleClose} />
              </>
            )}
            {(userState.role === "Admin" || userState.role == "Manager") && (
              <AdminSecurity handleClick={handleClick} />
            )}
            {(userState.role === "Tester" ||
              userState.role === "Developer") && (
              <TesterSecurity user={userState} />
            )}
          </div>
        </main>
      </>
    )
  );
}
