import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, Button } from "@mui/material";

const RevokeAccess = (props) => {
  const { handleClick , onClose , open, name} = props;
   
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "500px",
          padding: "16px",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          textAlign: "left",
          color: "#11047A",
        }}
      >
        <p>Revoke Access</p>
        <button
          onClick={handleClose}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "12px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </DialogTitle>
      <Box
        sx={{
          padding: "16px 24px",
          textAlign: "center",
        }}
      >
        <h3>
          Are you Sure You want to Revoke Access of {name}?
        </h3>
        <br />
        <p>This action is irreversible. It will delete all data related to {name}. They can create a new account and get started again.</p>
        <br />
        <Button aria-label="confirm reject" variant="contained" color="error" onClick={()=>handleClick("reject")}>
         Confirm Revoke
        </Button>
      </Box>
    </Dialog>
  );
};

export default RevokeAccess;
