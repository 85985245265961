import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import Arrow from "../Reports/Elements/ScannedResult/Elements/Automated/Arrow";
import Sidebar from "../Sidebar/Sidebar";
import DocSnippet from "./Elements/DocSnippet";
import "./Inventory.css";

export default function Inventory() {
  const [open, setOpen] = React.useState(false);
  const { userState } = useContext(AuthContext);
  const [Documents, setDocuments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [status, setStatus] = useState("");
  const [filter, setFilter] = useState("");
  const statusList = ["Not Scanned", "Mark for Remediation", "Passed"];

  const handleFilterChange = (event) => {
    const selectedProjectName = event.target.value;

    // Update filter state with the selected project name
    setFilter(selectedProjectName);

    // Find the selected project by name and set its ID
    const selectedProject = projects.find(
      (proj) => proj.projectName === selectedProjectName
    );

    console.log(selectedProject);
    if (selectedProject) {
      setProjectId(selectedProject.projId);
    } else {
      // If no project is selected (i.e., "Select Project" is selected), clear the projectId
      setProjectId("");
    }
  };
  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  // const [newDoc, setNewDoc] = useState({
  //   siteName: "",
  //   url: "",
  //   description: "",
  //   notes: "",
  //   status: "Not Scanned",
  //   time: new Date().toISOString(),
  //   type: "type",
  // });

  const navigate = useNavigate();

  const goToLogin = () => navigate("/");

  useEffect(() => {
    // console.log("role : ", userState.role);
    if (userState.set && userState.uid === "") {
      goToLogin();
    }
  }, [userState, goToLogin]);

  useEffect(() => {
    console.log("projectId", projectId);
    console.log(status);
    const fetchDocuments = async () => {
      try {
        const response = await axios.post(
          "https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testFetchDocuments",
          {
            projectId: projectId || undefined,
            status: status || undefined,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8",
            },
          }
        );

        // console.log(response.data);
        if (response.data && response.data.documentsGroupedBySite) {
          // console.log(response.data.documentsGroupedBySite)
          setDocuments(response.data.documentsGroupedBySite);
        }
      } catch (error) {
        console.error("Error fetching documents from API:", error);
      }
    };

    fetchDocuments();
  }, [projectId, status]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        // console.log("userState.uid",userState.uid);
        const response = await axios.post(
          "https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/getProjectsByUserId",
          {
            userId: userState.uid,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8",
            },
          }
        );

        if (response.data) {
          // console.log(response.data);
          setProjects(response.data.projects);
        }
      } catch (error) {
        console.error("Error fetching projects from API:", error.message);
      }
    };
    fetchProjects();
  }, [userState.uid]);
  // const handelInput = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setNewDoc({ ...newDoc, [name]: value });
  // };

  // const addDoc = async () => {
  //   const site = new URL(newDoc.url);
  //   const siteName = site.hostname;
  //   setNewDoc({ ...newDoc, siteName: siteName });

  //   try {
  //     const response = await axios.post(
  //       "https://asia-south1-your-cloud-function-url.cloudfunctions.net/addDocument",
  //       {
  //         ...newDoc,
  //         siteName: siteName,
  //         time: new Date().toISOString(),
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-api-key": "your-api-key",
  //         },
  //       }
  //     );
  //     console.log("Document added to the database", response.data);
  //   } catch (err) {
  //     console.error("Error adding document:", err);
  //   }
  //   setOpen(false);
  // };

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const clearFilters = () => {
    setFilter("");
    setStatus("");
    setProjectId("");
  };
  return (
    userState.set && (
      <>
        <Sidebar />
        <main>
          <div className="inventory">
            <div className="top-items">
              <h1 className="heading">Document Inventory</h1>
              <div style={{ marginRight: "80px" }}>
                {/* <button className="buttonA" onClick={handleClickOpen}>
                  +Add new
                </button> */}
                {/* <button className="buttonM">Import Data</button> */}
              </div>
              {/* <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: "20px",
                    height: "779px",
                    width: "519px",
                    padding: "20px",
                  },
                }}
              >
                <DialogTitle sx={{ fontSize: 24, color: "#2B3674" }}>
                  Add new document
                </DialogTitle>
                <DialogContent>
                  <div className="dialogQues">
                    <label className="dialogLabel">URL*</label>
                    <input
                      type="text"
                      name="url"
                      onChange={handelInput}
                      className="Dialoginput"
                      placeholder="URL of the form http or https://www"
                      required={true}
                    ></input>
                  </div>
                  <div className="dialogQues">
                    <label className="dialogLabel">Document name</label>
                    <input
                      type="text"
                      name="name"
                      onChange={handelInput}
                      className="Dialoginput"
                      placeholder="Document name"
                      required={true}
                    ></input>
                  </div>
                  <div className="dialogQues">
                    <label className="dialogLabel">Document description</label>
                    <input
                      type="text"
                      name="description"
                      onChange={handelInput}
                      className="Dialoginput"
                      style={{ height: "75px" }}
                      placeholder="Description"
                    ></input>
                  </div>
                  <div className="dialogQues">
                    <label className="dialogLabel">Additional Notes</label>
                    <input
                      type="text"
                      name="notes"
                      onChange={handelInput}
                      className="Dialoginput"
                      style={{ height: "75px" }}
                      placeholder="notes"
                    ></input>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <fieldset
                        style={{
                          border: "none",
                          fontFamily: "DM Sans",
                          fontSize: "20px",
                          color: "#2B3674",
                          padding: '1rem 2rem'
                        }}
                      >
                        <legend>Status</legend>
                        <RadioGroup defaultValue={"Not Scanned"}>
                          <label className="form-check">
                            <Radio
                              sx={{
                                height: "24px",
                                width: "24px",
                                color: "#E0E5F2",
                                marginRight: "12px",
                              }}
                              value={"Passed"}
                              name="status"
                              onChange={handelInput}
                            />
                            Passed
                          </label>
                          <label className="form-check">
                            <Radio
                              sx={{
                                height: "24px",
                                width: "24px",
                                color: "#E0E5F2",
                                marginRight: "12px",
                              }}
                              value={"Failed"}
                              name="status"
                              onChange={handelInput}
                            />
                            Failed
                          </label>
                          <label className="form-check">
                            <Radio
                              sx={{
                                height: "24px",
                                width: "24px",
                                color: "#E0E5F2",
                                marginRight: "12px",
                              }}
                              value={"Not Scanned"}
                              name="status"
                              onChange={handelInput}
                            />
                            Not Scanned
                          </label>
                        </RadioGroup>
                      </fieldset>
                    </div>
                    <div>
                      <fieldset
                        style={{
                          border: "none",
                          fontFamily: "DM Sans",
                          fontSize: "16px",
                          color: "#2B3674",
                        }}
                      >
                        <legend>Type</legend>
                        <RadioGroup defaultValue={"document"}>
                          <label className="form-check">
                            <Radio
                              sx={{
                                height: "24px",
                                width: "24px",
                                color: "#E0E5F2",
                                marginRight: "12px",
                              }}
                              value={"document"}
                              name="type"
                              onChange={handelInput}
                            />
                            Document
                          </label>
                          <label className="form-check">
                            <Radio
                              sx={{
                                height: "24px",
                                width: "24px",
                                color: "#E0E5F2",
                                marginRight: "12px",
                              }}
                              value={"audio/video"}
                              name="type"
                              onChange={handelInput}
                            />
                            Audio/Video
                          </label>
                          <label className="form-check">
                            <Radio
                              sx={{
                                height: "24px",
                                width: "24px",
                                color: "#E0E5F2",
                                marginRight: "12px",
                              }}
                              value={"image"}
                              name="type"
                              onChange={handelInput}
                            />
                            Image
                          </label>
                        </RadioGroup>
                      </fieldset>
                    </div>
                  </div>
                </DialogContent>
                <DialogActions sx={{ justifyContent: "flex-start" }}>
                  <button className="dialogButton" onClick={addDoc}>
                    Add document
                  </button>
                </DialogActions>
              </Dialog> */}
            </div>
          </div>
          <div className="reports">
            <div className="inventory2">
              <div className="pr_ti">
                <div>
                  <h2 className="document_heading">Document Reports</h2>
                </div>
              </div>
              <hr color="#E9EDF7" style={{ marginRight: "20px" }} />

              {/* Dropdowns for filter and status */}
              <div className="filters">
                <select
                  value={filter}
                  onChange={handleFilterChange}
                  className="filter-dropdown"
                >
                  <option value="" className="filter-option">
                    Select Project
                  </option>
                  {/* Add your filter options here */}
                  {projects.map((proj) => (
                    <option
                      key={proj.projId}
                      value={proj.projectName}
                      className="filter-option"
                    >
                      {proj.projectName}
                    </option>
                  ))}
                </select>

                <select
                  value={status}
                  onChange={handleStatusChange}
                  className="status-dropdown"
                >
                  <option value="">Document status</option>
                  {/* Add your status options here */}
                  {statusList.map((stat, index) => (
                    <option key={index} value={stat}>
                      {stat}
                    </option>
                  ))}
                </select>

                <button onClick={clearFilters} className="clear-filters-button">
                  Clear
                </button>
              </div>

              {Object.keys(Documents).map((siteName) => {
                return (
                  <>
                    <Accordion
                      key={siteName}
                      sx={{
                        marginBottom: "0.5rem",
                        borderRadius: "20px",
                        // boxShadow: "0.7px 0.7px 0.7px grey",
                        "&::before": {
                          height: 0,
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<Arrow />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <h1 className="accordion_heading">{siteName}</h1>
                      </AccordionSummary>
                      <AccordionDetails>
                        {Documents[siteName].map((Doc) => {
                          return (
                            <DocSnippet
                              key={Doc.id}
                              notes={Doc.notes}
                              description={Doc.description}
                              siteName={Doc.siteName}
                              projectId={Doc.projectID}
                              url={Doc.url}
                              type={Doc.type}
                              status={Doc.status}
                              time={Doc.time}
                              name={Doc.id}
                              role={userState.role}
                              scannedDocumentLink={
                                Doc.scannedDocumentLink
                                  ? Doc.scannedDocumentLink
                                  : ""
                              }
                            />
                          );
                        })}
                      </AccordionDetails>
                    </Accordion>
                    {/* <hr color="#E9EDF7" /> */}
                  </>
                );
              })}
            </div>
          </div>
        </main>
      </>
    )
  );
}
