import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect } from "react";
import Automated from "./Elements/Automated/Automated";
import backButton from "../../../../assets/back_button.svg";
import "./PageWise.css";
import Manual2 from "./Elements/Manual/Manual";
import { useLocation, useNavigate } from "react-router-dom";
import { userStateValues } from "../../../../firebaseConfig";
import { AuthContext } from "../../../Context/AuthContext";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PageWise() {
  const { userState } = useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const goToLogin = () => navigate("/");

  useEffect(() => {
    if (userState == userStateValues.loggedOut) {
      goToLogin();
    } else {
    }
  });
  // console.log("Current State At this PageWise", location.state);
  //Users/xo2dr7l538flz0xmvfUX36T4H8N2/Reports/www.inclusivestem.org/https:__www.inclusivestem.org_index.html/1000/pages/https:__www.inclusivestem.org_index.html/pass
  // if user is not logged in it will redirect to Login Page

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Fails = location.state?.fails;
  const Pass = location.state?.passes;
  const Warnings = location.state?.warnings;
  const Manual = location.state?.manual;

  // console.log("M", location.state.manual);
  // console.log("reqPW", location.state.latestRequest);
  // console.log(Fails);

  // console.log(Pass);
  // console.log(Warnings);

  return (
    userState !== "" && (
      <div className="entire_report">
        <button
          className="buttonBack"
          aria-label="Back Button"
          onClick={() => navigate(-1)}
        >
          {" "}
          <img src={backButton} className="backButton" alt="back" />
        </button>
        <text className="latest_heading">
          Page Wise report of {location.state.url}
        </text>

        <div className="scanned_result_container">
          <br />

          <div className="tabs">
            <Tabs
              TabIndicatorProps={{
                style: {
                  color: "#11047A",
                  height: "4px",
                  borderRadius: "70px",
                  marginTop: "24px",
                },
              }}
              value={value}
              onChange={handleChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
            >
              <Tab
                sx={{
                  textDecoration: "none",
                  textTransform: "none",
                  letterSpacing: "-0.02em",
                  fontSize: "20px",
                  marginBottom: "24px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
                label="Violations"
                {...a11yProps(0)}
              />
              <Tab
                sx={{
                  textDecoration: "none",
                  textTransform: "none",
                  letterSpacing: "-0.02em",
                  fontSize: "20px",
                  marginBottom: "24px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
                label="Warnings"
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  textDecoration: "none",
                  textTransform: "none",
                  letterSpacing: "-0.02em",
                  fontSize: "20px",
                  marginBottom: "24px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
                label="Passed"
                {...a11yProps(2)}
              />
              <Tab
                sx={{
                  textDecoration: "none",
                  textTransform: "none",
                  letterSpacing: "-0.02em",
                  fontSize: "20px",
                  marginBottom: "24px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
                label="Manual"
                {...a11yProps(3)}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <Automated
              to="details"
              latestRequest={location.state.latestRequest}
              Fails={Fails}
              userID={location?.state?.userID}
              siteName={location.state.siteName}
              url={location.state.url}
              badUrl={location.state.badUrl}
              projectId={location.state.projectId}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Automated
              to="details"
              latestRequest={location.state.latestRequest}
              Fails={Warnings}
              userID={location?.state?.userID}
              siteName={location.state.siteName}
              url={location.state.url}
              badUrl={location.state.badUrl}
              projectId={location.state.projectId}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Automated
              to="pdetails"
              Fails={Pass}
              userID={location?.state?.userID}
              latestRequest={location.state.latestRequest}
              siteName={location.state.siteName}
              url={location.state.url}
              badUrl={location.state.badUrl}
              projectId={location.state.projectId}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Manual2
              latestRequest={location.state.latestRequest}
              downloadUrl={""}
              userID={location?.state?.userID}
              Fails={Manual}
              siteName={location.state.siteName}
              badUrl={location.state.badUrl}
              url={location.state.url}
              projectId={location.state.projectId}
            />
          </TabPanel>
        </div>
      </div>
    )
  );
}
