import React from "react";
import { useNavigate } from "react-router-dom";
import "./IssueSnippet.css";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { auth, db } from "../../../../../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { async } from "@firebase/util";
export default function IssueSnippet(props) {
  const navigate = useNavigate();
  const rows = props.rows;
  // console.log("props state at issueSnippet", props);

  //  // console.log("CheckUrl", props.url);

  const sanitizeUrl = (badUrl) => {
    // console.log("badUrl: ", badUrl);
    let res = "";
    for (let i = 0; i < badUrl.length; i++) {
      if (badUrl[i] != "/") res += badUrl[i];
      else if (i != badUrl.length - 1) {
        res += "_";
      }
    }
    return res;
  };
  let sanitizedUrl;
  if (props.badUrl) {
    sanitizedUrl = sanitizeUrl(props.badUrl);
  } else {
    sanitizedUrl = sanitizeUrl(props.url);
  }
  // console.log("sanitizedUrl", sanitizedUrl);

  function createData(path, snippet, url,id,fix) {
    return { path, snippet, url,id ,fix};
  }

  function createData2(path, snippet) {
    return { path, snippet };
  }
  const userID = auth?.currentUser?.uid ?? props?.userID ?? "";
  const handleNavi = async (issue) => {
    // console.log("Button Is Working");
    //  // console.log("Rows", props.rows[issue].issue);
    const arr = props.rows[issue].issue;
    const imageUrls = [];
    const rows2 = [];
    const issueSnip = props.rows[issue].issue[0];
    // console.log("ChangeUrl", props.change);
    const docRef = doc(db, "Warnings/" + issueSnip.ruleId);
    const q = await getDoc(docRef);
    let isseSnip2;
    // console.log("EXISTS", q.exists());
    if (q.exists()) {
      isseSnip2 = q.data();
    } else {
      // console.log("CHECKING IN FAILURE");
      const fRef = doc(db, "Failures/" + issueSnip.ruleId);
      isseSnip2 = (await getDoc(fRef)).data();
    }

    // console.log("IS2", isseSnip2);

    const finalSnip = { ...isseSnip2, ...issueSnip };

    // console.log("ISSUESNIP", issueSnip);
    if (props.to === "mdetails") {
      navigate("/reports/scanned-result/entire-report/mdetails", {
        state: {
          siteName: props.siteName,
          url: props.url,
          issue: finalSnip,
          userID,
          projectId: props.projectId 
        },
      });
    } else if (props.to === "details") {
      let count = 0;
      // console.log("Arr", arr);
      arr.forEach(async (element) => {
        // console.log("element : ",element);
        //  // console.log("SiteName",props.siteName)
        //  // console.log("XPN",element.XPath);
        //  // console.log("ChangeIS",props.change);
        //  // console.log("LatestReq",props.latestRequest);
        /* fething details 2 times for
         single page mode details to get screenshot*/
        let img = "Screenshot Unavailable";
        const refCol =
          "Projects/" +
          props.projectId +
          "/Reports/" +
          props.siteName +
          "/" +
          sanitizedUrl +
          "/" +
          props.latestRequest +
          "/screenshots/" +
          props.change +
          "/notPassed";
        // console.log("refCol at issueSnipet", refCol);

        const q = query(
          collection(db, refCol),
          where("XPath", "==", element.XPath)
        );

        let snap = await getDocs(q);
        if (snap.empty) {
          const refCol1 =
            "Projects/" +
            props.projectId +
            "/Reports/" +
            props.siteName +
            "/" +
            sanitizedUrl +
            "/" +
            props.latestRequest +
            "/screenshots/" +
            props.change +
            "_" +
            "/notPassed";
          // console.log("refCol1 at issueSnipet", refCol1);
          const q1 = query(
            collection(db, refCol1),
            where("XPath", "==", element.XPath)
          );
          snap = await getDocs(q1);
        }
        // console.log("snap.exists", snap);
        snap.docs.forEach((doc) => {
          // console.log("each doc issue snippet",doc.id);
          //  // console.log("L",snap.docs.length)
          //  // console.log(doc.data());
          //  // console.log("Ar",imageUrls);
          imageUrls.push(doc.data().downloadUrl);
          // console.log("ImageUrls", imageUrls);
          img = doc.data().downloadUrl;
          //  // console.log("img ",img);
        });
        const row = createData(element.XPath, element.html, img, doc.id,element.fix);
        rows2.push(row);
        // console.log("row", row);
        // console.log("Rows2", rows2);
        count++;
        // console.log("count", count);
        if (count == arr.length) {
          // console.log("FinalSnip", finalSnip);
          navigate("/reports/scanned-result/entire-report/details", {
            state: {
              siteName: props.siteName,
              url: props.url,
              userID: userID,
              issue: finalSnip,
              rows: props.rows[issue].issue,
              rows2: rows2,
              change: props.change,
              projectId: props.projectId,
              path: props.path
            },
          });
        }
      });
    } else {
      let count = 0;

      arr.forEach(async (element) => {
        // console.log("SiteName", props.siteName);
        // console.log("XPN", element.XPath);
        //      // console.log("ChangeISP",props.change);
        //     const refCol = "Reports/"+props.siteName+"/previousReports/"+props.latestRequest+"/screenshots/"+props.change+"/passed";
        //      // console.log(refCol)
        //     const q = query(collection(db,refCol),where("XPath","==",element.XPath));
        // const snap = await getDocs(q);
        //   snap.docs.forEach((doc)=>{
        //      // console.log("L",snap.docs.length)
        //      // console.log(doc.data());
        //      // console.log("Ar",imageUrls);
        //     imageUrls.push(doc.data().downloadUrl)
        //      // console.log("ImageUrls",imageUrls);
        //     const img = doc.data().downloadUrl;
        //      // console.log("img ",img);
        //     const row = createData2(element.XPath , element.html);
        //      // console.log("TR",row)
        //     rows2.push(row);
        //      // console.log("Rows2",rows2);
        // });
        const row = createData2(element.XPath, element.html);
        rows2.push(row);
        count++;
        // console.log("count", count);
        if (count == arr.length) {
          navigate("/reports/scanned-result/entire-report/pdetails", {
            state: {
              siteName: props.siteName,
              url: props.url,
              issue: finalSnip,
              userID,
              rows: props.rows[issue].issue,
              rows2: rows2,
              change: props.change,
              projectId: props.projectId
            },
          });
        }
      });
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <TableContainer
        component={Paper}
        sx={{
          padding: "16px",
          paddingTop: "16px",
          paddingBottom: "16px",
          border: "1px solid #E9EDF7",
          boxSizing: "border-box",
          borderRadius: "16px",
          boxShadow: "none",
          width: "1009px",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="">
          <TableHead>
            <TableRow sx={{ borderBottom: "1px solid #E9EDF7" }}>
              <TableCell>Rule</TableCell>
              <TableCell align="left">Rule Description</TableCell>
              <TableCell align="left">Severity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.rule}
                sx={{
                  paddingRight: "20px",
                  borderBottom: "1px solid #E9EDF7",
                  "&:last-child td, &:last-child th": { borderBottom: "none" },
                }}
              >
                <TableCell
                  component="a"
                  scope="row"
                  onClick={() => {
                    handleNavi(index);
                  }}
                  sx={{
                    cursor: "pointer",
                    widht: "200px",
                    textDecoration: "underline",
                  }}
                >
                  {row.rule}
                </TableCell>
                <TableCell align="left" sx={{}}>
                  {row.description}
                </TableCell>
                <TableCell align="left" sx={{ color: "red" }}>
                  {row.severity}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
