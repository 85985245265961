import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import '../Details/Details.css'
import backButton from '../../../../../../assets/back_button.svg'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Arrow from '../Automated/Arrow';
export default function ManualDetails() {

  const location = useLocation()
  const navigate=useNavigate();
  const issueSnip = location.state.issue;
  const impact = (issueSnip.impact);

  return(
  <div className='details'>
           <button className='buttonBack' aria-label='Back Button' onClick={()=> navigate(-1)}> <img src={backButton} className='backButton'alt='back' /></button> <text className='latest_heading'>{issueSnip.ruleId} details</text>
             <div className='scanned_result_container'>
                 <div className='voilation_content'>
                 <h1 className='latest_heading'>Violation: {issueSnip.ruleId}</h1>
                 <div style={{marginTop:"5px"}}>
                   <br/>
                 <text className='description'>{issueSnip.ruleDescription}</text>
                 </div>
                 <div style={{marginTop:"8px"}}>
                 <a to={issueSnip.helpUrls} className='help_url' href={issueSnip.helpUrls} target='_blank'>More information about {issueSnip.ruleId}</a>
                 <div className='impact_details'>
                <h2 style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}}>Compliance</h2>
                <div className='impact_container'>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>Conformance level :</text ><text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>{issueSnip.conformanceLevel}</text><br/>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>Success criteria :</text><text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>{issueSnip.wcagSuccessCriteriaId}</text><br/>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>GIGW mapping :</text><text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>{issueSnip.GIGW}</text><br/>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>Severity :</text><text style={{color:"red",fontFamily:"DM Sans"}}>{issueSnip.severityLevel}</text><br/>
                </div>

             </div>
                  <div className='impact_details'>
                <h2 style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}}>Impact</h2>
                <div className='impact_container'><p className='impact'>{impact}</p></div>

             </div>
             <div className='impact_details'>
                <h2 style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}}>How to Fix?</h2>
                <div className='impact_container'><p className='impact'>{issueSnip.fix}</p></div>

             </div>
             <div className='impact_details'>
                <h2 style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}}>How to Test?</h2>
                <div className='impact_container'><p className='impact'>{issueSnip.howToTest}</p></div>

             </div>
             </div>
             <br/>
             </div>
             </div>
            </div>
  )
}
