import React, { useContext, useEffect, useState } from "react";
import "./Details.css";
import {
  MenuItem,
  TextField,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { attachFile, createSubTask, fetchJiraAssigneeById, fetchJiraDetails, fetchJiraProjectById, fetchRefreshTokenAndCloudIdFromFirestore, refreshTokens, saveJiraDetails } from "../../../../../../Utils/JiraFunctions";
import { APIConst } from "../../../../../../API const/APIConst";
import { subtract } from "lodash";

export default function JiraDetailsForm(props) {

  // console.log("inside jira detail form", props);

    const [subTaskAssigned,setSubTaskAssigned] = useState(false);
    const [assignButtonText,setAssignButtonText] = useState('Assign Subtask');
    const [currentJiraDetails, setCurrentJiraDetails] = useState({
      set: false,
      project: "Not Assigned",
      assignee: "Not Assigned",
      projectName: "...loading",
      userName: "...loading"
    });
    const [jiraSubTask,setJiraSubTask] = useState({
        project: '',
        assignee: '',
        description: `path:${props.path} \n\n snippet: ${props.snippet}`
    });

    const handelInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        // console.log(name, value);
    
        setJiraSubTask({ ...jiraSubTask, [name]: value });
        // console.log(jiraSubTask);
        // console.log(name, value);
        // console.log("jira subtask object", jiraSubTask);
      };

      const makeSubTask = async () => {
        //fetch task id, cloud id
        setAssignButtonText('Assigning');
        const [refreshToken, cloudId] =
      await fetchRefreshTokenAndCloudIdFromFirestore(props.userId);
      const accessToken = await refreshTokens(
        props.userId,
        refreshToken,
        APIConst.jiraServerUrl
      );
        //create subtask
        const subTask = await createSubTask(
            APIConst.jiraServerUrl,
            accessToken,
            cloudId,
            jiraSubTask,
            props.parentIssueKey
          );
        // extract subtask key
        const docPath = `${props.cardPath}/pmTools/Jira/${props.ruleId}/task/subtasks/${props.issueId}`;
    if(subTask!= null && subTask !== undefined) {
      await saveJiraDetails(
        docPath,
        jiraSubTask.project.id,
        jiraSubTask.assignee.accountId,
        subTask.id,
        subTask.key
      ); 
    }
        const subTaskKey = subTask.key;
        // console.log(subTaskKey);
        // call attach api
        const resp = await attachFile(APIConst.jiraServerUrl,accessToken,cloudId,props.ssUrl,subTaskKey);
        setAssignButtonText("Assigned SubTask");
        getJiraDetails();
      }


      const getJiraDetailsById = async () => {
        const [refreshToken, cloudId] =
          await fetchRefreshTokenAndCloudIdFromFirestore(props.userId);
        const accessToken = await refreshTokens(
          props.userId,
          refreshToken,
          APIConst.jiraServerUrl
        );
        // console.log("new access token recieved");
        const jiraProject = await fetchJiraProjectById(APIConst.jiraServerUrl,
          cloudId,
          accessToken,
          currentJiraDetails.project
        );
    
        const jiraUser = await fetchJiraAssigneeById(APIConst.jiraServerUrl,
          cloudId,
          accessToken,
          currentJiraDetails.assignee
        );
        
        setCurrentJiraDetails(prevDetails => ({
          ...prevDetails,
          projectName: jiraProject.name,
          userName: jiraUser.displayName
        }));
      }

      useEffect(() => {
        // console.log("current jira det", currentJiraDetails);
          // console.log('calling getJiraDetailsById');
          if(currentJiraDetails.set) {
            getJiraDetailsById();
          }
      }, [currentJiraDetails.set]);

      useEffect(()=> {
        getJiraDetails();
        // console.log("props",props);
      },[props]);

      const getJiraDetails = async () => {
        const docPath1 = `${props.cardPath}/pmTools/Jira/${props.ruleId}/task/subtasks/${props.issueId}`;
        const res1 = await fetchJiraDetails(docPath1);
        // console.log("res1",res1);
        if (res1 !== undefined && res1 !== null) {
          setSubTaskAssigned(true);
          setCurrentJiraDetails({
            ...currentJiraDetails,
            set: true,
            project: res1.jiraProjectId,
            assignee: res1.jiraUserId,
          });
        } else {
          const docPath2 = `Projects/${props.projectId}`;
          const res2 = await fetchJiraDetails(docPath2);
          // console.log("res2", res2);
          if (res2 !== undefined && res2 != null) {
            // setCurrentJiraDetails({
            //   ...currentJiraDetails,
            //   project: res2.jiraProjectId,
            //   assignee: res2.jiraUserId,
            // });
            const foundProject = props.jiraProjects.find(
              (project) => project.id === res2.jiraProjectId
            );
            const foundUser = props.jiraAssignee.find(
              (user) => user.accountId === res2.jiraUserId
            );
    
            // console.log(foundProject, foundUser);
            if (foundProject && foundUser) {
              setJiraSubTask({
                ...jiraSubTask,
                project: foundProject,
                assignee: foundUser
              });
            }
          }
        }
      };


      useEffect(()=> {
        if(subTaskAssigned) {
          setAssignButtonText('Assigned SubTask');
        }
      },[subTaskAssigned]);

  return (
    <>
      <div
        className="impact-row"
        style={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <text
          style={{
            marginLeft: "5px",
            marginTop: "1rem",
            color: "#707eae",
            fontFamily: "'DM Sans',sans-sherrif",
            fontWeight: 500,
            fontSize: "14px",
          }}
        >
          project :
        </text>
        {!subTaskAssigned && <TextField
          select
          name="project"
          label="Jira project"
          value={jiraSubTask.project}
          onChange={handelInput}
          sx={{
            width: "60%",
            color: "#11047A",
            background: "#F4F7FE",
            maxHeight: "100px",
            overflowY: "auto",
          }}
        >
          <MenuItem value={""}></MenuItem>
          {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
          {props.jiraProjects.map((pro) => {
            return (
              <MenuItem key={pro.id} value={pro}>
                {pro.name}
              </MenuItem>
            );
          })}
          {!props.jiraProjects.length && <MenuItem>No Projects</MenuItem>}
        </TextField>}
        {subTaskAssigned && (
                    <text
                      style={{
                        marginLeft: "5px",
                        marginTop: "1rem",
                        color: "#707eae",
                        fontFamily: "'DM Sans',sans-sherrif",
                        fontWeight: 500,
                        fontSize: "14px",
                        width: "60%",
                      }}
                    >
                      {currentJiraDetails.projectName}
                    </text>
                  )}
      </div>

      <div
        className="impact-row"
        style={{
          marginBottom: "3rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <text
          style={{
            marginLeft: "5px",
            marginTop: "1rem",
            color: "#707eae",
            fontFamily: "'DM Sans',sans-sherrif",
            fontWeight: 500,
            fontSize: "14px",
            width: '15rem'
          }}
        >
          Assignee :
        </text>
        {!subTaskAssigned && <TextField
          select
          name="assignee"
          label="Jira assignee"
          value={jiraSubTask.assignee}
          onChange={handelInput}
          sx={{
            width: "60%",
            color: "#11047A",
            background: "#F4F7FE",
            maxHeight: "100px",
            overflowY: "auto",
          }}
        >
          <MenuItem value={""}></MenuItem>
          {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
          {props.jiraAssignee.map((pro) => {
            return (
              <MenuItem key={pro.accountId} value={pro}>
                {pro.displayName}
              </MenuItem>
            );
          })}
          {!props.jiraAssignee.length && <MenuItem>No Members</MenuItem>}
        </TextField>}
        {subTaskAssigned && (
                    <text
                      style={{
                        marginLeft: "5px",
                        marginTop: "1rem",
                        color: "#707eae",
                        fontFamily: "'DM Sans',sans-sherrif",
                        fontWeight: 500,
                        fontSize: "14px",
                        width: "60%",
                      }}
                    >
                      {currentJiraDetails.userName}
                    </text>
                  )}
      </div>
      <button onClick={makeSubTask} disabled={subTaskAssigned} className={subTaskAssigned?'assign assign-disabled':'assign'}>{assignButtonText}</button>
    </>
  );
}
