import React from "react";
import "./report_card.css";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import axios from "axios";
import more from "../../../assets/More BuTTon2.svg";
import { Menu, MenuItem } from "@mui/material";
import Manual from "./ScannedResult/Elements/Manual/Manual";

export default function Report_card(props) {
  const navigate = useNavigate();
  // console.log({ hasMore: props.hasMore });
  // console.log({ url: props.url });
  const params = useParams();
  const userID = props?.userID;
  const mode = props.mode;
  let status;
  let state;
  let issuesFound;
  let warnings;
  const sanitizeUrl = (badUrl) => {
    // console.log("badUrl: ", badUrl);
    let res = "";
    for (let i = 0; i < badUrl.length; i++) {
      if (badUrl[i] != "/") res += badUrl[i];
      else if (i != badUrl.length - 1) {
        res += "_";
      }
    }
    return res;
  };
  const sanitizedUrl = sanitizeUrl(props.url);
  if (props.status === "Pending") {
    issuesFound = "Processing...";
    warnings = "Processing...";
    status = "Processing...";
    state = true;
  } else if (props.hasMore && props.status !== "Pending") {
    issuesFound = props.issuesFound;
    warnings = props.warnings;
    status = "Partial Results";
    state = false;
  } else {
    issuesFound = props.issuesFound;
    warnings = props.warnings;
    status = "View Report";
    state = false;
  }

  function createData(
    mode,
    issueCount,
    warningsCount,
    testedAgainst,
    date,
    status,
    hasMore,
    link,
    reqId
  ) {
    return {
      mode,
      issueCount,
      warningsCount,
      testedAgainst,
      date,
      status,
      hasMore,
      link,
      reqId,
    };
  }

  const viewPrevReports = async () => {
    // if(mode=="crawl") return;

    const q = query(
      collection(
        db,
        "Projects/" +
          props.projectId +
          "/Reports/" +
          props.siteName +
          "/" +
          sanitizedUrl +
          "/"
      ),
      orderBy("timestamp", "desc")
    );
    const snap = await getDocs(q);
    let rows = [];
    snap.docs.forEach((doc) => {
      const row = createData(
        doc.data().mode,
        doc.data().issueCount,
        doc.data().warningsCount,
        doc.data().standard + "   ,",
        doc.data().formattedTS,
        doc.data().status,
        doc.data().hasMore,
        "View Reports",
        doc.id
      );
      rows.push(row);
    });
    // console.log("ROWS", rows);
    navigate("prevReports", {
      state: {
        siteName: props.siteName,
        url: props.url,
        lastScanned: props.lastScanned,
        rows: rows,
        userID: userID,
        hasMore: props.hasMore,
        projectId : props.projectId
      },
    });
  };
  const IncNum = async () => {
    // console.log("Props", props);

    // console.log(props.latestRequest);
    if (mode === "single-page") {
      navigate("/reports/scanned-result/entire-report", {
        state: {
          siteName: props.siteName,
          url: props.url,
          lastScanned: props.lastScanned,
          latestRequest: props.latestRequest,
          userID: userID,
          hasMore: props.hasMore,
          projectId: props.projectId
        },
      });
    } else {
      console.log("ReqId", props.latestRequest);
      const Pages = [];

      const docRef = doc(
        db,
        "Projects/" +
          props.projectId +
          "/Reports/" +
          props.siteName +
          "/" +
          sanitizedUrl +
          "/" +
          props.latestRequest
      );
      const data = await getDoc(docRef);
      const downloadUrl = data.data().downloadUrl;
      console.log("DownloadUrl", downloadUrl);
      // console.log("Calling Api");
      axios
        .get(downloadUrl)
        .then((res) => {
          console.log("Response recived in crawl", res);
          const urls = res.data.callbackResponse.result.urls;
          const manual = res.data.callbackResponse.result.manual;
          // console.log("MANUAL", manual);
          navigate("/reports/scanned-result", {
            state: {
              result: res.data.callbackResponse.result,
              siteName: props.siteName,
              url: props.url,
              userID: userID,
              lastScanned: props.lastScanned,
              latestRequest: props.latestRequest,
              pages: Pages,
              manual: manual,
              projectID : props.projectId
            },
          });
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
  };

  return (
    <>
      <div className="report_card">
        <h2 hidden>
          {props.siteName} Last scanned on {props.lastScanned} with level{" "}
          {props.testedAgainst}
        </h2>
        <div className="innerRC">
          <div className="button-item">
            <div className="site">
              <h2 className="siteName">{props.siteName}</h2>
              <a
                to={props.url}
                className="siteUrl"
                href={props.url}
                target="_blank"
                rel="noreferrer"
              >
                {props.url}
              </a>
            </div>
            <div className="button-group">
              {/* {props.hasMore && props.status !== "Pending" && (
                <button
                  className="button2-group"
                  onClick={IncNum}
                  aria-label="Partial results"
                >
                  <text style={{ textDecoration: "none" }}>
                    Partial results
                  </text>
                </button>
              )} */}

              <button
                className={
                  props.hasMore && props.status !== "Pending"
                    ? "button2-group"
                    : "button1-group"
                }
                onClick={IncNum}
                aria-label={status}
                disabled={state}
              >
                <text
                  className={
                    props.hasMore && props.status !== "Pending"
                      ? "nothing"
                      : "button_nav"
                  }
                  style={{ textDecoration: "none" }}
                >
                  {status}
                </text>
              </button>

              <button
                className="button1-group"
                style={{
                  width: "10rem",
                  height: "46px",
                  color: "#707eae",
                  fontFamily: "DM Sans",
                }}
                aria-label="view previous reports"
                onClick={viewPrevReports}
              >
                <text className="button_nav" style={{ textDecoration: "none" }}>
                  View Previous Reports
                </text>
              </button>
            </div>
          </div>
          <text className="history_title">Mode :</text>
          <text className="history_last">{props.mode}</text>
          <br />
          <text className="history_title">Errors :</text>
          <text
            className={
              issuesFound === "Processing..." ? "history_last" : "history_issue"
            }
          >
            {issuesFound}
          </text>
          <br />
          <text className="history_title">Warnings:</text>
          <text
            className={
              warnings === "Processing..." ? "history_last" : "history_issue"
            }
          >
            {warnings}
          </text>
          <br />
          <text className="history_title">Last Scanned:</text>
          <text className="history_last">{props.lastScanned}</text>
          <br />
          <text className="history_title">Tested against Level:</text>
          <text className="history_last" letterSpacing={10}>
            {props.testedAgainst + "    "}{" "}
          </text>
          <br />

          <br />
        </div>
      </div>
    </>
  );
}
