import React, { useContext, useEffect, useState } from "react";
import { onSnapshot, query, collection, where } from "firebase/firestore";
import InviteMember from "./Dialogs/InviteMember";
import RemoveMember from "./Dialogs/RemoveMember";
import { db } from "../../firebaseConfig";
import { AuthContext } from "../Context/AuthContext";
export default function MembersList(props) {
  const { orgId ,role} = props;
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    console.log(role);
    if (orgId != "") {
      // console.log(props.orgId);
      // getMembers();
    }
    const unsub = onSnapshot(
      query(collection(db, "Users"), where("orgId", "==", orgId)),
      (snapShot) => {
        const membersData = [];
        setMembers([]);
        snapShot.docs.forEach((doc) => {
          let dataElement = {
            email: doc.data().email,
            name: doc.data().name,
            role: doc.data().role,
            teams: doc.data().teams,
            uid: doc.id,
          };
          membersData.push(dataElement);
          // console.log(doc.data());
          // console.log(membersData);
        });
        setMembers(membersData);
      }
    );
    return unsub;
  }, [orgId]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const { setClicked } = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
    setClicked(false);
  };

  return (
    <div className="members-main-membersList">
      <div className="members-main-membersList-heading">
        <h2>{members.length} Members</h2>

        {role == "Admin" && <button onClick={handleClickOpen}>+ Invite Members</button>}
        {open && <InviteMember open={open} onClose={handleClose} />}
      </div>
      <hr
        style={{
          border: "1px solid #E9EDF7",
        }}
      />
      <table className="members-main-membersList-table">
        <thead>
          <tr className="members-main-membersList-tr">
            <td>Member</td>
            <td>Role</td>
            <td>Team</td>
            <td> </td>
          </tr>
        </thead>
        <tbody className="members-table-body">
          {members.map((val, id) => (
            <TableRow val={val} key={id} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

const TableRow = (props) => {
  const data = props.val;
  const staticData = {
    ...data,
    team: "0",
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <tr className="members-main-membersList-tr">
      <td>
        <div>
          {staticData.name}
          <br />
          {staticData.email}
        </div>
      </td>
      <td>{staticData.role}</td>
      <td>{staticData.teams?.length}</td>
      <td>
        <button onClick={handleClickOpen}>
          <svg
            aria-label="Remove Member"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
        <RemoveMember
          name={data.name}
          uid={data.uid}
          open={open}
          onClose={handleClose}
        />
      </td>
    </tr>
  );
};
