import { Alert, Button, Link, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import LoginImage from "../../../assets/LoginImage.svg";
import Logo from "../../../assets/portal_logo.svg";
//portal_logo.svg
import "./style.css";

export default function ForgotPassword(props) {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const handleClick = async () => {
    const actionCodeSettings = {
      // After password reset, the user will be give the ability to go back
      // to this page.
      url: "http://localhost:3000",
      handleCodeInApp: false,
    };
    if (email == "") {
      return;
    }

    try {
      // console.log(auth);
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      if (error.code == "auth/user-not-found") {
        setError("Email not found!");
      } else {
        setError("Something went wrong!");
      }
      console.log(error.code);
      return;
      // setError("")
    }
    setSubmitted((prev) => !prev);
    console.log("Okay");
  };
  return (
    <div className="login-container">
      <div className="login-c-main">
        <img src={Logo} />
        <h2>{!submitted ? "Forgot Password" : "Check your mail"}</h2>
        {!submitted ? (
          <div
            style={{
              width: "450px",
            }}
          >
            <label htmlFor="email">Email Address</label>
            <TextField
              autoFocus
              required
              fullWidth={true}
              sx={{ backgroundColor: "#F4F7FE", mb: 2 }}
              autoComplete="email"
              name="email"
              placeholder="Email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></TextField>
            {error && (
              <Alert icon={false} severity="error">
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: "70px" }}
              onClick={handleClick}
            >
              Proceed
            </Button>
            <Link href="/" variant="body2">
              {"Already have an account? Log In"}
            </Link>
          </div>
        ) : (
          <div>
            <Typography>
              Please click on the link sent to {email} to complete your password
              reset process. If you cant see the mail in Inbox, Check your spam
              folder.
            </Typography>
            <Typography>
              Still facing issues? Contact us on info@example.com.
            </Typography>
          </div>
        )}
      </div>
      <img className="login-c-image" src={LoginImage} />
    </div>
  );
}
