import React, { useContext, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./Previous_Reports.css";
import { auth, db } from "../../../firebaseConfig";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  limit,
  query,
  setDoc,
  doc,
} from "firebase/firestore";
import ReportCard from "./report_card";
import {
  Alert,
  Button,
  InputAdornment,
  Snackbar,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CSVLink } from "react-csv";
import Fuse from "fuse.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";
import { APIConst } from "../../../API const/APIConst";

export default function Previous_Reports() {
  const [Reports, setReport] = useState([]);
  const [show, setShow] = useState(true);
  const [visibility, setVisibility] = useState(true);
  const { userState } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState("Success");
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  const userID = auth?.currentUser?.uid ?? params?.id ?? "";
  const options = {
    includeScore: true,
    keys: ["siteName"],
  };
  const fuse = new Fuse(Reports, options);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handlePublicUrl = async () => {
    try {
      const docRef = doc(db, "Users/" + userID + "/Reports/publicOption");
      setOpen(false);
      await setDoc(docRef, { visibility: !visibility }, { merge: true });
      if (!visibility) {
        navigator.clipboard.writeText(window.location?.href + "/" + userID);
        setText("Public URL Enabled & Copied to clipboard");
      } else {
        setText("Public URL Disabled");
      }
      setOpen(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setReport([]);
    if (projects.length === 0) return;
    console.log("hello 1");
    const unsubscribes = projects.map((project) => {
      const unSub = onSnapshot(
        collection(db, "Projects/" + project.projId + "/Reports"),
        (snapshot) => {
          snapshot.docs.forEach((domains) => {
            const urlList = domains?.data()?.urlList;
            if (!urlList) return;
    
            urlList.forEach(async (urls) => {
              const collectionRef = collection(
                db,
                `Projects/${project.projId}/Reports/${domains.id}/${urls}`
              );
              const querryRef = query(
                collectionRef,
                orderBy("creationTime", "desc"),
                limit(1)
              );
              const urlReports = await getDocs(querryRef);
              const latestUrlReport = urlReports.docs[0];
              if (latestUrlReport) {
                const data = {
                  projectId: project.projId,
                  projectName: project.projectName,
                  siteName: domains.id,
                  url: latestUrlReport.data().url,
                  mode: latestUrlReport.data().mode,
                  id: latestUrlReport.id,
                  latestReport: latestUrlReport.id,
                  latestRequest: latestUrlReport.id,
                  testedAgainst: domains.data().standard + "",
                  lastScanned: latestUrlReport.data().formattedTS,
                  issueCount: latestUrlReport.data().issueCount,
                  warningsCount: latestUrlReport.data().warningsCount,
                  time: latestUrlReport.data().timestamp,
                  status: latestUrlReport.data().status,
                  hasMore: latestUrlReport.data().hasMore ?? false,
                };
    
                setReport((prevReports) => {
                  const ind = prevReports.findIndex(
                    (report) =>
                      report.projectId === data.projectId &&
                      report.siteName === data.siteName && 
                      report.url === data.url
                  );
    
                  if (ind === -1) {
                    // If the report does not exist, add it
                    return [...prevReports, data];
                  } else {
                    // If the report exists, update it
                    const updatedReports = [...prevReports];
                    updatedReports[ind] = data;
                    return updatedReports;
                  }
                });
              }
            });
          });
        }
      );
      return unSub;
    });
    

    // Cleanup function to unsubscribe from all listeners when component unmounts
    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [projects]);

  useEffect(() => {
    const fetchProjects = () => {
      if (!auth?.currentUser?.uid && !params?.id) {
        navigate("/");
      }
      const userID = auth?.currentUser?.uid ?? params?.id ?? "";

      const payload = {
        userId: userID,
      };

      axios
        .post(APIConst.projectByUserId, JSON.stringify(payload), {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8",
          },
          timeout: 70000,
        })
        .then(async (response) => {
          await setProjects(response.data.projects);
        })
        .catch((err) => {
          // window.alert(err);
          console.log(err);
        });
    };

    if (userID) {
      fetchProjects();
    }
  }, [userID]);

  const handleChange = (e) => {
    const searchText = e.target.value;
    if (searchText) {
      const result = fuse.search(searchText);
      setReport(result.map(({ item }) => item));
      setShow(false);
    } else {
      setShow(true);
    }
  };

  const parseDateString = (dateString) => {
    // Format the date string into a format JavaScript Date can parse
    const parts = dateString.replace('IST', '').trim().split(' ');
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const time = parts[3] + ' ' + parts[4]; // Combine time and AM/PM
    
    // Create a new Date string that JavaScript can parse
    return new Date(`${month} ${day}, ${year} ${time}`);
  };

  const groupedReports = Reports.reduce((groups, report) => {
    const projectName = report.projectName;
    if (!groups[projectName]) {
      groups[projectName] = [];
    }
    groups[projectName].push(report);
    return groups;
  }, {});
  
  // Sort the reports in descending order by lastScanned for each project group
  Object.keys(groupedReports).forEach((projectName) => {
    groupedReports[projectName].sort((a, b) => parseDateString(b.lastScanned) - parseDateString(a.lastScanned));
  });
  
  // console.log("grouped reports",groupedReports);

  return (
    <div className="previous_report">
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{
          backgroundColor: "#F4F7FE",
          top: "40px",
          left: "2%",
          width: "440px",
        }}
        placeholder="Search Documents"
        onChange={handleChange}
      />
      {userState.role && (
        <CSVLink data={Reports}>
          <Button
            aria-label="Download All Report"
            style={{
              borderRadius: "70px",
              width: "210px",
              top: "40px",
              left: "23%",
              height: "46px",
              border: "1px solid #A3AED0",
              marginLeft:'10rem'
            }}
            variant="outlined"
          >
            Download all reports
          </Button>
        </CSVLink>
      )}
      {/* {userState.role && (
        <Button
          aria-label={
            visibility ? "Disable public access" : "Enable public access"
          }
          onClick={handlePublicUrl}
          style={{
            borderRadius: "70px",
            width: "175px",
            top: "40px",
            left: "25%",
            height: "46px",
            border: "1px solid #A3AED0",
          }}
          variant="outlined"
        >
          {visibility ? "Disable" : "Enable"} Public Url
        </Button>
      )} */}
      <div className="accordion-container">
        {Object.keys(groupedReports).map((projectName, index) => (
          <Accordion 
            key={index}
            style={{ marginBottom: '0.9rem', borderRadius: '20px', boxShadow: '1px 1px 1px 1px grey',width: '95%'}}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography
                style={{
                  fontSize: "22px",
                  fontWeight: "500",
                  color: "#2B3674",
                  marginTop: '10px',
                  marginBottom: "10px",
                }}
              >
                {projectName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {groupedReports[projectName].map((report, idx) => {
                return (
                  <ReportCard
                    key={idx}
                    userID={userID}
                    hasMore={report.hasMore}
                    status={report.status}
                    issuesFound={report.issueCount}
                    warnings={report.warningsCount}
                    mode={report.mode}
                    siteName={report.siteName}
                    latestReport={report.latestReport}
                    url={report.url}
                    lastScanned={report.lastScanned}
                    latestRequest={report.latestRequest}
                    testedAgainst={report.testedAgainst}
                    projectId={report.projectId}
                  />
                )
              })}
            </AccordionDetails>
          </Accordion>
          
        ))}
      </div>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleClose}
        TransitionComponent={"SlideTransition"}
      >
        <Alert severity="success" onClose={handleClose} sx={{ width: "100%" }}>
          {text}
        </Alert>
      </Snackbar>
    </div>
  );
}
