/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import "./members.css";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { doc, getDoc } from "firebase/firestore";
import { userStateValues, auth, db } from "../../firebaseConfig";
import TeamsList from "./TeamsList";
import MembersList from "./MembersList";
import { onAuthStateChanged } from "firebase/auth";
import { AuthContext } from "../Context/AuthContext";
import ProjectsList from "./ProjectsList";


export default function Members() {
    const {userState} = useContext(AuthContext)

  const navigate = useNavigate();
  const goToLogin = () => navigate("/");

  const [orgId, setOrgId] = useState("");

  async function getOrgID(uid) {
    // console.log("okay?", uid);
    const userDoc = (await getDoc(doc(db, "Users/" + uid))).data();
    // console.log(userDoc);
    setOrgId(userDoc.orgId);
  }

  useEffect(() => {
    if (userState.set && userState.uid === "") {
      goToLogin();
    }
  });

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (userCred) => {
      if (userCred) {
        getOrgID(userCred.uid);
      }
    });
    return unsub;
  }, [orgId]);

  return (
    userState.set && (
      <>
        <Sidebar/>
        <main>
          <div className="members">
            <div className="members-top-item">
              <h1 className="members-heading">Members</h1>
              <div className="members-main-container">
                {orgId != "" && <MembersList orgId={orgId} role={userState.role}/>}
                {orgId != "" && <TeamsList orgId={orgId} role={userState.role}/>}
                {orgId != "" && <ProjectsList orgId={orgId} />}
              </div>
            </div>
          </div>
        </main>
      </>
    )
  );
}
