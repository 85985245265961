import React, { useContext, useEffect, useState } from "react";
import "./Details.css";
import backButton from "../../../../../../assets/back_button.svg";
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import close from "../../../../../../assets/x-square.png";
import copy from "../../../../../../assets/copy.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { userStateValues } from "../../../../../../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { AuthContext } from "../../../../../Context/AuthContext";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { PmContext } from "../../../../../Context/PmContext";
import {
  createIssue,
  createStory,
  fetchJiraAssigneeById,
  fetchJiraAssignees,
  fetchJiraDetails,
  fetchJiraEpics,
  fetchJiraProjectById,
  fetchJiraProjects,
  fetchJiraStories,
  fetchRefreshTokenAndCloudIdFromFirestore,
  refreshTokens,
  saveJiraDetails,
  SetPmTool,
} from "../../../../../../Utils/JiraFunctions";
import { APIConst } from "../../../../../../API const/APIConst";
import JiraDetailsForm from "./JiraDetailsFrom";
import ReactMarkdown from "react-markdown";

export default function Details(props) {
  const { userState } = useContext(AuthContext);
  const { enableTool,setEnableTool } = useContext(PmContext);
  const location = useLocation();
  const navigate = useNavigate();
  const goToLogin = () => navigate("/");
  const [codeCopyButton,setCodeCopyButton] = useState(false);
  const [jiraProjects, setJiraProjects] = useState([]);
  const [jiraAssignee, setJiraAssignee] = useState([]);
  const [jiraEpics, setJiraEpics] = useState([]);
  const [jiraStories, setJiraStories] = useState([]);
  const [parentEpic,setParentEpic] = useState('');
  const [parentStory,setParentStory] = useState('');
  const [jiraProjectIssues, setJiraProjectsIssues] = useState([]);
  const [currentJiraDetails, setCurrentJiraDetails] = useState({
    set: false,
    project: "Not Assigned",
    assignee: "Not Assigned",
    projectName: "...loading",
    userName: "...loading"
  });
  const [assignButtonText,setAssignButtonText] = useState('Assign');
  const [jiraIssue, setJiraIssue] = useState("");
  const [jiraStory, setJiraStory] = useState({
    project: "",
    assignee: "",
    description: "",
    summary: ""
  });
  const [jira, setJira] = useState({
    project: "",
    assignee: "",
    description: "",
    summary: "",
    story: null,
    epic: null,
  });
  const [cardAssigned, setCardAssigned] = useState(false);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.preventDefault();
      if (!tags.includes(inputValue.trim())) {
        inputValue.replace(/ /g, "_");
        setTags([...tags, inputValue.trim()]);
      }
      setInputValue(""); // Clear the input field after adding the tag
    }
  };

  const handleDelete = (tagToDelete) => () => {
    setTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  const getJiraDetailsById = async () => {
    const [refreshToken, cloudId] =
      await fetchRefreshTokenAndCloudIdFromFirestore(location.state.userID);
    const accessToken = await refreshTokens(
      location.state.userID,
      refreshToken,
      APIConst.jiraServerUrl
    );
    // console.log("new access token recieved");
    const jiraProject = await fetchJiraProjectById(APIConst.jiraServerUrl,
      cloudId,
      accessToken,
      currentJiraDetails.project
    );

    const jiraUser = await fetchJiraAssigneeById(APIConst.jiraServerUrl,
      cloudId,
      accessToken,
      currentJiraDetails.assignee
    );
    
    setCurrentJiraDetails({
      ...currentJiraDetails,
      projectName : jiraProject.name,
      userName: jiraUser.displayName
    });
  }


  const getJiraProjects = async () => {
    const [refreshToken, cloudId] =
      await fetchRefreshTokenAndCloudIdFromFirestore(location.state.userID);
    const accessToken = await refreshTokens(
      location.state.userID,
      refreshToken,
      APIConst.jiraServerUrl
    );
    // console.log("new access token recieved");
    const jiraProjects = await fetchJiraProjects(
      APIConst.jiraServerUrl,
      cloudId,
      accessToken
    );
    // console.log("jira projects", jiraProjects);
    setJiraProjects(jiraProjects);
  };

  const getJiraAssignees = async () => {
    const [refreshToken, cloudId] =
      await fetchRefreshTokenAndCloudIdFromFirestore(location.state.userID);
    const accessToken = await refreshTokens(
      location.state.userID,
      refreshToken,
      APIConst.jiraServerUrl
    );
    const assignees = await fetchJiraAssignees(
      APIConst.jiraServerUrl,
      cloudId,
      accessToken
    );
    // console.log("jira users", assignees);
    // Filter the assignees based on the accountType being 'atlassian'
    const filteredAssignees = assignees.filter(
      (user) => user.accountType === "atlassian"
    );
    setJiraAssignee(filteredAssignees);
  };

  const getJiraProjectEpics = async () => {
    const [refreshToken, cloudId] =
      await fetchRefreshTokenAndCloudIdFromFirestore(location.state.userID);
    const accessToken = await refreshTokens(
      location.state.userID,
      refreshToken,
      APIConst.jiraServerUrl
    );
    const epics = await fetchJiraEpics(
      APIConst.jiraServerUrl,
      cloudId,
      accessToken,
      // currentJiraDetails.project
      jira.project.id
    );
    setJiraEpics(epics);
  };

  const getJiraProjectStories = async () => {
    const [refreshToken, cloudId] =
      await fetchRefreshTokenAndCloudIdFromFirestore(location.state.userID);
    const accessToken = await refreshTokens(
      location.state.userID,
      refreshToken,
      APIConst.jiraServerUrl
    );
    // const stories = await fetchJiraStories(
    //   APIConst.jiraServerUrl,
    //   cloudId,
    //   accessToken,
    //   currentJiraDetails.project,
    //   jira.epic
    // );
    // setJiraStories(stories);
  };

  const getJiraDetails = async () => {
    const updatedPath = location.state.path.substring(
      0,
      location.state.path.lastIndexOf("/")
    );
    // console.log("updated path", location.state.path);
    const docPath1 = `${updatedPath}/pmTools/Jira/${issueSnip.ruleId}/task`;
    const docPath2 = `Projects/${location.state.projectId}`;
    const res1 = await fetchJiraDetails(docPath1);
    // console.log("res1", res1);
    if (res1 !== undefined && res1 !== null) {
      setCardAssigned(true);
      setCurrentJiraDetails({
        ...currentJiraDetails,
        set: true,
        project: res1.jiraProjectId,
        assignee: res1.jiraUserId,
      });
      setJiraIssue(res1.taskKey);
    } else {
      const res2 = await fetchJiraDetails(docPath2);
      // console.log("res2", res2);
      if (res2 !== undefined && res2 !== null && res2.jiraProjectId) {
        setCurrentJiraDetails({
          ...currentJiraDetails,
          // set: true,
          project: res2.jiraProjectId,
          assignee: res2.jiraUserId,
        });
        const foundProject = jiraProjects.find(
          (project) => project.id === res2.jiraProjectId
        );
        const foundUser = jiraAssignee.find(
          (user) => user.accountId === res2.jiraUserId
        );

        // console.log(foundProject, foundUser);
        if (foundProject && foundUser) {
          setJira({
            ...jira,
            project: foundProject,
            assignee: foundUser,
          });
          setJiraStory({
            ...jiraStory,
            project: foundProject,
            assignee: foundUser,
          });
        }
      }
    }
  };

  const handelInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);
    if(name === 'epic') {
      const selectedEpic = jiraEpics.find((epic) => epic.id === value);
      setParentEpic(selectedEpic.key);
    }
    // if(name === 'story') {
    //   const selectedStory = jiraStories.find((story) => story.id === value);
    //   setParentStory(selectedStory.key)
    // }
    setJira({ ...jira, [name]: value });
    // console.log(jira);
    // console.log(name, value);
    // console.log("jira object", jira);
  };

  useEffect(() => {
    if (jiraProjects != [] && jiraAssignee != []) {
      getJiraDetails();
    }
  }, [jiraProjects, jiraAssignee]);

  useEffect(() => {
    if (props.userState == userStateValues.loggedOut) {
      goToLogin();
    } else {
    }
  });

  useEffect(() => {
      SetPmTool(location.state.userID,setEnableTool,enableTool);
  },[location.state]);


  useEffect(()=> {
    if(cardAssigned) {
      setAssignButtonText("Assigned");
    }
  },[cardAssigned]);

  useEffect(() => {
    getJiraProjects();
    getJiraAssignees();
    // console.log("location state inside details", location.state);
    // console.log(`${location.state.path}/${location.state.rows[0].id}`);
    setTags([
      `Conformance_level:${issueSnip.conformanceLevel}`,
      `Success_criteria:${issueSnip.wcagSuccessCriteriaId}`,
      `Severity:${issueSnip.severityLevel}`,
    ]);
    // const descObj = {
    //   impact: impact,
    //   fix: issueSnip.fix
    //,
    // }
    setJira({
      ...jira,
      description: `impact: ${impact}\n\n fix: ${issueSnip.fix}`,
      summary: issueSnip.ruleId,
    });
    setJiraStory({
      ...jiraStory,
      description: issueSnip.ruleDescription,
      summary: issueSnip.category,
    });
  }, []);

  useEffect(() => {
    // console.log("current jira det", currentJiraDetails);
    if (currentJiraDetails.set) {
      // console.log('calling getJiraDetailsById');
      getJiraDetailsById();
    }
  }, [currentJiraDetails.set]);

  useEffect(() => {
    if(enableTool.jira && jira.project) {
      getJiraProjectEpics();
    }
  },[jira.project]);

  // console.log(location.state.issue);
  const issueSnip = location.state.issue;
  // console.log("NOW", issueSnip);
  const [flag, setFlag] = useState(false);
  const impact = issueSnip.impact;

  // console.log("ImageUrls", location.state.imageUrls);
  // console.log("Location State At Details Page", location.state);

  // console.log("loc:",location.state);
  const rows2 = location.state.rows2;
  // console.log("R@", rows2);
  const rows3 = rows2.filter(
    (v, i, a) =>
      a.findIndex((t) =>
        ["path", "snippet", "id"].every((k) => t[k] === v[k])
      ) === i
  );
  // console.log("Row3", rows3);
  const altText = [];
  rows3.forEach((row) => {
    if (row.url === "Screenshot Unavailable") {
      altText.push("Screenshot Unavailable");
    } else {
      altText.push("Thumbnail");
    }
  });

  if (rows3 == null) {
    setFlag(true);
  }

  const openUrl = () => {
    window.open(location.state.url, "_blank");
  };

  const [open, setOpen] = React.useState({ state: false, url: "" });
  const expandImage = (url) => {
    setOpen({ state: true, url: url });
  };
  const issueArr = location.state.rows;
  // console.log("IssueArr", issueArr);
  const rows = [];

  // issueArr.forEach(async (element) => {
  //   let img = "";
  //   // console.log("XPN", element.XPath);
  //   // console.log("Change", location.state.change);
  // });

  const assignCard = async () => {
    setAssignButtonText("Assigning");
    // console.log("inside assign card", location.state.userID);
    const [refreshToken, cloudId] =
      await fetchRefreshTokenAndCloudIdFromFirestore(location.state.userID);
    const accessToken = await refreshTokens(
      location.state.userID,
      refreshToken,
      APIConst.jiraServerUrl
    );
    try {
      
      // const story = await createStory(
      //   APIConst.jiraServerUrl,
      //   accessToken,
      //   cloudId,
      //   jiraStory,
      //   tags,
      //   parentEpic
      // );
      const issue = await createIssue(
        APIConst.jiraServerUrl,
        accessToken,
        cloudId,
        jira,
        tags,
        parentEpic
      );
      const updatedPath = location.state.path.substring(
        0,
        location.state.path.lastIndexOf("/")
      );
      const docPath = `${updatedPath}/pmTools/Jira/${issueSnip.ruleId}/task`;
      if (issue != null && issue !== undefined) {
        await saveJiraDetails(
          docPath,
          jira.project.id,
          jira.assignee.accountId,
          issue.id,
          issue.key,
          jira.epic
        );
      }
      setAssignButtonText('Assigned');
      getJiraDetails();
    } catch (err) {
      console.log("error adding issue", err);
    }
  };

  const handleCopy = () => {
    const codeElement = document.querySelector('#code-impact pre');
    if (codeElement) {
      const codeText = codeElement.innerText; // Get the text inside <pre> tag
      navigator.clipboard.writeText(codeText).then(() => {
        alert('Code copied to clipboard!');
      });
    }
  };

  useEffect(() => {
    if (jira.epic != null) getJiraProjectStories();
  }, [jira.epic]);


  useEffect(() => {
    // Access all pre elements inside the div with id="code-impact"
    const preElements = document.querySelectorAll('#code-impact pre');
  
    // Loop through each pre element
    preElements.forEach((preElement) => {

      const existingButton = preElement.previousElementSibling;
    if (existingButton && existingButton.tagName === 'BUTTON') {
      existingButton.remove(); // Remove the existing button
    }

      // Create a button element
      const buttonElement = document.createElement('button');
      buttonElement.classList.add('copy-code-button');
      
      // Create an img element for the button
      const imgElement = document.createElement('img');
      imgElement.src = copy; // Replace with your image URL
      imgElement.alt = 'Copy Icon';
      imgElement.classList.add("copy-img");
  
      // Create a text node for the button
      const buttonText = document.createTextNode('Copy Code');
  
      // Append the img and text to the button
      buttonElement.appendChild(imgElement);
      buttonElement.appendChild(buttonText);
  
      // Add an event listener to the button to copy the code
      buttonElement.addEventListener('click', () => {
        buttonElement.innerText = 'Copied';
        setTimeout(()=> {
          setCodeCopyButton(!codeCopyButton);
        },5000);
        const codeText = preElement.innerText; // Get the text inside <pre> tag
        navigator.clipboard.writeText(codeText).then(() => {
          // alert('Code copied to clipboard!');
        });
      });
  
      // Insert the button before the pre element
      preElement.parentElement.insertBefore(buttonElement, preElement);
    });
  }, [codeCopyButton]);
  

  return (
    <div className="details">
      <button
        className="buttonBack"
        aria-label="Back Button"
        onClick={() => navigate(-1)}
      >
        {" "}
        <img src={backButton} className="backButton" alt="back" />
      </button>{" "}
      <text className="latest_heading">{issueSnip.ruleId} details</text>
      <div className="scanned_result_container">
        <div className="voilation_content">
          <h1 className="latest_heading">Violation: {issueSnip.ruleId}</h1>
          <div style={{ marginTop: "5px" }}>
            <br />
            <text className="description">{issueSnip.ruleDescription}</text>
          </div>
          <div style={{ marginTop: "8px" }}>
            <a
              to={issueSnip.helpUrls}
              className="help_url"
              href={issueSnip.helpUrls}
              target="_blank"
              rel="noreferrer"
            >
              More information about {issueSnip.ruleId}
            </a>
            {!enableTool.jira && <div className="impact_details" style={{
                  marginLeft: "5px",
                  color: "#707eae",
                  fontFamily: "'DM Sans',sans-sherrif",
                  fontWeight: 500,
                  fontSize: "16px",
                }}>
              To Assign Issue in jira, connect your account <a href="/settings">here</a>
            </div>}
            <div
              className="impact_details"
              style={{ display: enableTool.jira ? "block" : "none" }}
            >
              <h2
                style={{
                  marginLeft: "5px",
                  color: "#707eae",
                  fontFamily: "'DM Sans',sans-sherrif",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                {cardAssigned ? "Assigned Jira Card" : "Assign Jira Issues"}
              </h2>
              {/* {!cardAssigned && <h2
                style={{
                  marginLeft: "5px",
                  color: "#707eae",
                  fontFamily: "'DM Sans',sans-sherrif",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >Project: {currentJiraDetails.project} <br/> User: {currentJiraDetails.assignee}</h2>} */}
              <div className="impact_container">
                <div
                  className="impact-row"
                  style={{
                    marginBottom: "1rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <text
                    style={{
                      marginLeft: "5px",
                      marginTop: "1rem",
                      color: "#707eae",
                      fontFamily: "'DM Sans',sans-sherrif",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    project :
                  </text>
                  {!cardAssigned && (
                    <TextField
                      select
                      name="project"
                      label="Jira project"
                      value={jira.project}
                      onChange={handelInput}
                      sx={{
                        width: "60%",
                        mx: "5rem",
                        color: "#11047A",
                        background: "#F4F7FE",
                        maxHeight: "100px",
                        overflowY: "auto",
                      }}
                    >
                      <MenuItem value={""}></MenuItem>
                      {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
                      {jiraProjects.map((pro) => {
                        return (
                          <MenuItem key={pro.id} value={pro}>
                            {pro.name}
                          </MenuItem>
                        );
                      })}
                      {!jiraProjects.length && <MenuItem>No Projects</MenuItem>}
                    </TextField>
                  )}
                  {cardAssigned && (
                    <text
                      style={{
                        marginLeft: "5px",
                        marginTop: "1rem",
                        color: "#707eae",
                        fontFamily: "'DM Sans',sans-sherrif",
                        fontWeight: 500,
                        fontSize: "14px",
                        width: "60%",
                      }}
                    >
                      {currentJiraDetails.projectName}
                    </text>
                  )}
                </div>
                <br />
                <div
                  className="impact-row"
                  style={{
                    marginBottom: "3rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <text
                    style={{
                      marginLeft: "5px",
                      marginTop: "1rem",
                      color: "#707eae",
                      fontFamily: "'DM Sans',sans-sherrif",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Assignee :
                  </text>
                  {!cardAssigned && (
                    <TextField
                      select
                      name="assignee"
                      label="Jira assignee"
                      value={jira.assignee}
                      onChange={handelInput}
                      sx={{
                        width: "60%",
                        mx: "5rem",
                        color: "#11047A",
                        background: "#F4F7FE",
                        maxHeight: "100px",
                        overflowY: "auto",
                      }}
                    >
                      <MenuItem value={""}></MenuItem>
                      {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
                      {jiraAssignee.map((pro) => {
                        return (
                          <MenuItem key={pro.accountId} value={pro}>
                            {pro.displayName}
                          </MenuItem>
                        );
                      })}
                      {!jiraAssignee.length && <MenuItem>No Members</MenuItem>}
                    </TextField>
                  )}
                  {cardAssigned && (
                    <text
                      style={{
                        marginLeft: "5px",
                        marginTop: "1rem",
                        color: "#707eae",
                        fontFamily: "'DM Sans',sans-sherrif",
                        fontWeight: 500,
                        fontSize: "14px",
                        width: "60%",
                      }}
                    >
                      {currentJiraDetails.userName}
                    </text>
                  )}
                </div>
                {!cardAssigned && <div
                  className="impact-row"
                  style={{
                    marginBottom: "3rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <text
                    style={{
                      marginLeft: "5px",
                      marginTop: "1rem",
                      color: "#707eae",
                      fontFamily: "'DM Sans',sans-sherrif",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Epic :
                  </text>
                    <TextField
                      select
                      name="epic"
                      label="Jira Epic"
                      value={jira.epic}
                      onChange={handelInput}
                      sx={{
                        width: "60%",
                        mx: "5rem",
                        color: "#11047A",
                        background: "#F4F7FE",
                        maxHeight: "100px",
                        overflowY: "auto",
                      }}
                    >
                      <MenuItem value={null}></MenuItem>
                      {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
                      {jiraEpics && jiraEpics.map((pro) => {
                        const name = `${pro.fields.summary} (${pro.key})`;
                        const val = {
                          id: pro.id,
                          key: pro.key,
                        };
                        return (
                          <MenuItem key={pro.id} value={pro.id}>
                            {name}
                          </MenuItem>
                        );
                      })}
                      {!jiraEpics.length && <MenuItem>No Epics</MenuItem>}
                    </TextField>
                </div>}
                {/* <div
                  className="impact-row"
                  style={{
                    marginBottom: "3rem",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <text
                    style={{
                      marginLeft: "5px",
                      marginTop: "1rem",
                      color: "#707eae",
                      fontFamily: "'DM Sans',sans-sherrif",
                      fontWeight: 500,
                      fontSize: "14px",
                    }}
                  >
                    Story :
                  </text>
                  {!cardAssigned && (
                    <TextField
                      select
                      name="story"
                      label="Jira Story"
                      value={jira.story}
                      onChange={handelInput}
                      sx={{
                        width: "60%",
                        mx: "5rem",
                        color: "#11047A",
                        background: "#F4F7FE",
                        maxHeight: "100px",
                        overflowY: "auto",
                      }}
                    >
                      <MenuItem value={null}></MenuItem>
                      {jiraStories.map((pro) => {
                        const name = `${pro.fields.summary} (${pro.key})`;
                        const val = {
                          id: pro.id,
                          key: pro.key,
                        };
                        return (
                          <MenuItem key={pro.id} value={pro.id}>
                            {name}
                          </MenuItem>
                        );
                      })}
                      {!jiraStories.length && <MenuItem>No Stories</MenuItem>}
                    </TextField>
                  )}
                </div> */}
                {!cardAssigned && (
                  <div
                    className="impact-row"
                    style={{
                      marginBottom: "3rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <text
                      style={{
                        marginLeft: "5px",
                        marginTop: "1rem",
                        color: "#707eae",
                        fontFamily: "'DM Sans',sans-serif",
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Tags:
                    </text>

                    {!cardAssigned && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "start",
                          gap: "10px",
                          marginBottom: "3rem",
                        }}
                      >
                        <TextField
                          label="Enter tags"
                          variant="outlined"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                          onKeyDown={handleKeyDown}
                          sx={{
                            width: "60%",
                            background: "#F4F7FE",
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {tags.map((tag, index) => (
                            <Chip
                              key={index}
                              label={tag}
                              onDelete={handleDelete(tag)}
                              sx={{ marginTop: "5px" }}
                            />
                          ))}
                        </Box>
                      </Box>
                    )}
                  </div>
                )}
                {!cardAssigned && (
                  <div
                    className="impact-row"
                    style={{
                      marginBottom: "3rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <text
                      style={{
                        marginLeft: "5px",
                        marginTop: "1rem",
                        color: "#707eae",
                        fontFamily: "'DM Sans',sans-serif", // Fixed the font name
                        fontWeight: 500,
                        fontSize: "14px",
                      }}
                    >
                      Description :
                    </text>
                    {!cardAssigned && (
                      <TextField
                        name="description"
                        label="Issue Description"
                        value={jira.description}
                        onChange={handelInput}
                        sx={{
                          width: "60%",
                          mx: "5rem",
                          color: "#11047A",
                          background: "#F4F7FE",
                          overflowY: "auto",
                        }}
                        multiline // Converts to a textarea
                        rows={6} // Controls how many rows (height of the textarea)
                      />
                    )}
                  </div>
                )}
                <button onClick={assignCard} disabled={cardAssigned} className={cardAssigned?'assign assign-disabled':'assign'}>
                  {assignButtonText}
                </button>
              </div>
            </div>
            <div className="impact_details">
              <h2
                style={{
                  marginLeft: "5px",
                  color: "#707eae",
                  fontFamily: "'DM Sans',sans-sherrif",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                Compliance
              </h2>
              <div className="impact_container">
                <text
                  style={{
                    marginLeft: "5px",
                    color: "#707eae",
                    fontFamily: "'DM Sans',sans-sherrif",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Conformance level :
                </text>
                <text
                  style={{
                    marginLeft: "5px",
                    color: "#707eae",
                    fontFamily: "'DM Sans',sans-sherrif",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {issueSnip.conformanceLevel}
                </text>
                <br />
                <text
                  style={{
                    marginLeft: "5px",
                    color: "#707eae",
                    fontFamily: "'DM Sans',sans-sherrif",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Success criteria :
                </text>
                <text
                  style={{
                    marginLeft: "5px",
                    color: "#707eae",
                    fontFamily: "'DM Sans',sans-sherrif",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {issueSnip.wcagSuccessCriteriaId}
                </text>
                {/* <text
                  style={{
                    marginLeft: "5px",
                    color: "#707eae",
                    fontFamily: "'DM Sans',sans-sherrif",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  GIGW mapping :
                </text>
                <text
                  style={{
                    marginLeft: "5px",
                    color: "#707eae",
                    fontFamily: "'DM Sans',sans-sherrif",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  {issueSnip.GIGW}
                </text> */}
                <br />
                <text
                  style={{
                    marginLeft: "5px",
                    color: "#707eae",
                    fontFamily: "'DM Sans',sans-sherrif",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Severity :
                </text>
                <text style={{ color: "red", fontFamily: "DM Sans" }}>
                  {issueSnip.severityLevel}
                </text>
                <br />
              </div>
            </div>
            <div className="impact_details">
              <h2
                style={{
                  marginLeft: "5px",
                  color: "#707eae",
                  fontFamily: "'DM Sans',sans-sherrif",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                Impact
              </h2>
              <div className="impact_container">
                <p className="impact">{impact}</p>
              </div>
            </div>
            <div className="impact_details">
              <h2
                style={{
                  marginLeft: "5px",
                  color: "#707eae",
                  fontFamily: "'DM Sans',sans-sherrif",
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                How to Fix?
              </h2>
              <div className="impact_container">
                <p className="impact" id="code-impact"> <ReactMarkdown>{rows3[0].fix?rows3[0].fix:"Not available for this report"}</ReactMarkdown></p>
              </div>
            </div>
          </div>
          <br />
          <hr color="#E9EDF7" />
        </div>
        <div>
          <h2
            style={{
              marginLeft: "35px",
              color: "#707eae",
              fontFamily: "'DM Sans',sans-sherrif",
              fontWeight: 500,
              fontSize: "16px",
            }}
            hidden={flag}
          >
            Instances
          </h2>
          <TableContainer
            component={Paper}
            sx={{
              padding: "16px",
              paddingTop: "16px",
              marginTop: "32px",
              marginLeft: "20px",
              paddingBottom: "16px",
              border: "1px solid #E9EDF7",
              boxSizing: "border-box",
              borderRadius: "16px",
              boxShadow: "none",
              minWidth: "1090px",
              width: "100%",
              fontSize: "12px",
            }}
            hidden={flag}
          >
            <Table sx={{ minWidth: 650 }} aria-label="">
              <TableHead>
                <TableRow sx={{ borderBottom: "1px solid #E9EDF7" }}>
                  <TableCell align="left" sx={{ color: "#485585" }}>
                    Path
                  </TableCell>
                  <TableCell align="left" sx={{ color: "#485585" }}>
                    Snippet
                  </TableCell>
                  {/* <TableCell align="left" sx={{ color: "#485585" }}>
                    Fix
                  </TableCell> */}
                  <TableCell align="center" sx={{ color: "#485585" }}>
                    Screenshot
                  </TableCell>
                  {enableTool.jira && <TableCell align="center" sx={{ color: "#485585" }}>
                    Jira SubTask
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows3.map((row, index) => (
                  <>
                    <TableRow
                      key={row.name}
                      sx={{
                        paddingRight: "10px",
                        borderBottom: "1px solid #E9EDF7",
                      }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: "12px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        <div className="impact_container3">
                          <p className="impact">{row.path}</p>
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginRight: "16px",
                          marginLeft: "16px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        <div className="impact_container2">
                          <p className="impact">{row.snippet}</p>
                        </div>
                      </TableCell>
                      {/* {<TableCell
                        align="left"
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          marginRight: "16px",
                          marginLeft: "16px",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                        }}
                      >
                        <div className="impact_container1">
                          <p className="impact">{row.fix?row.fix: "Not available for this report"}</p>
                        </div>
                      </TableCell>} */}
                      <TableCell
                        align="center"
                        sx={{
                          color: "red",
                          fontSize: "12px",
                          cursor: "pointer",
                        }}
                      >
                        <a
                          onClick={() => expandImage(row.url)}
                          style={{ color: "#707EAE", marginRight: "50px" }}
                          aria-label="Click here to expand Image"
                        >
                          <img
                            src={row.url}
                            style={{
                              height: "197px",
                              width: "226px",
                              border: "1px solid #E9EDF7",
                              borderRadius: "8px",
                              opacity: "85%",
                            }}
                            alt={altText[index]}
                            onClick={() => expandImage(row.url)}
                          />
                        </a>
                      </TableCell>
                      {/* <TableCell
                      align="center"
                      sx={{
                        color: "red",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => expandImage(row.url)}
                        style={{ color: "#707EAE", marginRight: "50px" }}
                        aria-label="Click here to expand Image"
                      >
                        <img
                          src={row.url}
                          style={{
                            height: "197px",
                            width: "226px",
                            border: "1px solid #E9EDF7",
                            borderRadius: "8px",
                            opacity: "85%",
                          }}
                          alt={altText[index]}
                          onClick={() => expandImage(row.url)}
                        />
                      </a>
                    </TableCell> */}
                    
                    {enableTool.jira && <div className="jira-card-container">
                      {cardAssigned && (
                        <JiraDetailsForm
                          userId={location.state.userID}
                          jiraProjects={jiraProjects}
                          jiraAssignee={jiraAssignee}
                          projectId={location.state.projectId}
                          path={row.path}
                          snippet={row.snippet}
                          parentIssueKey={jiraIssue}
                          cardPath={location.state.path.substring(
                            0,
                            location.state.path.lastIndexOf("/")
                          )}
                          issueId={location.state.rows[index].id}
                          row={row}
                          ssUrl={row.url}
                          ruleId={issueSnip.ruleId}
                        />
                      )}
                      {!cardAssigned && <div style={{display: 'flex',alignItems: "center",height: '200px',width: '600px',justifyContent: 'center',fontFamily: 'sans-serif'}}> <h2>Create Jira Issue on top</h2></div>}
                    </div>}
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Dialog
        open={open.state}
        onClose={() => {
          setOpen({ state: false });
        }}
        style={{ width: "100%" }}
        PaperProps={{
          style: {
            height: "580px",
            minWidth: "1010px",
            borderRadius: "20px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle sx={{ fontSize: 24, color: "#2B3674" }}>
          Screenshot
          <button
            style={{
              marginLeft: "770px",
              background: "white",
              border: "none",
            }}
            aria-label="close "
            onClick={() => {
              setOpen(false);
            }}
          >
            <img src={close} alt="close" />
          </button>
        </DialogTitle>
        <DialogContent>
          <img
            src={open.url}
            style={{
              height: "463px",
              width: "945px",
              border: "1px solid #E9EDF7",
              borderRadius: "16px",
            }}
            alt="Screenshot indicating issue location"
            onClick={openUrl}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
