import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate,useLocation } from "react-router-dom";
import Logo from "../../assets/portal_logo.svg";
import LoginImage from "../../assets/LoginImage.svg";
import "./PasswordMgmt/style.css";
import { AuthContext } from "../Context/AuthContext";

export default function LogOut() {
  const navigate = useNavigate();
  const location = useLocation();
  

  return (
      <div className="login-container" style={{ height:'50vh',display:'flex', justifyContent: 'center', alignItems: 'center',flexWrap: 'wrap', backgroundColor: 'rgb(232, 240, 254)'}}>
      <div style={{ width: '50vw', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: '15rem'}}>
      <h1 style={{}}>Successfully Logged Out!!</h1>
        <Button
              type="submit"
              fullWidth={true}
              variant="contained"
              sx={{ mt: 5, mb: 2,ml: 10,mr: 10,borderRadius: "10px" }}
              onClick={()=> navigate('/')}
            >
              Okay
            </Button>
      </div>
      </div>
    );
}
