import React, { useState, useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { db, functions } from "../../../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { AuthContext } from "../../Context/AuthContext";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

const AddMember = (props) => {
  const { onClose, open, teamId, orgId } = props;
  const [members, setMembers] = useState([]);
  const [data, setData] = useState("");
  const [clicked, setClicked] = useState(true);
  const handleClose = () => {
    onClose();
  };
  useEffect(() => {
    if (orgId != "") {
      // console.log(props.orgId);
      // getMembers();
    }
    const unsub = onSnapshot(
      query(collection(db, "Users"), where("orgId", "==", orgId)),
      (snapShot) => {
        const membersData = [];
        setMembers([]);
        snapShot.docs.forEach((doc) => {
          let dataElement = {
            name: doc.data().name,
            role: doc.data().role,
            uid: doc.id,
          };
          membersData.push(dataElement);
          // console.log(doc.data());
          // console.log(membersData);
        });
        setMembers(membersData);
      }
    );
    return unsub;
  }, [orgId]);
  // console.log("Members", members);
  const handleChange = (e) => {
    setClicked(false);
    // console.log(e.target.value);
    const mem = e.target.value;
    setData(mem);
  };

  const addUser = async () => {
    // console.log(details);
    setClicked(true);
    const teamsRef = doc(db, "Organisations/" + orgId + "/teams/" + teamId);
    const userRef = doc(db, "Users/" + data);
    const teamsDoc = await getDoc(teamsRef);
    const userDoc = await getDoc(userRef);

    // console.log(teamsDoc.data());
    if (userDoc.data().teams) {
      let temp2 = [...userDoc.data().teams, teamId];
      temp2 = [...new Set(temp2)];
      const res2 = await setDoc(userRef, { teams: temp2 }, { merge: true });
    } else {
      let temp2 = [teamId];
      const res2 = await setDoc(userRef, { teams: temp2 }, { merge: true });
    }
    let tmp = [...teamsDoc.data().members, data];
    if (teamsDoc.data().members.length === tmp.length) {
      window.alert("Member Cannot Be Empty");
      onClose();
    }
    // console.log("tmp before", tmp);
    tmp = [...new Set(tmp)];
    // console.log("tmp after", tmp);
    const res = await updateDoc(teamsRef, { members: tmp });
    // console.log(res);
    onClose();
  };
  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "400px",
          height: "300px",
          padding: "16px",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <h1
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "1.2rem",
          alignItems: "center",
          color: "#11047A",
          fontSize: "25px",
        }}
      >
        <p>Add Member</p>
        <button
          aria-label="close"
          onClick={handleClose}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "12px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </h1>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        <TextField
          style={{ backgroundColor: "#F4F7FE" }}
          required
          select
          fullWidth
          onChange={handleChange}
        >
          {members?.map((data, index) => {
            // console.log(data);
            return (
              <MenuItem aria-label={data.name} key={index} value={data.uid}>
                {data.name}-{data.role}
              </MenuItem>
            );
          })}
        </TextField>

        <Button
          fullWidth
          arial-label="Add Member"
          variant="contained"
          disabled={clicked}
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          onClick={addUser}
        >
          Add Member
        </Button>
      </Box>
    </Dialog>
  );
};

export default AddMember;
