import { Button, Container, CssBaseline, Grid, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { signOut } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import { auth, db } from "../../firebaseConfig";

export default function TesterSecurity({ user }) {
  const [disabled, setDisabled] = useState(true);
  const fetchDetails = async () => {
    const userDocRef = doc(db, "Users/" + user.uid);
    const userSnap = await getDoc(userDocRef);
    setDetails({ ...userSnap.data() });
  };
  // console.log(details);
  useEffect(() => {
    fetchDetails();
    // fetchIndustries();
  }, []);
  // console.log(user);
  const [details, setDetails] = useState({});
  const handleInput = (e) => {
    setDisabled(false);
    const { name, value } = e.target;
    // console.log(name, value);

    setDetails({
      ...details,
      [name]: value,
    });
    // setDetails
  };

  const handleSubmit = async () => {
    setDisabled(true);
    const docRef = doc(db, "Users/" + user.uid);
    // console.log("docRef", docRef);
    await updateDoc(docRef, {
      name: details.name ? details.name : "",
      email: details.email,
      phoneNo: details.phoneNo ? details.phoneNo : "",
      orgId: details.orgId,
      role: details.role,
    });
    // window.alert("Updated");
    // console.log(details);
  };
  // console.log(details)
  return (
    <Container component="main">
      <CssBaseline />
      <h3
        style={{ position: "absolute", left: "360px", top: "120px" }}
        className="accessEmail"
      >
        Basic Info
      </h3>
      <div className="adminDiv">
        <Box sx={{ mt: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <label htmlFor="Name">Your Name</label>
              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                fullWidth
                autoComplete="name"
                name="name"
                placeholder="Contact Name"
                id="Name"
                value={details.name}
                onChange={handleInput}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="email">Email id</label>
              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                fullWidth
                disabled
                placeholder="jhondoe@example.com"
                id="email"
                name="email"
                autoComplete="email"
                value={details.email}
                onChange={handleInput}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="phoneNo">Phone (with country code)</label>

              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                fullWidth
                placeholder="+91 9999999999"
                id="phoneNo"
                name="phoneNo"
                autoComplete="phone"
                value={details.phoneNo}
                onChange={handleInput}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            disabled={disabled}
            variant="contained"
            sx={{
              width: "400px",
              height: "46px",
              mt: 3,
              mb: 2,
              borderRadius: "70px",
            }}
            onClick={handleSubmit}
          >
            Save Info
          </Button>
        </Box>
        <div style={{ display: "block" }}>
          <h3 className="accessEmail">Reset Password</h3>
          <Button
            // onClick={handleClick}
            style={{
              borderRadius: "70px",
              width: "439px",
              height: "46px",
              border: "1px solid #A3AED0",
            }}
            variant="outlined"
          >
            Reset My Password
          </Button>
        </div>
      </div>
      <br />
    </Container>
  );
}
