import React, { useEffect,useContext,useState } from "react";
import "./report_card.css";
import { useNavigate, useParams } from "react-router-dom";
import { auth, db } from "../../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import axios from "axios";
import more from "../../../assets/More BuTTon2.svg";
import { Menu, MenuItem } from "@mui/material";
import Manual from "./ScannedResult/Elements/Manual/Manual";
import { APIConst } from "../../../API const/APIConst";
import { AuthContext } from "../../Context/AuthContext";
import LinearIndeterminate from "../../Misc/LinearLoader/LinearLoader";
import EntireReport from "./ScannedResult/EntireReport";
import { useCallback } from "react";

export default function GithubActions() {
  const navigate = useNavigate();

  const {userState} = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null); 
  const [isUserStateLoaded, setIsUserStateLoaded] = useState(false);

  // console.log({ hasMore: props.hasMore });
  // console.log({ url: props.url });
  const params = useParams();
  let userID = auth?.currentUser?.uid;
  console.log("auth.current user",auth.currentUser);
  console.log("auth",auth);
  const uuid = params.uuid;
  console.log(params);

  const goToLogin = useCallback(() => {
    navigate("/", {
      state: {
        github: 'true',
        uuid: uuid
      }
    });
  }, [navigate, uuid]); // Ensure that navigate and uuid are included as dependencies

  //this is for the setinterval to call the fetchReport function in intervals;
  let intervalId;

  const fetchReport = useCallback( ()=> {
    console.log("inside fetch report");
    axios.post(
        APIConst.fetchReportByuid,
        {
          uuid: uuid,  // The body of the POST request
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'd1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8', // Add the x-api-key to the header
          },              
        //   timeout: 70000, // Set the timeout to 70 seconds
        }
      )
      .then((response)=>{
        console.log("fetched report",response);
        if(response.data.status !== 'Pending') {
          // navigate("/reports/scanned-result/entire-report", {
          //   state: {
          //     userID: userID,
          //     siteName: response.data.url,
          //     url: response.data.url,
          //     id: response.data.uuid,
          //     lastScanned: response.data.formattedTS,
          //     status: response.data.status,
          //     hasMore: response.data.hasMore?response.data.hasMore: false,
          //     entireDoc: response.data
          //   },
          // });
          setLoading(false);
          setReport({
            userID: userID,
            siteName: response.data.siteName,
            url: response.data.url,
            id: response.data.uuid,
            lastScanned: response.data.formattedTS,
            status: response.data.status,
            hasMore: response.data.hasMore?response.data.hasMore: false,
            entireDoc: response.data
          })
          
        }
        if (report && report.status === 'Completed' && report.hasMore === false) {
          console.log("Clearing interval as report is completed and hasMore is false");
          clearInterval(intervalId);
        } else {
          console.log("lol not going inside the iff !!! alert");
        }
      })
      .catch((err)=> {
        window.alert(err);
        console.log(err);
      })
  },[uuid, userID]);
  
  const checkIfLogged = useCallback( async () => {
    const userID = await localStorage.getItem('uid')?localStorage.getItem('uid'):auth?.currentUser?.uid;
    console.log("inside github", userID);

    if (!userID) {
      goToLogin();
    } else {
      fetchReport();
    }
  },[fetchReport, goToLogin]);

  useEffect(() => {
    console.log("useEffect called");
    console.log(isUserStateLoaded);
    if(isUserStateLoaded) {
      checkIfLogged();
    }
    // Set up the interval only if userState is loaded
    if (isUserStateLoaded) {
      intervalId = setInterval(() => {
        console.log("fetched report");
        fetchReport();
      }, 5000); // 5000 ms = 5 seconds

      // Cleanup the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [checkIfLogged, fetchReport, isUserStateLoaded]);

  useEffect(()=> {
    if(auth) {
      setIsUserStateLoaded(true);
    }
  },[]);

  // useEffect(()=> {
  //     if(isUserStateLoaded) {
  //       const intervalId = setInterval(() => {
  //         console.log("fetched report");
  //         fetchReport();
  //       }, 5000); // 5000 ms = 5 seconds
  //       // Cleanup the interval on component unmount
  //       return () => clearInterval(intervalId);
  //     }
  // },[fetchReport, isUserStateLoaded]);

  return (
    <>
      {loading && (
        <div className="loader-container">
        <p>loading...</p>
          <LinearIndeterminate />
        </div>
      )}
      {!loading && report != null && 
        <EntireReport 
            userID = {report.userID} 
            siteName = {report.siteName} 
            url = {report.url} 
            id = {report.id} 
            lastScanned = {report.lastScanned} 
            status = {report.status} 
            hasMore = {report.hasMore} 
            entireDoc = {report.entireDoc}

        />
      }
    </>
  );
}
