import React, { useContext, useEffect, useState } from "react";
import { onSnapshot, query, collection, where } from "firebase/firestore";
import InviteMember from "./Dialogs/InviteMember";
import RemoveMember from "./Dialogs/RemoveMember";
import { auth, db } from "../../firebaseConfig";
import { AuthContext } from "../Context/AuthContext";
import axios from "axios";
import { APIConst } from "../../API const/APIConst";
import { Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem } from "@mui/material";
import more from "../../assets/More BuTTon2.svg";
import "./projectsList.css"
import Projects from "../Schedule/Elements/Projects";
import { PmContext } from "../Context/PmContext";

export default function ProjectsList(props) {
  const { orgId } = props;
  const [members, setMembers] = useState([]);
  const [open, setOpen] = useState(false);
  const [projects,setProjects] = useState([]);
  

  const fetchProjects = () => {
    const payload = {
      userId: auth.currentUser.uid, // The body of the POST request
    };

    axios
      .post(APIConst.projectByUserId, JSON.stringify(payload), {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8", // Add the x-api-key to the header
        },
        timeout: 70000, // Set the timeout to 70 seconds
      })
      .then(async (response) => {
        console.log("fetched projects", response);
        await setProjects(response.data.projects);
        console.log("projects", projects);
        console.log(projects.length);
      })
      .catch((err) => {
        // window.alert(err);
        console.log(err);
      });
  };
  //test purpose

  useEffect(() => {
    fetchProjects();
  }, []);


//   useEffect(() => {
//     if (orgId != "") {
//       // console.log(props.orgId);
//       // getMembers();
//     }
//     const unsub = onSnapshot(
//       query(collection(db, "Users"), where("orgId", "==", orgId)),
//       (snapShot) => {
//         const membersData = [];
//         setMembers([]);
//         snapShot.docs.forEach((doc) => {
//           let dataElement = {
//             email: doc.data().email,
//             name: doc.data().name,
//             role: doc.data().role,
//             teams: doc.data().teams,
//             uid: doc.id,
//           };
//           membersData.push(dataElement);
//           // console.log(doc.data());
//           // console.log(membersData);
//         });
//         setMembers(membersData);
//       }
//     );
//     return unsub;
//   }, [orgId]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const { setClicked } = useContext(AuthContext);

  const handleClose = () => {
    setOpen(false);
    setClicked(false);
  };

  return (
    <div className="members-main-membersList">
      <div className="members-main-membersList-heading">
        <h2>{projects.length} Projects</h2>

        <button onClick={handleClickOpen}>+ Add Projects</button>
        {open && (
            <Projects
              open={open}
              onClose={handleClose}
              userId={auth.currentUser.uid}
              fetchProjects={fetchProjects}
              db={db}
              to={"create"}
            />
          )}
      </div>
      <hr
        style={{
          border: "1px solid #E9EDF7",
        }}
      />
      <table className="members-main-membersList-table">
        <thead className="members-table-head">
          <tr className="members-main-membersList-tr">
            <td>Name</td>
            <td>Id</td>
            {/* <td>Team</td> */}
            <td> </td>
          </tr>
        </thead>
        <tbody className="members-table-body">
          {projects.map((val, id) => (
            <TableRow val={val} key={id} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

const TableRow = (props) => {
  const data = props.val;
  const staticData = {
    ...data,
    team: "0",
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open2, setOpen2] = React.useState(false);
  const open3 = Boolean(anchorEl);
  const {enableTool,setEnableTool} = useContext(PmContext);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickEdit = () => {
    setOpen2(true);
  };

  const handleCloseEdit = () => {
    setOpen2(false);
  };

  return (
    <tr className="members-main-membersList-tr">
      <td>
        <div>
          {staticData.projectName}
          <br />
        </div>
      </td>
      <td>{staticData.projId}</td>
      {/* <td>{staticData.teams?.length}</td> */}
      <td>
        <button onClick={handleClickOpen}>
          <svg
            aria-label="Remove Member"
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
        <button
            className="DocmoreButton"
            aria-label="More Button"
            onClick={handleMenuClick}
            // disabled={!enableTool.jira}
            style={{ display: enableTool.jira ? "" : "none" }}
          >
            <img src={more} alt="More Button"></img>
          </button>
          <Projects
              open={open2}
              onClose={handleCloseEdit}
              userId={auth.currentUser.uid}
              db={db}
              to={"edit"}
              projectData={staticData}
            />
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open3}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            borderRadius: "10px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#A3AED0",
            boxShadow: "none",
            fontFamily: "'DM Sans','sans-serif'",
          },
        }}
      >
        <MenuItem onClick={handleClickEdit}>Add Jira details</MenuItem>
      </Menu>
        <RemoveMember
          name={data.projectName}
          uid={data.projId}
          open={open}
          onClose={handleClose}
        />
      </td>
    </tr>
  );
};
