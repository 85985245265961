import { createContext, useState } from "react";


export const PmContext = createContext();

export const PmContextProvider =({children})=>{
    
      const [enableTool,setEnableTool] = useState({
        jira: false
      });
    const [clicked,setClicked]= useState(false);

    console.log("Initial enableTool:", enableTool.jira);
    return(
        <PmContext.Provider value={{enableTool,setEnableTool,clicked,setClicked}}>
            {children}
        </PmContext.Provider>
    )

} 