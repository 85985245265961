import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import RequestCard from "./RequestCard";
import "./request.css";
import { auth, db } from "../../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
const Request = () => {
  /**
   * {
   *  requestId:"",
   *  action:"approve"|"reject"
   *  uid:""
   * }
   *
   */
  const {userState} = useContext(AuthContext)
  const navigate = useNavigate();
  const goToLogin = () => navigate("/");
  const [requests, setRequests] = useState([]);
  const fetchRequests = async () => {
    const colRef = collection(db, "Requests");
    const queryRef = query(colRef, where("status", "==", "pending"));
    const snap = await getDocs(queryRef);
    setRequests([]); // empty the stare before inserting
    for (let i = 0; i < snap.docs.length; i++) {
      let temp = snap.docs[i].data();
      temp.requestId = snap.docs[i].id;
      setRequests((prev) => [...prev, temp]);
    }
    // console.log("b");
  };

  useEffect(() => {
    if (userState.set && userState.uid === "") {
      goToLogin();
    }
    if(userState.role !=="Owner"){
      navigate(-1)
}
  },[]);

  useEffect(() => {
    fetchRequests();
  }, []);

  return (
    userState.uid !=="" && (
    <div className="container">
      <Sidebar />
      <main>
        <div className="requests">
          <h1 className="requests-title">New Requests</h1>
          {requests.map((item, id) => (
            <RequestCard key={id} data={item} />
          ))}
        </div>
      </main>
    </div>)
  );
};

export default Request;
