import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
import "./DocSnippet.css";
import more from "../../../assets/More BuTTon2.svg";
import { doc, updateDoc } from "firebase/firestore";
import { db, userStateValues } from "../../../firebaseConfig";
import axios from "axios";
export default function DocSnippet(props) {
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const [Edit, setEdit] = useState({
    description: "",
    notes: "",
    status: props.status,
  });

  const [file, setFile] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [scannedDocumentLink , setScannedDocumentLink] = useState("");

  const handelInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (value == "Passed") setShowUpload(true);
    // console.log(name,value);

    setEdit({ ...Edit, [name]: value });
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClickEdit = () => {
    setOpen2(true);
  };

  const handleCloseEdit = () => {
    setOpen2(false);
  };

  const handleFileChange = (e) => {
 // Get the selected file
    setScannedDocumentLink(e.target.value);
  };

  const handleEdit = async () => {
    // console.log("Documents/"+props.name);
    // const DocRef = doc(db, "Documents/" + props.name);
    // await updateDoc(DocRef, { ...Edit })
    //   .then(console.log("Done"))
    //   .catch((error) => {
    //     console.log(error);
    //   });

    const formData = new FormData();

    // Append the file and other metadata to the FormData object
    // formData.append("file", file); // 'file' is the key your server expects
    // formData.append("role", props.role);
    // formData.append("docName", props.name);
    // formData.append("status", Edit.status);
   try {
    console.log(Edit.status);
    const response = await axios.post(
      "https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testChangeDocStatus",
      {
        "role": props.role,
        "docName": props.name,
        "status": Edit.status,
        "scannedDocumentLink" : scannedDocumentLink,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8",
        },
      }
    );

    console.log(`response : ${JSON.stringify(response.data)}`);
   } catch (error) {
    console.log(error.message);
   }

    setOpen2(false);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click(); // Trigger the hidden file input
  };

  return (
    <div className="doc_snippet">
      <div className="button-item">
        <div className="docsite">
          <h3 className="siteName">{props.name}</h3>
          <a to={props.url} className="docUrl" href={props.url} target="_blank">
            {props.url}
          </a>
        </div>
        <div className="button-group">
          <button className="doc_button" onClick={handleClickOpen1}>
            <text style={{ textDecoration: "none" }}>View Details</text>
          </button>
          <button
            className="DocmoreButton"
            aria-label="More Button"
            onClick={handleMenuClick}
          >
            <img src={more} alt="More Button"></img>
          </button>
        </div>
      </div>
      {/* <text className='doc_title'>Date added:</text><text className='history_last'>{props.time.toDate().toDateString()}</text><br/> */}
      <text className="doc_title">File Type:</text>
      <text className="history_last">{props.type}</text>
      <br />
      <text className="doc_title">Accessibility Status:</text>
      <text className="history_issue">{props.status}</text>
      <br />
      <text className="doc_title">Project ID:</text>
      <text className="history_last">{props.projectId}</text>
      <br />
      <Dialog
        open={open1}
        onClose={handleClose1}
        PaperProps={{
          style: {
            borderRadius: "20px",
            height: "779px",
            width: "519px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle sx={{ fontSize: 24, color: "#2B3674" }}>
          Details for {props.name}
        </DialogTitle>
        <DialogContent>
          <div className="dialogQues">
            <label className="dialogLabel">URL</label>
            <input
              type="text"
              value={props.url}
              className="Dialoginput"
              disabled
            ></input>
          </div>
          <div className="dialogQues">
            <label className="dialogLabel">Document name</label>
            <input
              type="text"
              value={props.name}
              className="Dialoginput"
              disabled
            ></input>
          </div>
          {/* <div className='dialogQues'>
          <label className='dialogLabel'>Document description</label>
          <input type="text"  value={props.description} className='Dialoginput' style={{height:"75px"}} disabled></input>
          </div>
          <div className='dialogQues'>
          <label className='dialogLabel'>Additional Notes</label>
          <input type="text" value={props.notes} className='Dialoginput' style={{height:"75px"}} disabled></input>
          </div> */}

          {/* <fieldset style={{border:"none",fontFamily:'DM Sans',
    fontSize: "16px",
    color: "#2B3674"}} >
            <legend> Status</legend>
          <RadioGroup defaultValue={props.status} >
              <label className='form-check'>
              <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px",
               
             }} value={"Passed"} disabled/>Passed
             </label>
             <label className='form-check'>
              <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px",
               
             }} value={"Failed"} disabled/>Failed
             </label>
             <label className='form-check'>
              <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px",
               
             }} value={"Not Scanned"} disabled/>Not Scanned
             </label>
          </RadioGroup> 
          </fieldset>  */}

          <div className="dialogQues">
            <label className="dialogLabel">Document Status</label>
            <input
              type="text"
              value={props.status}
              className="Dialoginput"
              disabled
            ></input>
          </div>

          <div className="dialogQues">
            <label className="dialogLabel">Project ID</label>
            <input
              type="text"
              value={props.projectId}
              className="Dialoginput"
              disabled
            ></input>
          </div>

          {
            props.scannedDocumentLink && <div className="dialogQues">
            <label className="dialogLabel">Scanned Document Url</label>
            <input
              type="text"
              value={props.scannedDocumentLink}
              className="Dialoginput"
              disabled
            ></input>
          </div>
          }
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <button className="dialogButton" onClick={handleClose1}>
            Go Back
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleCloseEdit}
        PaperProps={{
          style: {
            borderRadius: "20px",
            height: "779px",
            width: "519px",
            padding: "20px",
          },
        }}
      >
        <DialogTitle sx={{ fontSize: 24, color: "#2B3674" }}>
          Edit Document {props.name}
        </DialogTitle>
        <DialogContent>
          <div className="dialogQues">
            <label className="dialogLabel">URL</label>
            <input
              placeholder="URL"
              type="text"
              value={props.url}
              className="Dialoginput"
              disabled
            ></input>
          </div>
          <div className="dialogQues">
            <label className="dialogLabel">Document name</label>
            <input
              placeholder="name"
              type="text"
              value={props.name}
              className="Dialoginput"
              disabled
            ></input>
          </div>
          <div className="dialogQues">
            <label className="dialogLabel">Document Status</label>
            <input
              type="text"
              value={props.status}
              className="Dialoginput"
              disabled
            ></input>
          </div>

          <div className="dialogQues">
            <label className="dialogLabel">Project ID</label>
            <input
              type="text"
              value={props.projectId}
              className="Dialoginput"
              disabled
            ></input>
          </div>
          {
            props.scannedDocumentLink && <div className="dialogQues">
            <label className="dialogLabel">Scanned Document Url</label>
            <input
              type="text"
              value={props.scannedDocumentLink}
              className="Dialoginput"
              disabled
            ></input>
          </div>
          }

          <fieldset
            style={{
              border: "none",
              fontFamily: "DM Sans",
              fontSize: "16px",
              color: "#2B3674",
            }}
          >
            <legend> Status</legend>
            <RadioGroup defaultValue={props.status}>
              {props.role == "Owner" ? (
                <label className="form-check">
                  <Radio
                    sx={{
                      height: "24px",
                      width: "24px",
                      color: "#E0E5F2",
                      marginRight: "12px",
                    }}
                    value={"Passed"}
                    name="status"
                    onChange={handelInput}
                  />
                  Passed
                </label>
              ) : (
                <>
                  <label className="form-check">
                    <Radio
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "#E0E5F2",
                        marginRight: "12px",
                      }}
                      value={"Mark for Remediation"}
                      name="status"
                      onChange={handelInput}
                    />
                    Mark for Remediation
                  </label>
                </>
              )}
            </RadioGroup>
          </fieldset>

          {/* Conditionally render file upload input */}
          {showUpload && (
            <div className="dialogQues">
              <label className="dialogLabel">Enter Scanned File Url</label>
              <input
                type="text"
                className="Dialoginput"
                value={scannedDocumentLink}
                onChange={handleFileChange} // Add this handler
              />
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <button className="dialogButton" onClick={handleEdit}>
            Save
          </button>
          <button className="dialogButton" onClick={handleCloseEdit}>
            Cancel
          </button>
        </DialogActions>
      </Dialog>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            borderRadius: "10px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "#A3AED0",
            boxShadow: "none",
            fontFamily: "'DM Sans','sans-serif'",
          },
        }}
      >
        <MenuItem onClick={handleClickEdit}>Edit Document</MenuItem>
      </Menu>
    </div>
  );
}
