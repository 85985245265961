import React, { useContext, useEffect } from "react";
import "./LatestReport.css";
import backButton from "../../../../assets/back_button.svg";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { userStateValues } from "../../../../firebaseConfig";
import { AuthContext } from "../../../Context/AuthContext";


// const GoBack=()=>{
//     let history=useNavigate();

// }
export default function LatestReport() {
    const {userState} = useContext(AuthContext)

  const location = useLocation();
  // console.log(location.state.siteName);
  const navigate = useNavigate();
  const goToLogin = () => navigate("/");

  useEffect(() => {
    if (userState == userStateValues.loggedOut) {
      goToLogin();
    }
  });

  const viewScannedResult = () => {
    navigate("/reports/latest/scanned-result", {
      state: {
        siteName: location.state.siteName,
        lastScanned: location.state.lastScanned,
        url: location.state.url,
        issuesFound: location.state.issuesFound,
      },
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    userState.uid !==""&& (
      <div className="latest_report">
        <div className="top-latest">
          <div>
            <img
              src={backButton}
              alt="back"
              className="backButton"
              onClick={goBack}
            />
            <text className="latest_heading">
              Latest Report on {location.state.siteName}
            </text>
          </div>
          <button className="buttonL">View Previous Reports</button>
        </div>
        <div className="card">
          <div className="chart_card">
            <text className="chart_heading">Issue Severity</text>
            <button className="buttonIS" onClick={viewScannedResult}>
              View all issues
            </button>
          </div>
          <div className="chart_card">
            <text className="chart_heading">Issue Types</text>
            <button className="buttonLIT" onClick={viewScannedResult}>
              <text style={{ textDecoration: "none" }} className="buttonLink">
                View Scanned Result
              </text>
            </button>
          </div>
          <div className="chart_card">
            <text className="chart_heading">No. of Elements scanned</text>
            <button className="buttonLIT" onClick={viewScannedResult}>
              <text style={{ textDecoration: "none" }} className="buttonLink">
                View Scanned Result
              </text>
            </button>
          </div>
        </div>
      </div>
    )
  );
}
