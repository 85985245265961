import React, { useContext, useEffect, useState } from "react";
import "./ScannedResult.css";
import backButton from "../../../../assets/back_button.svg";
import ScannedSnippet from "./Elements/ScannedSnippet";
import { useLocation, useNavigate } from "react-router-dom";
import { auth, db, userStateValues } from "../../../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { AuthContext } from "../../../Context/AuthContext";

export default function ScannedResult(props) {
  // const {userState} = useContext(AuthContext)
  // console.log("props",props);
  const navigate = useNavigate();
  const location = useLocation();
  const goToLogin = () => navigate("/");
  let siteName = "";
  let url = "";
  let latestReq = "";
  useEffect(() => {
    if (props.userState == userStateValues.loggedOut) {
      goToLogin();
    } else {
    }
  });
  const sanitizeUrl = (badUrl) => {
    // console.log("badUrl: ", badUrl);
    let res = "";
    for (let i = 0; i < badUrl.length; i++) {
      if (badUrl[i] != "/") res += badUrl[i];
      else if (i != badUrl.length - 1) {
        res += "_";
      }
    }
    return res;
  };
  // const sanitizedUrl = sanitizeUrl(location.state.url);
  try {
    siteName = location.state.siteName;
  } catch (error) {
    siteName = window.location.href.split("/").at(5);
  }
  try {
    url = sanitizeUrl(location.state.url);
  } catch (error) {
    url = window.location.href.split("/").at(5);
  }
  // console.log("props.URl at scanned result", url);
  console.log("state : ",{ state: location.state });
  try {
    latestReq = location.state.latestRequest;
  } catch (error) {
    latestReq = window.location.href.split("/").at(6);
  }

  // console.log("Sitename ", siteName);
  // console.log("Latest Report", latestReq);
  // console.log("location state at Scanned Result", location.state);

  const [Pages, setPages] = useState([]);
  const [Manuals, setManuals] = useState([]);
  // console.log("urls", location.state.result.urls);
  // console.log("manual", location.state.result.manual);
  useEffect(() => {
    getPagesInOrder();
  }, [location?.state?.userID]);

  useEffect(()=>{
    console.log("Pages : ",Pages);
  },[Pages])
  const userID = location?.state?.userID;
  const getPagesInOrder = async () => {
    // const userID = auth.currentUser.uid;
    if (!userID) {
      return;
    }
    console.log(
      "Projects/" +
        location.state.projectID +
        "/Reports/" +
        siteName +
        "/" +
        url +
        "/" +
        latestReq +
        "/pageWiseIssueCount");
    const ref = collection(
      db,
      "Projects/" +
        location.state.projectID +
        "/Reports/" +
        siteName +
        "/" +
        url +
        "/" +
        latestReq +
        "/pageWiseIssueCount"
    );
    const docRef = doc(
      db,
      "Projects/" + location.state.projectID + "/Reports/" + siteName + "/" + url + "/" + latestReq
    );
    // console.log("ref", ref);
    const q = query(ref, orderBy("issueCount", "desc"));
    const mRef = collection(db, "Manuals");
    const manual = await getDocs(mRef);
    const snapshot = await getDocs(q);
    console.log("snapshot", snapshot.docs);
    setPages(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    const queryDoc = (await getDoc(docRef)).data();
    // console.log("Q", queryDoc.downloadUrl);
    setManuals(manual.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  // console.log("Pages", Pages);
  // console.log("manual", Manuals);

  // const goBack = () => {
  //   // console.log("CLICK");
  //   navigate(-1);
  // };

  // console.log(window.location.href);

  return (
    <div className="scanned_result">
      <button
        className="buttonBack"
        aria-label="Back Button"
        onClick={() => navigate(-1)}
      >
        {" "}
        <img src={backButton} className="backButton" alt="back" />
      </button>
      <text className="latest_heading">
        Scanned results {siteName} on {location.state.lastScanned}
      </text>
      <div className="scanned_result_container">
        <br />
        {/* <ScannedSnippet buttonLink={'button_link'} lastScanned = {location.state.lastScanned} url={location.state.url} to={'entire-report'} issues={43} buttonType={'buttonEntire'} siteName= {location.state.siteName} />
            <br/>
            <br/>
            <hr color='#E0E5F2' className='SR_Sep'/> */}
        <br />
        <h1 className="SR_heading">Page wise Reports</h1>
        <br />
        {/* <input type="text" className\='searchbar_SR' placeholder="Search pages"></input> */}
        {/* <input type="text" className\='searchbar_SR' placeholder="Search pages"></input> */}
        {Pages.map((page, idx) => {
          // console.log("page:", page);
          return (
            <ScannedSnippet
              key={idx}
              userID={userID}
              buttonLink={"button_link2"}
              lastScanned={null}
              badUrl={url}
              url={page.pageUrl}
              to={"entire-report"}
              issues={page.issueCount}
              warns={page.warningsCount}
              buttonType={"buttonPage"}
              siteName={siteName}
              pageIndex={idx}
              manual={Manuals}
              fails={page.fails}
              passes={page.passes}
              latestRequest={latestReq}
              warnings={page.warnings}
              projectId={location.state.projectID}
            />
          );
        })}
        <br />
      </div>
    </div>
  );
}
