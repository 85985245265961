import React, { useState, useEffect } from "react";
import "./teamsList.css";
import CreateTeam from "./Dialogs/CreateTeam";
import RemoveTeam from "./Dialogs/RemoveTeam";
import { db } from "../../firebaseConfig";
import {
  onSnapshot,
  collection,
  getDocs,
  query,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import AddMember from "./Dialogs/AddMember";
import TeamMembersList from "./Dialogs/TeamMemberList";

export default function TeamsList(props) {
  const { orgId,role } = props;
  const [open, setOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  // async function getTeams() {
  //   const teamsRef = query(collection(db, "Organisations/" + orgId + "/teams"));
  //   const teamsDoc = await getDocs(teamsRef);

  //   let teamsData = [];

  //   teamsDoc.forEach((doc) => {
  //     let dataElement = {
  //       teamName: doc.data().teamName,
  //       members: doc.data().members,
  //       teamId: doc.id,
  //       orgId: orgId,
  //     };
  //     teamsData.push(dataElement);
  //   });
  //   // console.log("teamsData while getting",teamsData);
  //   setTeamMembers(teamsData);
  // }

  useEffect(() => {
    const unsub = onSnapshot(
      query(collection(db, "Organisations/" + orgId + "/teams")),
      (snapShot) => {
        setTeamMembers([]);
        let teamsData = [];
        snapShot.forEach((doc) => {
          let dataElement = {
            teamName: doc.data().teamName,
            members: doc.data().members,
            teamId: doc.id,
            orgId: orgId,
          };
          teamsData.push(dataElement);
        });
        // console.log("teamsData in snapshot", teamsData);
        setTeamMembers(teamsData);
      }
    );
    return unsub;
  }, [orgId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="teams-main-teamsList">
      <div className="teams-main-teamsList-heading">
        <h2>{teamMembers.length} Teams</h2>

        {(role=="Admin" || role=="Manager") && <button onClick={handleClickOpen}>+ Create Team</button>}
        <CreateTeam open={open} onClose={handleClose} />
      </div>
      <hr
        style={{
          border: "1px solid #E9EDF7",
        }}
      />
      <table className="teams-main-teamsList-table">
        <thead>
          <tr className="teams-main-teamsList-tr">
            <td>Team</td>
            <td>Members</td>
            <td>Invite Members</td>
            <td> </td>
          </tr>
        </thead>
        <tbody>
          {teamMembers.map((val, id) => (
            <TableRow val={val} key={id} />
          ))}
        </tbody>
      </table>
    </div>
  );
}
//teams-main-teamsList

const TableRow = ({ val }) => {
  const data = { ...val };
  const [open, setOpen] = useState(false);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [openSeeMember, setSeeMember] = useState(false);
  const [details, setDetails] = useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSeeMember(false);
    setOpenAddMember(false);
    setOpen(false);
  };
  const handleAdd = () => {
    setOpenAddMember(true);
    // console.log("Adding Member");
  };
  const handleSee = async (teamId) => {
    const teamsRef = doc(
      db,
      "Organisations/" + data.orgId + "/teams/" + teamId
    );
    const teamsDoc = await getDoc(teamsRef);
    const memList = teamsDoc.data().members;
    setDetails([]);
    memList.forEach(async (data) => {
      const userRef = doc(db, "Users/" + data);
      const UserData = await getDoc(userRef);
      setDetails((prev) => [...prev, UserData.data()]);
    });
    // setDetails(teamsDoc.data().members);
    // console.log("details", details);
    setSeeMember(true);
    // console.log("Seeing Member");
  };

  return (
    <tr className="teams-main-teamsList-tr">
      <td>{data.teamName}</td>
      <td
        aria-label="See Members"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        // onClick={() => handleSee(data.teamId)}
      >
        <a href="#" onClick={() => handleSee(data.teamId)}>{data.members.length}</a>
      </td>
      <td
        aria-label="Add Member"
        style={{ textDecoration: "underline", cursor: "pointer" }}
        // onClick={handleAdd}
      >
        <a href="#" onClick={handleAdd}>Add Members</a>
      </td>
      <td>
        <button aria-label="Remove Team" onClick={handleClickOpen}>
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
        <TeamMembersList
          details={details}
          open={openSeeMember}
          onClose={handleClose}
        />
        <AddMember
          open={openAddMember}
          teamId={data.teamId}
          orgId={data.orgId}
          onClose={handleClose}
        />
        <RemoveTeam
          teamName={data.teamName}
          teamId={data.teamId}
          orgId={data.orgId}
          open={open}
          onClose={handleClose}
        />
      </td>
    </tr>
  );
};
