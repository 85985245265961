import React, { useContext, useEffect, useState,useCallback } from "react";
import "./Settings.css";
import Sidebar from "../Sidebar/Sidebar";
import { db, resetPassword, userStateValues } from "../../firebaseConfig";
import { useNavigate,useParams,useLocation,useSearchParams } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";
import { collection, getDocs, query, where, doc, setDoc, updateDoc, getDoc,deleteField } from "firebase/firestore";
import { Button } from "@mui/material";
// import AdminSecurity from "./AdminSecurity";
// import TesterSecurity from "./TesterSecurity";
import InviteOwner from "../Members/Dialogs/InviteOwner";
import ActionAreaCard from "./PmCard";
import axios from "axios";
import { APIConst } from "../../API const/APIConst";
import { fetchRefreshTokenAndCloudIdFromFirestore, fetchUserJiraDetails } from "../../Utils/JiraFunctions";
import { PmContext } from "../Context/PmContext";

export default function Settings() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userState, setClicked } = useContext(AuthContext);
  const {enableTool,setEnableTool } = useContext(PmContext); 
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [jiraLoggedIn,setJiraLoggedIn] = useState(false);
  const [refreshToken,setRefreshToken] = useState(null);
  const [cloudId,setCloudId] = useState(null);


  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');

  console.log('code:', code);
  console.log('state:', state);

  console.log("enable tool from settings",enableTool.jira);

  const storeParamsInFirestore = async () => {

    if (code && state) {
      try {
        // Define the document reference where you want to store the parameters
        const docRef = doc(db, `Users/${userState.uid}/pmTools/Jira`);

        // Set the 'store' and 'state' parameters to Firestore
        await setDoc(docRef, { code, state }, { merge: true });

        console.log("Store and state parameters stored successfully in Firestore!");

        const payload = {
          code: code,
          redirectUri: APIConst.jiraRedirectUrl
        }
        axios.post(`${APIConst.jiraServerUrl}/jira/token`,JSON.stringify(payload),{
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(async (response) => {
        console.log(response);
        const access_token = response.data.access_token;
        const refresh_token = response.data.refresh_token;
        try{
          await updateDoc(docRef, { access_token, refresh_token }, { merge: true });
          console.log("successfully saved refresh and access tokens in firestore");

          
          //get cloud Id
          axios.get(`${APIConst.jiraServerUrl}/jira/accessible-resources`,{
          headers: {
            "Authorization": `Bearer ${access_token}`,
            "Content-Type": "application/json",
          }
        })
        .then(async (response) => {
          console.log(response);
          const cloud_id = response.data[0].id;
          try{
            await updateDoc(docRef, { cloud_id }, { merge: true });
            console.log("successfully saved cloud Id in firestore");
            console.log("making true");
            setEnableTool({
              ...enableTool,
              jira:true
            });
          } catch(error) {
            console.log("error adding cloud Id to firestore",error);
          }
        })
        .catch((error) => {
          // window.alert(error);
          console.log(error);
        });







        } catch(error) {
          console.log("error adding tokens to firestore",error);
        }
      })
      .catch((error) => {
        // window.alert(error);
        console.log(error);
      });

      } catch (error) {
        console.error("Error storing store and state parameters in Firestore:", error);
      }
    } else {
      console.log("Missing 'code' or 'state' parameter.");
    }
  };

  const deleteFieldsFromFirestore = async () => {
    try {
      // Define the document reference
      const docRef = doc(db, `Users/${userState.uid}/pmTools/Jira`);
  
      // Delete specific fields by setting them to `deleteField()`
      await updateDoc(docRef, {
        code: deleteField(),
        state: deleteField(),
        access_token: deleteField(),
        refresh_token: deleteField(),
        cloud_id: deleteField(),
      });
  
      console.log("Fields deleted successfully from Firestore!");
      setEnableTool({
        ...enableTool,
      jira: false
      });
    } catch (error) {
      console.error("Error deleting fields from Firestore:", error);
    }
  };

  const fetchRequestsOwner = async () => {
    const colRef = collection(db, "Users");
    const queryRef = query(colRef, where("role", "==", "Owner"));
    const snap = await getDocs(queryRef);
    setUsers([]);
    for (let i = 0; i < snap.docs.length; i++) {
      let temp = snap.docs[i].data();
      // console.log("snap Data",temp);
      setUsers((prev) => [...prev, temp]);
    }
  };
  // console.log(users);

  const goToLogin = () => navigate("/");

  useEffect(() => {
    if (userState.uid === "") {
      // goToLogin();
    } else {
      storeParamsInFirestore(); // Store parameters on load
    }
    if (userState.role == "Owner") {
      fetchRequestsOwner();
    }
  }, [userState]);

  const handleOpen = () => {
    setClicked(false);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = async () => {
    // console.log(userState);
    resetPassword(userState.email);
    // window.alert("Sent Successfully");
    console.log("Sent");
  };

  useEffect(()=> {
    const res = fetchRefreshTokenAndCloudIdFromFirestore(userState.uid);
    setRefreshToken(res[0]);
    setCloudId(res[1]);
  },[userState]);

 const getUserJiraDetails = async ()=>{
  console.log("enable tool from settings",enableTool);
  if(userState.uid) {
    const data = await fetchUserJiraDetails(userState.uid);
    console.log("user has jira",data.access_token);
    if(data.access_token !== undefined && data.access_token !== null) {
      console.log("making true");
      setEnableTool({
        ...enableTool,
      jira: true
      });
    } else {
      setEnableTool({
        ...enableTool,
      jira: false
      });
    }
  }
 };


  useEffect(() => {
    getUserJiraDetails();
  },[]);

  return (
    userState.uid !== "" && (
      <>
        <Sidebar />
        <main>
          <div className="settings">
            <div className="top-item">
              <h1 className="heading">Settings</h1>
            </div>

            {userState.role === "Owner" && (
              <>
                <div className="deatils">
                  <h3 className="accessEmail">
                    Active email IDs with owner access
                  </h3>
                  <div className="accessEmaiList">
                    {users?.map((data) => {
                      return <span className="emails">{data.email}</span>;
                    })}
                  </div>
                  <br />
                  <Button
                    onClick={handleOpen}
                    style={{
                      borderRadius: "70px",
                      width: "439px",
                      height: "46px",
                      border: "1px solid #A3AED0",
                    }}
                    variant="outlined"
                  >
                    Add Owner
                  </Button>
                  <br />
                  <h3 className="accessEmail">Reset Password</h3>
                  <Button
                    onClick={handleClick}
                    style={{
                      borderRadius: "70px",
                      width: "439px",
                      height: "46px",
                      border: "1px solid #A3AED0",
                    }}
                    variant="outlined"
                  >
                    Reset My Password
                  </Button>
                </div>

                <InviteOwner open={open} onClose={handleClose} />
              </>
            )}
            <div className="card-container">
              <h2>Connect to Product Management Tools</h2>
              <ActionAreaCard isLoggedIn = {enableTool.jira === true} disconnect={deleteFieldsFromFirestore}/>
            </div>
          </div>
        </main>
      </>
    )
  );
}
