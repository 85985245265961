import { createContext, useState } from "react";


export const AuthContext = createContext();

export const AuthContextProvider =({children})=>{
    const initialState = {
        role: "",
        uid: "",
        orgId: "",
        name: "",
        email: "",
        phoneNo:"",
        set: false,
      };
      const [userState, setUserState] = useState(initialState);
    const [clicked,setClicked]= useState(false)
    return(
        <AuthContext.Provider value={{userState,setUserState,initialState,clicked,setClicked}}>
            {children}
        </AuthContext.Provider>
    )

} 