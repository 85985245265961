/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import logo from "../../assets/NCLUDE.AI.png";
import schedule from "../../assets/Schedule Icon2.svg";
import reports from "../../assets/Vector.svg";
import newreqest from "../../assets/newreqest.svg";
import metrics from "../../assets/airplay.svg";
import inventory from "../../assets/movie.svg";
import security from "../../assets/Security Icon2.svg";
import logout from "../../assets/logout.svg";
import user from "../../assets/user.svg";
import { NavLink } from "react-router-dom";
import "./sidebar.css";
import { Typography } from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { AuthContext } from "../Context/AuthContext";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
export default function Sidebar() {
  const { userState, setClicked } = useContext(AuthContext);
  // console.log({ userState });
  const navigate = useNavigate();
  const logoutNow = async () => {
    localStorage.removeItem('uid');
    setClicked(false);
    await signOut(auth);
    console.log("Signed Out",auth);
    navigate("/logout");
  };

  return (
    <div className="sidebar">
      <div className="top-section">
        <div className="logo">
          <img src={logo} alt="Portal Logo" height={'15px'} style={{ marginLeft: '1rem'}}/>
        </div>
        <div className="seperator">
          <hr color="#e5e5e5" />
        </div>
        <nav>
          <div className="menuList">
            {(userState.role === "Admin" ||
              userState.role === "Manager" ||
              userState.role === "Developer" ||
              userState.role === "Tester") && (
              <>
                <div className="item">
                  <img className="icon" src={schedule} />
                  <NavLink
                    to="/schedule"
                    className={({ isActive }) =>
                      !isActive ? "text" : "active"
                    }
                    exact
                    style={{ textDecoration: "none" }}
                  >
                    Schedule
                  </NavLink>
                </div>

                <div className="item">
                  <img className="icon" src={inventory} />
                  <NavLink
                    to="/inventory"
                    className={({ isActive }) =>
                      !isActive ? "text" : "active"
                    }
                    exact
                    style={{ textDecoration: "none" }}
                  >
                    Documents Inventory
                  </NavLink>
                </div>

                <div className="item">
                  <img className="icon" src={inventory} />
                  <NavLink
                    to="/members"
                    className={({ isActive }) =>
                      !isActive ? "text" : "active"
                    }
                    exact
                    style={{ textDecoration: "none" }}
                  >
                    Members & Projects
                  </NavLink>
                </div>
              </>
            )}
            {userState.role !== "Owner" && (
              <div className="item">
                <img className="icon" src={reports} />
                <NavLink
                  to="/reports"
                  className={({ isActive }) => (!isActive ? "text" : "active")}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  Reports
                </NavLink>
              </div>
            )}
            {userState.role === "Owner" && (
              <>
              <div className="item">
                <img className="icon" src={newreqest} />
                <NavLink
                  to="/requests"
                  className={({ isActive }) => (!isActive ? "text" : "active")}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  Requests
                </NavLink>
              </div>
              <div className="item">
                  <img className="icon" src={inventory} />
                  <NavLink
                    to="/inventory"
                    className={({ isActive }) =>
                      !isActive ? "text" : "active"
                    }
                    exact
                    style={{ textDecoration: "none" }}
                  >
                    Documents Inventory
                  </NavLink>
                </div>
                </>
            )}
            {userState.role === "Owner" && (
              <div className="item" style={{ alignItems: "center" }}>
                <img className="icon" src={user} />
                <NavLink
                  to="/approved"
                  className={({ isActive }) => (!isActive ? "text" : "active")}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  Approved Requests
                </NavLink>
              </div>
            )}
            {userState.role !== "Owner" && (
              <div className="item" style={{ alignItems: "center" }}>
                <img className="icon" src={metrics} />
                <NavLink
                  to="/metrics"
                  className={({ isActive }) => (!isActive ? "text" : "active")}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  Metrics
                </NavLink>
              </div>
            )}
            {userState?.role && (
              <div className="item">
                <img className="icon" src={security} />
                <NavLink
                  to="/security"
                  className={({ isActive }) => (!isActive ? "text" : "active")}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  Security
                </NavLink>
              </div>
            )}
            {userState?.role && (
              <div className="item">
                <img className="icon" src={security} />
                <NavLink
                  to="/settings"
                  className={({ isActive }) => (!isActive ? "text" : "active")}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  Settings
                </NavLink>
              </div>
            )}
            {userState?.role && (
              <div
                onClick={logoutNow}
                className="item_logout"
                style={{ marginTop: "auto" }}
              >
                <img className="icon" src={logout} />
                <NavLink
                  to="/"
                  className={({ isActive }) => (!isActive ? "text" : "active")}
                  exact
                  style={{ textDecoration: "none" }}
                >
                  Log Out
                </NavLink>
              </div>
            )}
          </div>
        </nav>
      </div>
    </div>
  );
}
