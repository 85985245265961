import React, { useEffect, useState } from 'react'
import './Details.css'
import backButton from '../../../../../../assets/back_button.svg'
import { Accordion, AccordionDetails, AccordionSummary, Dialog, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import Arrow from '../Automated/Arrow'
import { useLocation, useNavigate } from 'react-router-dom'
import close from '../../../../../../assets/x-square.png'
import { storage } from '../../../../../../firebaseConfig'
import { getDownloadURL, ref } from 'firebase/storage'

export default function PDetails() {
  const location = useLocation()
    const navigate=useNavigate();
    // console.log(location.state.issueIdx);
    const issueSnip = location.state.issue;
    // console.log(issueSnip);
    const [flag , setFlag] = useState(false);
   // const src = "https://storage.googleapis.com/screenshots-p0pfx/alphagov.github.io/9999/https%3A%5C%5Calphagov.github.io%5Caccessibility-tool-audit%5Ctest-cases.html/Passed/SS/344.png?GoogleAccessId=firebase-adminsdk-n7s29%40testproj-cef55.iam.gserviceaccount.com&Expires=2532729600&Signature=yPUB0nIUh0X9SvIMMb4i04UGrGhfnsBdZKzSOW4b28oJgkKYU4v1fwwGxd%2B3ptixk811SzED0TtmpzM2EM95cuhZl6NRWOiJk%2Fb67Kdrmjz72AuFlmZvHmkr%2BE2AK60oXM9pdPMW3cO7TcZLZ%2F%2Bc6VfrL5ZSW36efVZqAs0hH8mqv5AniQzrWMaqQw1ESs2qSNiVYoaTF9DwinwMEn4JIn570b6FYNpRS89MK6%2F8QG4Xzy%2Fd9Q6FXZZUmiWDXV4Wh30BYCmoTUwmJw2z01YKg7x1E5fee0JC9ekgACEQkJNj5Wx0S8revwhIztSCe%2FG392JsVLWRF%2B4Uel5moXRMoQ%3D%3D";
    const impact = (issueSnip.impact);
    // const top = issueSnip.boundingBox.top;
    // console.log(top);
    // console.log("ImageUrls",location.state.imageUrls)
    // const left = issueSnip.boundingBox.left;
    // console.log(left);
    // const height = issueSnip.boundingBox.height;
    // const width = issueSnip.boundingBox.width;
    // const imageUrls = location.state.imageUrls
    // const imageurl = "url("+location.state.imageUrl+")";
    // console.log(imageurl);
   const rows2 = location.state.rows2;
  //  console.log("R@",location.state.rows);
   const rows3 = rows2.filter((v,i,a)=>a.findIndex(t=>['path','snippet'].every(k=>t[k] ===v[k]))===i)
  //  console.log("Row3",rows3);
    if(rows3==null){
      setFlag(true);
    }
   const openUrl=()=>{
     window.open(location.state.url , "_blank")
   }
    

    const [open, setOpen] = React.useState({state:false , url:""});
    const expandImage = (url) =>{
      setOpen({state:true,url:url});
    }
    const issueArr = location.state.rows;
    // console.log("IssueArr",issueArr)
    const rows = [];
    
    issueArr.forEach(async(element) => {
      let img =""
      // console.log("XPN",element.XPath);
      // console.log("Change",location.state.change)
    });
    return (
        <div className='details'>
           <button className='buttonBack' aria-label='Back Button' onClick={()=> navigate(-1)}> <img src={backButton} className='backButton'alt='back' /></button> <text className='latest_heading'>{issueSnip.ruleId} details</text>
             <div className='scanned_result_container'>
                 <div className='voilation_content'>
                 <h1 className='latest_heading'>Violation: {issueSnip.ruleId}</h1>
                 <div style={{marginTop:"5px"}}>
                   <br/>
                 <text className='description'>{issueSnip.ruleDescription}</text>
                 </div>
                 <div style={{marginTop:"8px"}}>
                 <a to={issueSnip.helpUrls} className='help_url' href={issueSnip.helpUrls} target='_blank'>More information about {issueSnip.ruleId}</a>
                 <div className='impact_details'>
                <h2 style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}}>Compliance</h2>
                <div className='impact_container'>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>Conformance level :</text ><text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>{issueSnip.conformanceLevel}</text><br/>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>Success criteria :</text><text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>{issueSnip.wcagSuccessCriteriaId}</text><br/>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>GIGW mapping :</text><text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>{issueSnip.GIGW}</text><br/>
                  <text style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"14px"}}>Severity :</text><text style={{color:"red",fontFamily:"DM Sans"}}>{issueSnip.severityLevel}</text><br/>
                </div>

             </div>
                  <div className='impact_details'>
                <h2 style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}}>Impact</h2>
                <div className='impact_container'><p className='impact'>{impact}</p></div>

             </div>
             {/* <div className='impact_details'>
                <h2 style={{marginLeft:"5px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}}>How to Fix?</h2>
                <div className='impact_container'><p className='impact'>{issueSnip.fix}</p></div>

             </div> */}
             </div>
             <br/>
      <hr color='#E9EDF7'/>
            
      
             </div>
             <div>
             <h2 style={{marginLeft:"35px",color:"#707eae",fontFamily:"'DM Sans',sans-sherrif",fontWeight:500,fontSize:"16px"}} hidden={flag}>Instances</h2>
               <TableContainer component={Paper} sx={{padding:"16px",
           paddingTop:"16px",
           marginTop:"32px",
           marginLeft:"32px",
           paddingBottom:"16px",
         border:"1px solid #E9EDF7",
         boxSizing: "border-box",
         borderRadius:"16px",
         boxShadow:"none",
         width:"1059px",
         fontSize:"12px"}} hidden={flag}>
             <Table sx={{ minWidth: 650 }} aria-label="">
        <TableHead>
          <TableRow sx={{ borderBottom: "1px solid #E9EDF7",}}>
           
            <TableCell align="left" sx={{color:"#485585"}}>Path</TableCell>
            <TableCell align="left" sx={{color:"#485585"}}>Snippet</TableCell>
           
          </TableRow>
        </TableHead>
        <TableBody>
          {rows3.map((row,index) => (
            <TableRow
              key={row.name}
              sx={{  paddingRight:"20px", borderBottom: "1px solid #E9EDF7"}}
            >
              
              <TableCell align="left" sx={{fontSize:"12px"}}><div className='impact_container3'><p className='impact'>{row.path}</p></div></TableCell>
              <TableCell align="left" sx={{color:"red",fontSize:"12px",marginRight:"16px",marginLeft:"16px"}}><div className='impact_container2'><p className='impact'>{row.snippet}</p></div></TableCell>
              
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
             </div>
             </div>

             <Dialog open={open.state} onClose={()=>{setOpen({state:false})}} style={{width:"100%"}} PaperProps={{
    style: { height:"580px", minWidth:"1010px",borderRadius: "20px",
            padding:"20px"} }}>
        <DialogTitle sx={{fontSize:24,color:"#2B3674",}}>Screenshot<button style={{marginLeft:"770px",background:"white",border:"none"}} aria-label="close "onClick={()=>{setOpen(false)}}><img src={close} alt="close"/></button></DialogTitle>
        <DialogContent>
           <img src={open.url} style={{height:"463px",width:"945px",border:"1px solid #E9EDF7",borderRadius:"16px"}} alt="Screenshot indication issue location" onClick={openUrl}/>
        </DialogContent>
      </Dialog>
             </div>

            
    )
}
