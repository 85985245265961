import React, { useContext, useEffect, useState } from "react";
import "./Metrics.css";
import Sidebar from "../Sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import { userStateValues } from "../../firebaseConfig";
import { AuthContext } from "../Context/AuthContext";
import { collection, getDocs } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig";
import {
  Button,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { async } from "@firebase/util";
import axios from "axios";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Line, Circle } from "rc-progress";
import { APIConst } from "../../API const/APIConst";

export default function Metrics() {
  const navigate = useNavigate();
  const { userState } = useContext(AuthContext);

  const goToLogin = () => navigate("/");

  useEffect(() => {
    if (userState == userStateValues.loggedOut) {
      goToLogin();
    }
  });
  const [projects, setProjects] = useState([]);
  const [projectSelected, setProjectSelected] = useState("");
  const [projectSelectedId, setProjectSelectedId] = useState("");

  const [passCount, setPassCount] = useState(0);
  const [failCount, setFailCount] = useState(0);
  const [warnCount, setWarnCount] = useState(0);
  const [pagesScanned, setPagesScanned] = useState(0);
  const [pagesWithleastonewaring, setpageswithleastonewarning] = useState(0);
  const [pagesWithleastonevio, setpageswithleastonevio] = useState(0);
  const [pagesWithleastonewaringorvio, setpageswithleastonewarningorvio] =
    useState(0);
  const [calculationStaus, setCalculationStatus] = useState("idle"); // "idle" || "calculating" || "done"
  let temp = 0;
  let failtemp = 0,
    wartemp = 0,
    tmpf = 0,
    tmppass = 0,
    tmpwar = 0;
  const [uid, setUid] = useState("");
  const [arr, setArr] = useState(null);

  useEffect(() => {
    const storedUid = localStorage.getItem("uid");
    setUid(storedUid);

    if (storedUid) {
      fetchProject(storedUid);
    }

    // Define the fetchProject function outside of the conditional check
    async function fetchProject(userId) {
      const payload = {
        userId, // The body of the POST request
      };

      try {
        const resp = await axios.post(
          APIConst.projectByUserId,
          JSON.stringify(payload),
          {
            headers: {
              "Content-Type": "application/json",
              "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8", // Add the x-api-key to the header
            },
            timeout: 70000, // Set the timeout to 70 seconds
          }
        );

        setProjects(resp.data.projects);
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      }
    }
  }, []);

  useEffect(() => {
    const storedUid = localStorage.getItem("uid");
    setUid(storedUid);
    if (projectSelected) {
      setCalculationStatus("calculating");

      // Array to keep track of all promises
      const promises = [];

      axios
        .get(
          `https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/fetchMatricsInfo/${projectSelected}`
        )
        .then((res) => {
          const siteNameArr = res.data.data;

          siteNameArr.forEach((data) => {
            promises.push(
              axios
                .post(
                  `https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/fetchMatricsInfo/${projectSelected}/sitnames`,
                  data,
                  { headers: { "Content-Type": "application/json" } }
                )
                .then((res) => {
                  const arrData = res.data.arr;
                  temp += arrData.length;
                  setPagesScanned(temp);

                  // Fetch additional data for each item in arrData
                  arrData.forEach((item) => {
                    promises.push(
                      axios
                        .post(
                          `https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/fetchMatricsInfo/${projectSelected}/sitnames/arr`,
                          item,
                          { headers: { "Content-Type": "application/json" } }
                        )
                        .then((res) => {
                          const finalObj = res.data.data;
                          setArr(finalObj);

                          // Update counts
                          tmpf += finalObj.failsCount;
                          tmppass += finalObj.passCount;
                          tmpwar += finalObj.warningCount;
                          setFailCount(tmpf);
                          setPassCount(tmppass);
                          setWarnCount(tmpwar);

                          if (finalObj.failsCount !== 0) {
                            failtemp += 1;
                            setpageswithleastonevio(failtemp);
                          }
                          if (finalObj.warningCount !== 0) {
                            wartemp += 1;
                            setpageswithleastonewarning(wartemp);
                          }
                        })
                        .catch((err) => console.error(err))
                    );
                  });
                })
                .catch((err) => console.error(err))
            );
          });

          // Wait for all promises to complete
          Promise.all(promises)
            .then(() => {
              setCalculationStatus("done");
            })
            .catch((err) => {
              console.error("Error during calculations:", err);
              setCalculationStatus("idle");
            });
        })
        .catch((err) => {
          console.error(err);
          setCalculationStatus("idle");
        });
    }
  }, [projectSelected]);

  const [myMap, setMyMap] = useState(new Map());
  const [myMapSev, setMyMapSev] = useState(new Map());
  const [countSev, setCountSev] = useState(0);
  const [countCat, setCountCat] = useState(0);

  useEffect(() => {
    let temp1 = 0,
      temp2 = 0;
    for (let [key, value] of myMap) {
      if (value) temp1 += value;
    }
    setCountCat(temp1);

    for (let [key, value] of myMapSev) {
      if (value) temp2 += value;
    }
    setCountSev(temp2);
  }, [myMap, myMapSev]);

  useEffect(() => {
    let tmpMap = new Map();
    let tempMapSev = new Map();
    if (arr) {
      for (let i = 0; i < arr.arr.length; i++) {
        if (tempMapSev.has(arr.arr[i].severityLevel)) {
          let val = tempMapSev.get(arr.arr[i].severityLevel);
          tempMapSev.set(arr.arr[i].severityLevel, val + 1);
        } else {
          tempMapSev.set(arr.arr[i].severityLevel, 1);
        }

        if (tmpMap.has(arr.arr[i].category)) {
          let val = tmpMap.get(arr.arr[i].category);
          tmpMap.set(arr.arr[i].category, val + 1);
        } else {
          tmpMap.set(arr.arr[i].category, 1);
        }
      }
    }
    setMyMap(tmpMap);
    setMyMapSev(tempMapSev);
  }, [arr]);

  const handelInput = (e) => {
    setProjectSelected(e.target.value);
    // Resetting the state variables
    setPassCount(0);
    setFailCount(0);
    setWarnCount(0);
    setPagesScanned(0);
    setpageswithleastonewarning(0);
    setpageswithleastonevio(0);
    setpageswithleastonewarningorvio(0);
    setArr(null);
    setMyMap(new Map());
    setMyMapSev(new Map());
    setCountSev(0);
    setCountCat(0);
  };
  // useEffect(() => {
  //   // console.log(myMap);
  //   // console.log(myMapSev);
  // }, [myMapSev]);
  return (
    userState && (
      <div>
        <Sidebar />
        <div>
          <div className="metrix-container">
            <div>
              <p
                style={{
                  fontSize: "24px",
                  fontFamily: "DM Sans",
                }}
              >
                Select Project for Metrics Data
              </p>
              <TextField
                select
                name="projectID"
                label="project"
                value={projectSelected}
                onChange={handelInput}
                sx={{
                  width: "100%",
                  mt: "1em",
                  color: "#11047A",
                  background: "#F4F7FE",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                <MenuItem value={""}></MenuItem>
                {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
                {projects.map((pro) => {
                  return (
                    <MenuItem key={pro.projId} value={pro.projId}>
                      {pro.projectName}
                    </MenuItem>
                  );
                })}
                {!projects.length && <MenuItem>No Projects</MenuItem>}
              </TextField>
            </div>
            <h1 className="metrix-heading">Metrics</h1>
            {!projectSelected && <p>Please Select a project to view the metrics</p>}

            {projectSelected &&  <>
              <p>
                Displaying combined metrics for pages available to your Team.
              </p>
              <div style={{ display: "flex", gap: "15px" }}>
                {/* <div>
            <select className="filters">
            <option value="1">Filter By Domain</option>
            <option value="2">Filter By Domain</option>
            <option value="3">Filter By Domain</option>
            <option value="4">Filter By Domain</option>
            </select>
          </div>
          <div>
            <select className="filters">
            <option value="1">Filter By Time</option>
            <option value="2">Filter By Time</option>
            <option value="3">Filter By Time</option>
            <option value="4">Filter By Time</option>
            </select>
          </div>
          <div>
            <select className="filters">
            <option value="1">Filter By Scan</option>
            <option value="2">Filter By Scan</option>
            <option value="3">Filter By Scan</option>
            <option value="4">Filter By Scan</option>
            </select>
          </div> */}
              </div>
              <h3 className="headings">Pages</h3>
              <div
                style={{
                  height: "auto",
                  padding: "10px",
                  background: "white",
                  borderRadius: "20px",
                  width: "1100px",
                }}
              >
                <div>
                  <div>
                    <div
                      style={{
                        margin: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <div
                          style={{
                            fontSize: "14px",
                            color: "#47548C",
                            fontFamily: "DM Sans",
                          }}
                        >
                          Pages Scanned
                        </div>
                        <div
                          style={{
                            color: "#1B2559",
                            fontSize: "24px",
                            fontFamily: "DM Sans",
                          }}
                        >
                          {pagesScanned}
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {calculationStaus !== "idle" && (
                          <p
                            style={{
                              backgroundColor:
                                calculationStaus === "calculating"
                                  ? "#a86232"
                                  : "#3283a8",
                              color: "#fff",
                              fontSize: "24px",
                              fontFamily: "DM Sans",
                              // padding: "10px 20px",
                              width: "150px",
                              height: "40px",
                              // length : "30px",
                              borderRadius: "3px",
                              display: "inline-block", // Makes it appear as a tag
                              textAlign: "center",
                              alignItems: "center",
                            }}
                          >
                            {calculationStaus}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ margin: "10px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#47548C",
                          fontFamily: "DM Sans",
                        }}
                      >
                        Pages with at least one violation
                      </div>
                      <div
                        style={{
                          color: "#1B2559",
                          fontSize: "24px",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {pagesWithleastonevio}
                      </div>
                    </div>
                    <div style={{ margin: "10px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#47548C",
                          fontFamily: "DM Sans",
                        }}
                      >
                        Pages with at least one warning
                      </div>
                      <div
                        style={{
                          color: "#1B2559",
                          fontSize: "24px",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {pagesWithleastonewaring}
                      </div>
                    </div>
                    <div style={{ margin: "10px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#47548C",
                          fontFamily: "DM Sans",
                        }}
                      >
                        Pages with at least one warning or violation
                      </div>
                      <div
                        style={{
                          color: "#1B2559",
                          fontSize: "24px",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {Math.max(
                          pagesWithleastonevio,
                          pagesWithleastonewaring
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="countDetails">
                <div className="details798"></div>
              </div> */}
              </div>
              {/* <div className="hrLine"></div> */}
              <br />
              <section>
                <h3 className="headings" style={{ width: "300px" }}>
                  Elements &#38; Issues
                </h3>
                <div
                  style={{
                    height: "auto",
                    padding: "10px",
                    background: "white",
                    borderRadius: "20px",
                    width: "1100px",
                  }}
                >
                  <div>
                    <div
                      style={{ width: 200, height: 200, margin: "auto auto" }}
                    >
                      <CircularProgressbar
                        value={
                          ((passCount + warnCount) /
                            (failCount + warnCount + passCount)) *
                          100
                        }
                        text={`${parseInt(
                          ((passCount + warnCount) /
                            (failCount + warnCount + passCount)) *
                            100
                        )}%`}
                      />
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          fontSize: "14px",
                          color: "#47548C",
                          fontFamily: "DM Sans",
                        }}
                      >
                        Elements have no issues
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <div style={{ margin: "10px", width: "300px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#47548C",
                          fontFamily: "DM Sans",
                          textAlign: "center",
                        }}
                      >
                        Elements scanned
                      </div>
                      <div
                        style={{
                          color: "#1B2559",
                          fontSize: "24px",
                          textAlign: "center",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {failCount + warnCount + passCount}
                      </div>
                    </div>
                    <div style={{ margin: "10px", width: "300px" }}>
                      <div
                        style={{
                          fontSize: "14px",
                          color: "#47548C",
                          fontFamily: "DM Sans",
                          textAlign: "center",
                        }}
                      >
                        violation found
                      </div>
                      <div
                        style={{
                          color: "#1B2559",
                          fontSize: "24px",
                          textAlign: "center",
                          fontFamily: "DM Sans",
                        }}
                      >
                        {failCount}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div style={{ width: "1100px" }}>
                <div className="headings" style={{ width: "400px" }}>
                  Violations analytics
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      padding: "10px",
                      background: "white",
                      borderRadius: "20px",
                      width: "500px",
                    }}
                  >
                    <div
                      style={{
                        margin: "auto auto",
                        color: "#1B2559",
                        fontWeight: "700",
                        padding: "10px",
                        fontFamily: "DM Sans",
                      }}
                    >
                      <div style={{ color: "#1B2559" }}>
                        No of violations by issue severity
                      </div>
                      <div style={{ margin: "20px 0 ", fontSize: "24px" }}>
                        {countSev}
                      </div>
                      <div>
                        {myMapSev &&
                          [...myMapSev.keys()].map((k) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{ fontSize: "14px", color: "#47548C" }}
                                key={k}
                              >
                                {k}
                              </div>
                              <div
                                style={{
                                  width: "250px",
                                  display: "flex",
                                  margin: "20px 0",
                                }}
                              >
                                <Line
                                  style={{ height: "10px" }}
                                  percent={myMapSev.get(k) % 100}
                                  strokeWidth={4}
                                  strokeColor="#3e98c7"
                                />
                                {/* <div>{myMap.get(k) % 100}</div> */}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      height: "auto",
                      padding: "10px",
                      background: "white",
                      borderRadius: "20px",
                      width: "500px",
                    }}
                  >
                    <div
                      style={{
                        margin: "auto auto",
                        color: "#1B2559",
                        fontWeight: "700",
                        padding: "10px",
                        fontFamily: "DM Sans",
                      }}
                    >
                      <div style={{ color: "#1B2559" }}>
                        No of violations by issue category
                      </div>
                      <div style={{ margin: "20px 0 ", fontSize: "24px" }}>
                        {countCat}
                      </div>
                      <div>
                        {myMap &&
                          [...myMap.keys()].map((k) => (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{ fontSize: "14px", color: "#47548C" }}
                                key={k}
                              >
                                {k}
                              </div>
                              <div
                                style={{
                                  width: "250px",
                                  display: "flex",
                                  margin: "20px 0",
                                }}
                              >
                                <Line
                                  style={{ height: "10px" }}
                                  percent={myMap.get(k) % 100}
                                  strokeWidth={4}
                                  strokeColor="#3e98c7"
                                />
                                {/* <div>{myMap.get(k) % 100}</div> */}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>}

            {/* Temporal Trend Section */}
            {/* <div style={{ width: "1100px" }}>
            <div className="headings" style={{ width: "400px" }}>
              temporal trends
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  height: "400px",
                  padding: "10px",
                  background: "white",
                  borderRadius: "20px",
                  width: "1100px",
                }}
              >
                <div style={{ width: "300px", margin: "auto auto" }}>
                  No of violations by issue severity
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </div>
      </div>
    )
  );
}
