import { Checkbox, Radio, RadioGroup, Select, MenuItem } from "@mui/material";
import React, { useState, useContext } from "react";
import "./Input.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { setWith } from "lodash";
import { db, auth, functions } from "../../../firebaseConfig";
import TextField from "@mui/material/TextField";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useEffect } from "react";
import { APIConst } from "../../../API const/APIConst";
import { AuthContext } from "../../Context/AuthContext";
import Projects from "./Projects";

export default function Input() {
  const [buttonText, setButtonText] = useState("Create new Accessibility Test"); 

  const [error,setError] = useState('');
  const [frequency, setFrequency] = useState(false);
  const changeText = (text) => setButtonText(text);

  const data = [
    { Checkpoint: "1.1.1 Non-text Content", id: "1.1.1" },
    {
      Checkpoint: "1.2.1 Audio-only and Video-only (Prerecorded)",
      id: "1.2.1",
    },
    { Checkpoint: "1.2.2 Captions (Prerecorded)", id: "1.2.2" },
    {
      Checkpoint: "1.2.3 Audio Description or Media Alternative (Prerecorded)",
      id: "1.2.3",
    },
    { Checkpoint: "1.2.4 Captions (Live)", id: "1.2.4" },
    { Checkpoint: "1.2.5 Audio Description (Prerecorded) ", id: "1.2.5" },
    {
      Checkpoint: "1.2.7 Extended Audio Description (Prerecorded)",
      id: "1.2.7",
    },
    { Checkpoint: "1.2.8 Media Alternative (Prerecorded)", id: "1.2.8" },
    { Checkpoint: "1.2.9 Audio-only (Live)", id: "1.2.9" },
    { Checkpoint: "1.3.1 Info and Relationships", id: "1.3.1" },
    { Checkpoint: "1.3.2 Meaningful Sequence", id: "1.3.2" },
    { Checkpoint: "1.3.3 Sensory Characteristics", id: "1.3.3" },
    { Checkpoint: "1.3.4 Orientation", id: "1.3.4" },
    { Checkpoint: "1.3.5 Identify Input Purpose", id: "1.3.5" },
    { Checkpoint: "1.3.6 Identify Purpose", id: "1.3.6" },
    { Checkpoint: "1.4.1 Use of Color", id: "1.4.1" },
    { Checkpoint: "1.4.2 Audio Control", id: "1.4.2" },
    { Checkpoint: "1.4.3 Contrast (Minimum)", id: "1.4.3" },
    { Checkpoint: "1.4.4 Resize text", id: "1.4.4" },
    { Checkpoint: "1.4.5 Images of Text", id: "1.4.5" },
    { Checkpoint: "1.4.6 Contrast (Enhanced)", id: "1.4.6" },
    { Checkpoint: "1.4.7 Low or No Background Audio", id: "1.4.7" },
    { Checkpoint: "1.4.8 Visual Presentation", id: "1.4.8" },
    { Checkpoint: "1.4.9 Images of Text (No Exception)", id: "1.4.9" },
    { Checkpoint: "1.4.10 Reflow", id: "1.4.10" },
    { Checkpoint: "1.4.11 Non-text Contrast", id: "1.4.11" },
    { Checkpoint: "1.4.12 Text Spacing", id: "1.4.12" },
    { Checkpoint: "1.4.13 Content on Hover or Focus", id: "1.4.13" },
    { Checkpoint: "2.1.1 Keyboard", id: "2.1.1" },
    { Checkpoint: "2.1.2 No Keyboard Trap", id: "2.1.2" },
    { Checkpoint: "2.1.3 Keyboard (No Exception)", id: "2.1.3" },
    { Checkpoint: "2.1.4 Character Key Shortcuts", id: "2.1.4" },
    { Checkpoint: "2.2.1 Timing Adjustable", id: "2.2.1" },
    { Checkpoint: "2.2.2 Pause, Stop, Hide", id: "2.2.2" },
    { Checkpoint: "2.2.3 No Timing", id: "2.2.3" },
    { Checkpoint: "2.2.4 Interruptions", id: "2.2.4" },
    { Checkpoint: "2.2.5 Re-authenticating", id: "2.2.5" },
    { Checkpoint: "2.2.6 Timeouts", id: "2.2.6" },
    { Checkpoint: "2.3.1 Three Flashes or Below Threshold", id: "2.3.1" },
    { Checkpoint: "2.3.2 Three Flashes", id: "2.3.2" },
    { Checkpoint: "2.3.3 Animation from Interactions", id: "2.3.3" },
    { Checkpoint: "2.4.1 Bypass Blocks", id: "2.4.1" },
    { Checkpoint: "2.4.2 Page Titled", id: "2.4.2" },
    { Checkpoint: "2.4.3 Focus Order", id: "2.4.3" },
    { Checkpoint: "2.4.4 Link Purpose (In Context)", id: "2.4.4" },
    { Checkpoint: "2.4.5 Multiple Ways", id: "2.4.5" },
    { Checkpoint: "2.4.6 Headings and Labels", id: "2.4.6" },
    { Checkpoint: "2.4.7 Focus Visible", id: "2.4.7" },
    { Checkpoint: "2.4.8 Location", id: "2.4.8" },
    { Checkpoint: "2.4.9 Link Purpose (Link Only)", id: "2.4.9" },
    { Checkpoint: "2.4.10 Section Headings", id: "2.4.10" },
    { Checkpoint: "2.5.1 Pointer Gestures", id: "2.5.1" },
    { Checkpoint: "2.5.2 Pointer Cancellation", id: "2.5.2" },
    { Checkpoint: "2.5.3 Label in Name", id: "2.5.3" },
    { Checkpoint: "2.5.4 Motion Actuation", id: "2.5.4" },
    { Checkpoint: "2.5.5 Target Size", id: "2.5.5" },
    { Checkpoint: "2.5.6 Concurrent Input Mechanisms", id: "2.5.6" },
    { Checkpoint: "3.1.1 Language of Page", id: "3.1.1" },
    { Checkpoint: "3.1.2 Language of Parts", id: "3.1.2" },
    { Checkpoint: "3.1.3 Unusual Words", id: "3.1.3" },
    { Checkpoint: "3.1.4 Abbreviations", id: "3.1.4" },
    { Checkpoint: "3.1.5 Reading Level", id: "3.1.5" },
    { Checkpoint: "3.1.6 Pronunciation", id: "3.1.6" },
    { Checkpoint: "3.2.1 On Focus", id: "3.2.1" },
    { Checkpoint: "3.2.2 On Input", id: "3.2.2" },
    { Checkpoint: "3.2.3 Consistent Navigation", id: "3.2.3" },
    { Checkpoint: "3.2.4 Consistent Identification", id: "3.2.4" },
    { Checkpoint: "3.2.5 Change on Request", id: "3.2.5" },
    { Checkpoint: "3.3.1 Error Identification", id: "3.3.1" },
    { Checkpoint: "3.3.2 Labels or Instructions", id: "3.3.2" },
    { Checkpoint: "3.3.3 Error Suggestion", id: "3.3.3" },
    {
      Checkpoint: "3.3.4 Error Prevention (Legal, Financial, Data)",
      id: "3.3.4",
    },
    { Checkpoint: "3.3.5 Help", id: "3.3.5" },
    { Checkpoint: "3.3.6 Error Prevention (All)", id: "3.3.6" },
    { Checkpoint: "4.1.1 Parsing", id: "4.1.1" },
    { Checkpoint: "4.1.2 Name, Role, Value", id: "4.1.2" },
    { Checkpoint: "4.1.3 Status Messages", id: "4.1.3" },
  ];

  const [options] = useState(data);
  const [projects,setProjects] = useState([]);
  const [newProjectId,setNewProjectId] = useState('');
  // this.state = { buttonText: "Initial Text" };

  let checkList1 = ["AAA", "AA", "A"];
  let checkList2 = ["WCAG_2_1", "WCAG_2_0"];

  const [open, setOpen] = useState(false);
  const { setClicked } = useContext(AuthContext);
  const [schedule, setSchedule] = useState({
    url: "",
    mode: "single-page",
    standard: checkList2,
    compilanceLevel: checkList1,
    includeWarnings: true,
    includeBestPractices: false,
    includeDocuments: false,
    email: auth.currentUser?.email,
    userID: auth.currentUser?.uid,
    project: null,
    frequency: "",
    projectID: "",
    policy: checkList2,
    source: "portal",
    framework: ""
  });

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClicked(false);
  };

  const handelInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    console.log(name, value);

    
    if (name === 'projectID') {
      const selectedProject = projects.find(project => project.projId === value);
      if (selectedProject) {
        setSchedule({
          ...schedule, 
          framework: selectedProject.projectStack || '',  // Set framework based on selected project
          projectID: value
        });
      }
    } else {
      setSchedule({ ...schedule, [name]: value });
    }
    
    console.log(schedule);
    console.log(name,value);
  };

  const handelCheck = (e) => {
    if (schedule.includeWarnings === true) {
      setSchedule({ ...schedule, includeWarnings: false });
      // console.log(schedule.includeWarnings);
    } else if (schedule.name === false) {
      setSchedule({ ...schedule, includeWarnings: true });
      // console.log(schedule.includeWarnings);
    }
  };

  const [checked, setChecked] = useState(["AAA", "AA", "A"]);
  const handelCheck2 = (e) => {
    if (schedule.includeBestPractices === true) {
      setSchedule({ ...schedule, includeBestPractices: false });
      // console.log(schedule.includeBestPractices);
    } else if (schedule.includeBestPractices === false) {
      setSchedule({ ...schedule, includeBestPractices: true });
      // console.log(schedule.includeBestPractices);
    }
  };

  const handelDoc = (e) => {
    if (schedule.includeDocuments === true) {
      setSchedule({ ...schedule, includeDocuments: false });
      // console.log(schedule.includeDocuments);
    } else if (schedule.includeDocuments === false) {
      setSchedule({ ...schedule, includeDocuments: true });
      // console.log(schedule.includeDocuments);
    }
  };
  const navigate = useNavigate();
  const handleList2 = (event) => {
    const check = event.target.checked;
    const value = event.target.value;
    // console.log(value);
    // console.log(check);
    // console.log(checked);

    if (!check) {
      checkList2 = checkList2.filter((val) => {
        if (val != value) {
          return val;
        }
      });
      setSchedule({ ...schedule, standard: checkList2 });
      // console.log(schedule);
      // console.log(checkList2);
    } else {
      checkList2.push(value);
      setSchedule({ ...schedule, standard: checkList2 });
      // console.log(schedule);
    }
  };
  const handleFreq = (event) => {
    if (event.target.value == 1) {
      setSchedule({ ...schedule, frequency: "" });
      setFrequency(false);
    } else {
      setFrequency(true);
    }
  };
  const handleList = (event) => {
    const check = event.target.checked;
    const value = event.target.value;
    // console.log(value);
    // console.log(check);
    // console.log(checked);

    if (!check) {
      checkList1 = checkList1.filter((val) => {
        if (val != value) {
          return val;
        }
      });
      setSchedule({ ...schedule, compilanceLevel: checkList1 });
      // console.log(schedule);
      // console.log(checkList1);
    } else {
      checkList1.push(value);
      setSchedule({ ...schedule, compilanceLevel: checkList1 });
      // console.log(schedule);
    }
  };

  const handelSubmit = async (e) => {
    if (schedule) {
      if (
        !(
          schedule.url.startsWith("http://") ||
          schedule.url.startsWith("https://") 
        )
      ) {
        // alert("Please start the url with http:// or https://");
        console.log("Please start the url with http:// or https://");
        setError("Please start the url with http:// or https://");
        return;
      }
      if(schedule.projectID === '') {
        // alert("Please select a project to schedule the scan");
        setError("Please select a project to schedule the scan");
        return;
      }
    }
    if (schedule.url.endsWith("/")) {
      schedule.url = schedule.url.slice(0, schedule.url.lastIndexOf("/"));
    }
    // console.log("on handleSubmit");
    // const name = e.target.name;
    const docRef = doc(db, "configs", "cloudUrls");
    // console.log("after docRef: ", docRef);
    const cloudUrls = await getDoc(docRef);
    // console.log("after cloudUrls");
    //const scheduleV2 = (await cloudUrls).data().scheduleV2;
    const testScheduleV2 = cloudUrls.data().testScheduleV2;
    // console.log("scheduleV2: ", scheduleV2);
    e.preventDefault();
    changeText("Processing...");

    const newRecord = { ...schedule };
    console.log(newRecord);
    axios
      .post(testScheduleV2, newRecord,{
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8", // Add the x-api-key to the header
        },
        timeout: 70000, // Set the timeout to 70 seconds
      })
      .then(() => {
        navigate("/reports");
      })
      .catch((error) => {
        // window.alert(error);
        console.log(error);
        changeText("Create a new Accessibility Test");
      });

    //     const axios = require('axios');
    // let data = JSON.stringify({
    //   "url": "https://www.inclusivestem.org/",
    //   "mode": "single-page",
    //   "policy": "WCAG_2_1",
    //   "includeBestPractices": true,
    //   "includeWarnings": false,
    //   "includeDocuments": false,
    //   "standard": "WCAG_2_1",
    //   "compilanceLevel": "AAA",
    //   "email": "kartikeydixit1401@gmail.com",
    //   "frequency": 1,
    //   "source": "extension",
    //   "projectID": "kdTestProjId"
    // });

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testScheduleV2',
    //   headers: {
    //     'x-api-key': 'd1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8',
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };

    // axios.request(config)
    // .then((response) => {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  };

  // const handleTest =async (e) => {
  //   e.preventDefault()
  //   // console.log("handleTest");
  //   // const listingQueues = httpsCallable(functions,"listingQueues")
  //   // console.log("Calling Listing")
  //   // await listingQueues();
  //   // console.log("Called Listing")
  //   // const colRef = collection(db,"Users")
  //   const docQuery = query(collection(db,"Users"),where("email","==","agrawalpratham2002@gmail.com"))
  //   let snap  = await getDocs(docQuery)
  //   console.log("snap",snap.docId);

  // };

  const fetchProjects = () => {
    const payload = {
      userId: auth?.currentUser?.uid, // The body of the POST request
    };

    axios
      .post(APIConst.projectByUserId, JSON.stringify(payload), {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8", // Add the x-api-key to the header
        },
        timeout: 70000, // Set the timeout to 70 seconds
      })
      .then(async (response) => {
        console.log("fetched projects", response);
        await setProjects(response.data.projects);
        console.log("projects", projects);
        console.log(projects.length);
      })
      .catch((err) => {
        // window.alert(err);
        console.log(err);
      });
  };
  //test purpose

  useEffect(() => {
    if(auth.currentUser) {
      fetchProjects();
    }
  }, []);

  useEffect(()=> {
    console.log("new project id",newProjectId);
    if(newProjectId) {
      schedule.projectID = newProjectId;
    }
  },[newProjectId, projects, schedule]);

  return (
    <>
      {/* <button onClick={handleTest}>List Queues</button> */}
      <div className="accessibility_form">
        {/* <form className='accessibility_form'> */}
        <div>{error}</div>
        <text className="form_heading">Schedule a new test</text>
        <div>
          <div className="question">
            <text>Webpage*</text>
          </div>
          <input
            type="text"
            className="input"
            placeholder="  website URL of the form http or https://www"
            value={schedule.url}
            onChange={handelInput}
            name="url"
            required={true}
          />
          <fieldset
            style={{
              borderColor: "white",
              border: "none",
              color: "#2B3674",
              fontSize: "16px",
              fontStyle: "normal",
              marginTop: "26px",
            }}
          >
            <legend>Single page or crawl all internal pages</legend>
            <RadioGroup defaultValue={"single-page"}>
              <div className="form-check">
                <label className="form-check-label" aria-label="single page">
                  <Radio
                    sx={{
                      height: "24px",
                      width: "24px",
                      color: "#E0E5F2",
                      marginRight: "12px",
                    }}
                    name="mode"
                    value="single-page"
                    onChange={handelInput}
                    defaultChecked={true}
                    label=""
                  />
                  Single web page
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label" aria-label="Crawl">
                  <Radio
                    sx={{
                      height: "24px",
                      width: "24px",
                      color: "#E0E5F2",
                      marginRight: "12px",
                    }}
                    name="mode"
                    value="crawl"
                    onChange={handelInput}
                  />
                  Crawl all internal pages
                </label>
              </div>
            </RadioGroup>
          </fieldset>

          <fieldset
            style={{
              borderColor: "white",
              border: "none",
              color: "#2B3674",
              fontSize: "16px",
              fontStyle: "normal",
              marginTop: "26px",
            }}
          >
            <legend>Accessibility Conformance standard</legend>

            <div className="form-check">
              <label className="form-check-label" aria-label="WCAG 2.0">
                {/* <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px"

             }} name='compilanceLevel' value="AAA" onChange={handelInput}/>AAA */}
                <Checkbox
                  sx={{
                    height: "24px",
                    width: "24px",
                    border: "1px solid #E0E5F2",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    borderColor: "#E0E5F2",
                    color: "#ffffff",
                    marginRight: "12px",
                  }}
                  onChange={handleList2}
                  value="WCAG_2_0"
                  defaultChecked
                />
                WCAG 2.0
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label" aria-label="WCAG 2.1">
                {/* <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px"

             }} name='compilanceLevel' value="AAA" onChange={handelInput}/>AAA */}
                <Checkbox
                  sx={{
                    height: "24px",
                    width: "24px",
                    border: "1px solid #E0E5F2",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    borderColor: "#E0E5F2",
                    color: "#ffffff",
                    marginRight: "12px",
                  }}
                  onChange={handleList2}
                  value="WCAG_2_1"
                  defaultChecked
                />
                WCAG 2.1
              </label>
            </div>
          </fieldset>
          <fieldset
            style={{
              borderColor: "white",
              border: "none",
              color: "#2B3674",
              fontSize: "16px",
              fontStyle: "normal",
              marginTop: "26px",
            }}
          >
            <legend>Accessibility Conformance Level to test against</legend>

            <div className="form-check">
              <label className="form-check-label" aria-label="A">
                {/* <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px"

             }} name='compilanceLevel' value="AAA" onChange={handelInput}/>AAA */}
                <Checkbox
                  sx={{
                    height: "24px",
                    width: "24px",
                    border: "1px solid #E0E5F2",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    borderColor: "#E0E5F2",
                    color: "#ffffff",
                    marginRight: "12px",
                  }}
                  onChange={handleList}
                  value="A"
                  defaultChecked
                />
                A
              </label>
            </div>

            <div className="form-check">
              <label className="form-check-label" aria-label="AA">
                {/* <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px"

             }} name='compilanceLevel' value="AAA" onChange={handelInput}/>AAA */}
                <Checkbox
                  sx={{
                    height: "24px",
                    width: "24px",
                    border: "1px solid #E0E5F2",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    borderColor: "#E0E5F2",
                    color: "#ffffff",
                    marginRight: "12px",
                  }}
                  onChange={handleList}
                  value="AA"
                  defaultChecked={true}
                />
                AA
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label" aria-label="AAA">
                {/* <Radio sx={{
               height:"24px",
               width:"24px",
               color:"#E0E5F2",
               marginRight:"12px"

             }} name='compilanceLevel' value="AAA" onChange={handelInput}/>AAA */}
                <Checkbox
                  sx={{
                    height: "24px",
                    width: "24px",
                    border: "1px solid #E0E5F2",
                    boxSizing: "border-box",
                    borderRadius: "6px",
                    borderColor: "#E0E5F2",
                    color: "#ffffff",
                    marginRight: "12px",
                  }}
                  onChange={handleList}
                  value="AAA"
                  defaultChecked
                />
                AAA
              </label>
            </div>
          </fieldset>
          <br />
        </div>
        <div className="secondCol">
          <div className="question">
            <text>One time or reccuring process</text>
          </div>
          <RadioGroup defaultValue={1}>
            <div className="form-check">
              <label className="form-check-label" aria-label="One Time">
                <Radio
                  sx={{
                    height: "24px",
                    width: "24px",
                    color: "#E0E5F2",
                    marginRight: "12px",
                  }}
                  name="reccuring"
                  value={1}
                  onChange={handleFreq}
                />
                One-time
              </label>
            </div>
            <div className="form-check">
              <label className="form-check-label">
                <Radio
                  sx={{
                    height: "24px",
                    width: "24px",
                    color: "#E0E5F2",
                    marginRight: "12px",
                  }}
                  name="reccuring"
                  value={0}
                  onChange={handleFreq}
                />
                Reccuring
              </label>
            </div>
          </RadioGroup>

          {frequency && (
            <>
              <div className="question">
                <text>Recurring frequencies</text>
              </div>
              <RadioGroup
                sx={{
                  borderRadius: "8px",
                }}
              >
                <div className="form-check">
                  <label className="form-check-label" aria-label="Every Day">
                    <Radio
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "#E0E5F2",
                        marginRight: "12px",
                      }}
                      name="frequency"
                      value={0}
                      onChange={handelInput}
                    />
                    Every Day
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label" aria-label="Every Week">
                    <Radio
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "#E0E5F2",
                        marginRight: "12px",
                      }}
                      name="frequency"
                      value={1}
                      onChange={handelInput}
                    />
                    Every Week
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label" aria-label="Every Month">
                    <Radio
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "#E0E5F2",
                        marginRight: "12px",
                      }}
                      name="frequency"
                      value={2}
                      onChange={handelInput}
                    />
                    Every Month
                  </label>
                </div>
                <div className="form-check">
                  <label className="form-check-label" aria-label="Every Year">
                    <Radio
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "#E0E5F2",
                        marginRight: "12px",
                      }}
                      name="frequency"
                      value={3}
                      onChange={handelInput}
                    />
                    Every Year
                  </label>
                </div>
              </RadioGroup>
            </>
          )}

          <div className="question">
            <text>Select project*</text>
          </div>
          {/* <input
            type="text"
            className="input"
            placeholder="  select a project to schedule the test"
            value={schedule.url}
            onChange={handelInput}
            name="url"
            required={true}
          /> */}

          <TextField
            select
            name="projectID"
            label="project"
            value={schedule.projectID}
            onChange={handelInput}
            sx={{
              width: "100%",
              mt: "1em",
              color: "#11047A",
              background: "#F4F7FE",
              maxHeight: "200px",
              overflowY: "auto"
            }}
          >
            <MenuItem value={""}></MenuItem>
            {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
            {projects.map((pro) => {
              return (
                <MenuItem key={pro.projId} value={pro.projId}>
                  {pro.projectName}
                </MenuItem>
              );
            })}
            {!projects.length && <MenuItem>No Projects</MenuItem>}
          </TextField>
          <text style={{fontSize: '0.8rem', cursor: 'pointer'}} onClick={handleClickOpen} role="button">Create New Project</text>
          {open && (
            <Projects
              open={open}
              onClose={handleClose}
              userId={auth.currentUser.uid}
              fetchProjects={fetchProjects}
              db={db}
              setNewProjectId={setNewProjectId}
              to={"create"}
            />
          )}
          <div className="question">
            <h2 style={{ fontSize: "22px" }}>
              <text aria-label="Additional Settings">Additional Settings</text>
            </h2>
          </div>

          <div className="form-check">
            <Checkbox
              sx={{
                height: "24px",
                width: "24px",
                border: "1px solid #E0E5F2",
                boxSizing: "border-box",
                borderRadius: "6px",
                borderColor: "#E0E5F2",
                color: "#ffffff",
                marginRight: "12px",
              }}
              name="includeWarnings"
              onChange={handelCheck}
              defaultChecked
              defaultValue={true}
            />
            <label
              className="form-check-label"
              htmlFor="flexCheckDefault"
              aria-label="Include Warnings"
            >
              Include Warnings
            </label>
          </div>

          <div className="form-check">
            <Checkbox
              sx={{
                height: "24px",
                width: "24px",
                border: "1px solid #E0E5F2",
                boxSizing: "border-box",
                borderRadius: "6px",
                borderColor: "#E0E5F2",
                color: "#ffffff",
                marginRight: "12px",
              }}
              onChange={handelCheck2}
            />
            <label
              className="form-check-label"
              htmlFor="flexCheckDefault"
              aria-label=" Include Best Practices"
            >
              Include Best Practices
            </label>
          </div>

          <div className="form-check">
            <Checkbox
              sx={{
                height: "24px",
                width: "24px",
                border: "1px solid #E0E5F2",
                boxSizing: "border-box",
                borderRadius: "6px",
                borderColor: "#E0E5F2",
                color: "#ffffff",
                marginRight: "12px",
              }}
              onChange={handelDoc}
            />
            <label
              className="form-check-label"
              htmlFor="flexCheckDefault"
              aria-label=" Include Documents"
            >
              Scan Documents
            </label>
          </div>
          {/* <div className='question'><text>Email</text></div>
          <input type="text" className='input' placeholder="Email" value={schedule.email}
            onChange={handelInput} name='email' /> */}
            <div className="question">
            <h2 style={{ fontSize: "22px" }}>
              <text aria-label="Additional Settings">FrameWork</text>
            </h2>
          </div>

          <RadioGroup
  value={schedule.framework}
  name="framework" // Add name here to make sure you're handling the input correctly
  onChange={handelInput} // The change will trigger here, not on individual Radio buttons
>
  <div className="form-check">
    <label className="form-check-label" aria-label="React">
      <Radio
        sx={{
          height: "24px",
          width: "24px",
          color: "#E0E5F2",
          marginRight: "12px",
        }}
        value={'react'} // Set value for React
      />
      React
    </label>
  </div>
  <div className="form-check">
    <label className="form-check-label">
      <Radio
        sx={{
          height: "24px",
          width: "24px",
          color: "#E0E5F2",
          marginRight: "12px",
        }}
        value={'next'} // Set value for Next.js
      />
      Next.Js
    </label>
  </div>
  <div className="form-check">
    <label className="form-check-label">
      <Radio
        sx={{
          height: "24px",
          width: "24px",
          color: "#E0E5F2",
          marginRight: "12px",
        }}
        value={'vue'} // Set value for Vue.js
      />
      Vue
    </label>
  </div>
  <div className="form-check">
    <label className="form-check-label">
      <Radio
        sx={{
          height: "24px",
          width: "24px",
          color: "#E0E5F2",
          marginRight: "12px",
        }}
        value={'angular'} // Set value for Angular
      />
      Angular
    </label>
  </div>
</RadioGroup>

          <button
            className="submit"
            aria-label="Submit"
            onClick={handelSubmit}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
}
