/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect } from "react";
import "./schedule.css";
import Illustration from "./Elements/Illustration";
import { useNavigate } from "react-router-dom";
import Input from "./Elements/Input";
import Sidebar from "../Sidebar/Sidebar";
import { width } from "@mui/system";
import { userStateValues } from "../../firebaseConfig";
import { AuthContext } from "../Context/AuthContext";
import { PmContext } from "../Context/PmContext";
import { fetchRefreshTokenAndCloudIdFromFirestore, SetPmTool } from "../../Utils/JiraFunctions";
import { enableNetwork } from "firebase/firestore";
import { set } from "lodash";

export default function Schedule() {
  const navigate = useNavigate();
  const {userState} = useContext(AuthContext)
  const {enableTool,setEnableTool} = useContext(PmContext);
  const goToLogin = () => navigate("/");

  const setPmTool = async () => {
    const res = await fetchRefreshTokenAndCloudIdFromFirestore(userState.uid);
    console.log("tokens and cloud if at schedule",res);
    if(res!=null && res!== undefined) {
      setEnableTool({
        ...enableTool,
        jira: true
      });
    } else {
      setEnableTool({
        ...enableTool,
        jira: false
      });
    }
  }


  useEffect(() => {
    if (userState.set && userState.uid === "") {
      goToLogin();
    }
    if(userState.role==="Owner"){
      navigate("/requests")
    }
    SetPmTool(userState.uid,setEnableTool,enableTool);
  },[]);

  return (
    userState.uid != "" && (
      <>
        <Sidebar/>
        <main>
          <div className="schedule">
            <div className="top-item">
              <h1 className="heading">Schedule</h1>
            </div>
            <div className="inline">
              <Input />
              <Illustration />
            </div>
          </div>
        </main>
      </>
    )
  );
}
