import React, { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { functions } from "../../firebaseConfig";

const RequestCard = (props) => {
  const { data } = props;
  // svR6ImZ0IHvqz1zsHc7B
  const [clicked, setClicked] = useState("");
  const updateRequest = httpsCallable(functions, "updateRequest");
  const handleClick = async (action) => {
    setClicked("Processing");
    const res = await updateRequest({
      requestId: data.requestId,
      action: action,
    });
    // console.log(res);
    if (res.code === "internal_error") {
      setClicked("Error");
      return;
    }
    if (action === "approve") {
      setClicked("Approved");
    } else {
      setClicked("Rejected");
    }
  };
  return (
    <div className="request-card">
      <div className="company-info">
        <p>Organisation:</p>
        {data.orgName}
        <br />
        <p>Industry:</p>
        {data.industry}
        <br />
        <p>Organisation Size: </p>
        {data.orgSize}
        <br />
        <p>Contact Person:</p>
        {data.adminName}
        <br />
        <p>Email:</p>
        {data.adminEmail}
        <br />
        <p>Phone: </p>
        {data.phoneNo}
        <br />
      </div>
      {clicked == "" ? (
        <div className="button-container">
          <button
          aria-label="approve"
          onClick={() => handleClick("approve")}
          className="button-approve"
          >
            Approve
          </button>
          <button
            aria-label="reject"
            onClick={() => handleClick("reject")}
            className="button-reject"
          >
            Reject
          </button>
        </div>
      ) : (
        <div className={clicked}>
          <p>{clicked}</p>
        </div>
      )}

      <br />
    </div>
  );
};

export default RequestCard;
