import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import ApproveCard from "./ApproveCard";
import "./request.css";
import { auth, db } from "../../firebaseConfig";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/AuthContext";

const Approved = () => {
  /**
   * {
   *  requestId:"",
   *  action:"approve"|"reject"
   *  uid:""
   * }
   *
   */
    const {userState} = useContext(AuthContext)
    const navigate = useNavigate();
    const [timeStamp,setTimeStamp] = useState(Date.now())

    // console.log(timeStamp);
  const goToLogin = () => navigate("/");
  const [requests, setRequests] = useState([]);
  // const [orgId, setOrgId] = useState([]);
  const fetchRequests = async () => {
    const colRef = collection(db, "Organisations");
    // const queryRef = query(colRef);
    const snap = await getDocs(colRef);
    // console.log("userState",colRef);
    setRequests([]); // empty the stare before inserting
    // setOrgId([]); // empty the stare before inserting
    for (let i = 0; i < snap.docs.length; i++) {

      let temp = snap.docs[i].data();
      temp.requestId = snap.docs[i].id;
      // console.log("org id",temp.requestId);
      // setOrgId((prev)=>[...prev,snap.docs[i].id])
      setRequests((prev) => [...prev, temp]);
    }
    // console.log("b");  
  };

  useEffect(() => {
    // console.log(userState);
    if (userState.role !== "Owner") {
      navigate("/schedule")
    }
    // if(userState.role !=="Owner"){
    //   console.log(userState.role);
    //   navigate(-1)
// }
    
  });

  useEffect(() => {
    // console.log("a");
    fetchRequests();
    // console.log(timeStamp)
  }, [timeStamp]);

  return (
    
    <div className="container">
      <Sidebar />
      <main>
        <div className="requests">
          <h1 className="requests-title">Approved Requests</h1>
          {/* {console.log("Approved",requests)} */}
          {requests.map((item, id) => (
            // console.log(id),
            <ApproveCard setTimeStamp={setTimeStamp} key={id} data={item} />
          ))}
        </div>
      </main>
    </div>
  );
};

export default Approved;
