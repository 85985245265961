import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react'
import Arrow from '../Automated/Arrow';
import IssueSnippet from '../Automated/IssueSnippet';
import './Manual.css'
import _ from 'lodash'
function Manual(props) {

  const group = _.groupBy(props.Fails , "category");
  return (
    <div>
      {/* <text className='latest_heading'>Filters and Views</text>
            <div >  
                <select id="cars" className='dropdown_white' aria-label='All Issue Filter'>
                    <option value="null" selected >All issue types</option>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                    
                </select>
                <select id="cars" className='dropdown_white' aria-label='All Issue Filter' >
                    <option value="null" selected >All Severity types</option>
                    <option value="1">Option 1</option>
                    <option value="2">Option 2</option>
                    <option value="3">Option 3</option>
                    
                </select>

                <select id="cars" className='dropdown_purple' aria-label='Group By Filter'>
                    <option value="null" selected >Group by Rules</option>
                    <option value="1">Group by Components</option>
                    <option value="2">None</option>
                
                </select> */}
                { 
              
              Object.keys(group).map((category)=>{
                // console.log(category);
                // console.log("GC",group[category]);
                const group2 = _.groupBy(group[category],"ruleId");
                // console.log("GC2",group2);
                const name = category.charAt(0).toUpperCase() + category.slice(1);
                const rows = [];
                

                function createData(rule, description, severity , issue) {
                  return { rule  , description , severity , issue };
                }
                Object.keys(group2).map((ruleId)=>{
                  
                    // console.log("RuleId",ruleId)
                    const row = createData(group2[ruleId].length+" "+ruleId , group2[ruleId][0].ruleDescription , group2[ruleId][0].severityLevel , group2[ruleId]);
                    rows.push(row);
                    // console.log("Row",row);
                 
                });
                
                return(
                  <>
                  <Accordion  disableGutters sx={{
                    backgroundColor:"#ffffff",
                    border: 'none',
                    paddingTop:'24px',
                    '&:before': {
                      display: 'none',
      
                  }
                    
                  }}  className='accordion' elevation={0} >
              <AccordionSummary
               expandIcon = {<Arrow/>}
                 sx ={{display:"inline-flex",
                 cursor: "pointer"}}
                 aria-controls="panel1a-content" 
                 id="panel1a-header"

              >
                <h1 className='accordion_heading' > {group[category].length+" "+name}</h1>
              </AccordionSummary>
              <AccordionDetails>
               
                      <IssueSnippet to="mdetails" rows = {rows} url={props.url}  siteName={props.siteName}  issueArr={group[category]}  />
              </AccordionDetails>
            </Accordion>
            <br/>
      <hr color='#E9EDF7'/>
            </>
                );
              })  
            }
            </div>

    
  )
}

export default Manual
