import React, { useState, useContext, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box, List, ListItem, ListItemText } from "@mui/material";
import { db, functions } from "../../../firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const TeamMemberList = (props) => {
  const { onClose, open, details } = props;
  //   const [data,setData]=useState([])
  // console.log(details);
  const handleClose = () => {
    onClose();
  };
  const fetchDetails = async () => {
    // const
  };
  const handleChange = (e) => {
    //
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "500px",
          //   height: "600px",
          padding: "16px",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <h1
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "1.2rem",
          alignItems: "center",
          color: "#11047A",
          fontSize: "25px",
        }}
      >
        <p>Team Member List</p>
        <button
          aria-label="close"
          onClick={handleClose}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "12px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </h1>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        {details.length != 0 ? "" : "No Member Added"}

        <List>
          {details.map((data) => {
            return (
              <ListItem style={{ padding: "0px" }}>
                <ListItemText
                  style={{
                    borderRadius: "20px",
                    border: "1px solid gray",
                    padding: "10px 20px",
                  }}
                  primary={
                    (data?.name ? data.name : "") +
                    " - " +
                    (data?.role ? data.role : "")
                  }
                />
              </ListItem>
            );
          })}
        </List>

        {/* {details?.map((data) => {
            // console.log("data",data);
          return <TextField
            // style={{ backgroundColor: "#F4F7FE" }}
            // required
            // select
            fullWidth
            disabled
            value={data.name+" - "+ data.role}
            // onChange={handleChange}
          />;
        })} */}
      </Box>
    </Dialog>
  );
};

export default TeamMemberList;
