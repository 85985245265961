const currentHost = window.location.hostname;

// Define environments
const isLocalhost = currentHost.includes("localhost");
const isProduction = currentHost.includes("webaccessibility.inclusivestem.org");

// Set the base URLs conditionally based on the environment
const baseUrls = {
  localhost: {
    projectByUserId: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/getProjectsByUserId',
    createProject: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/createProject',
    fetchReportByuid: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testFetchReport',
    testScheduleV2: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testScheduleV2',
    jiraAuthUrl: 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=5EgKf95EhAK4a8RyRy1xXOWIjzb7po41&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work%20write%3Ajira-work%20manage%3Ajira-configuration&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fsettings&state=uriJ1234&response_type=code&prompt=consent',
    jiraServerUrl: 'https://pm-tools-api.incskill.com',
    jiraRedirectUrl: 'http://localhost:3000/settings',
    fetchDocs: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testFetchDocuments'
  },
  production: {
    projectByUserId: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/getProjectsByUserId',
    createProject: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/createProject',
    fetchReportByuid: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testFetchReport',
    testScheduleV2: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testScheduleV2',
    jiraAuthUrl: 'https://auth.atlassian.com/authorize?audience=api.atlassian.com&client_id=5EgKf95EhAK4a8RyRy1xXOWIjzb7po41&scope=offline_access%20read%3Ajira-user%20read%3Ajira-work%20write%3Ajira-work%20manage%3Ajira-configuration&redirect_uri=https%3A%2F%2Fwebaccessibility.inclusivestem.org%2Fsettings&state=uriJ1234&response_type=code&prompt=consent',
    jiraServerUrl: 'https://pm-tools-api.incskill.com',
    jiraRedirectUrl: 'https://webaccessibility.inclusivestem.org/settings',
    fetchDocs: 'https://asia-south1-web-accessibility-gcp-prod.cloudfunctions.net/testFetchDocuments'
  }
};

// Conditionally select the right base URL set
export const APIConst = isLocalhost ? baseUrls.localhost : baseUrls.production;
