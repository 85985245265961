import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { httpsCallable, getFunctions } from "firebase/functions";
import ReCAPTCHA from "react-google-recaptcha";
import SideSvg from "../../assets/signup_image_right.svg";
import logo from "../../assets/portal_logo.svg";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db, functions } from "../../firebaseConfig";

export default function SignUp() {
  // const functions = getFunctions(undefined,"asia-south1");
  const registerRequest = httpsCallable(functions, "registerRequest");
  const [error, setError] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const [recaptcha, setReCaptcha] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [industries, setIndustries] = useState([]);
  const fetchIndustries = async () => {
    const colRef = doc(db, "configs/industryType/");
    // const qurey = query(colRef+"/industryType")
    const snap = await getDoc(colRef);
    // console.log("snap", snap.data());
    setIndustries(snap.data().industries);
  };
  useEffect(() => {
    fetchIndustries();
  }, []);

  // console.log(industries);
  const [details, setDetails] = useState({
    adminName: "",
    adminEmail: "",
    orgName: "",
    phoneNo: "",
    industry: "Tech",
    orgSize: "1-10",
  });
  function onCaptchaChange(value) {
    // console.log("Captcha value:", value);
    setReCaptcha(false);
    setCaptchaToken(value);
  }

  function validatePhoneForE164(phoneNumber) {
    const regEx = /^\+[1-9]\d{10,14}$/;
    return regEx.test(phoneNumber);
  }

  function checkFormValid() {
    const keys = Object.keys(details);
    let flag = true;
    keys.forEach((val) => {
      if (details[val] === "") flag = false;
    });
    return flag;
  }

  const handleSubmit = async (event) => {
    // call register function
    // console.log(details);
    setReCaptcha(true);
    setError("");
    // console.log(details);
    if (!checkFormValid()) {
      setError("Fill all the Fields");
      return;
    }
    if (!validatePhoneForE164(details.phoneNo)) {
      setError("Invalid Phone Number");
      return;
    }
    const res = await registerRequest({
      ...details,
      captchaToken: captchaToken,
    });
    setCaptchaToken(null);
    console.log("err", res?.data?.errMessage);
    if (res.data.errMessage == "Already Exist") {
      // console.log(captchaToken);
      setReCaptcha(false);
      setError("Already Exist");
      return;
    }
    setSubmitted(true);

    // navigate("/");
  };

  const handleInput = (e) => {
    // console.log(details);
    const name = e.target.name;
    const value = e.target.value;
    setDetails({ ...details, [name]: value });
    setReCaptcha(false);
  };

  return (
    <Container
      component="main"
      style={{
        paddingLeft: "150px",
      }}
      sx={{
        width: "90%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "0",
      }}
      maxWidth={false}
    >
      <CssBaseline />

      {!submitted ? (
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 4,
            display: "flex",
            flexDirection: "column",
            maxWidth: "480px",
            alignItems: "left",
          }}
        >
          <img
            src={logo}
            alt="Portal Logo"
            style={{
              width: "220px",
              height: "50px",

              marginLeft: "-20px",
              marginBottom: "20px",
            }}
          />
          <Typography component="h1" variant="h5">
            Sign Up
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <label htmlFor="Name">Your Name</label>
                <TextField
                  style={{ backgroundColor: "#F4F7FE" }}
                  autoFocus
                  required
                  fullWidth
                  autoComplete="name"
                  name="adminName"
                  placeholder="Contact Name"
                  id="Name"
                  value={details.adminName}
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="email">Email id</label>
                <TextField
                  style={{ backgroundColor: "#F4F7FE" }}
                  required
                  fullWidth
                  placeholder="jhondoe@example.com"
                  id="email"
                  name="adminEmail"
                  autoComplete="email"
                  value={details.adminEmail}
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="orgName">Organisation Name</label>
                <TextField
                  style={{ backgroundColor: "#F4F7FE" }}
                  required
                  fullWidth
                  autoComplete="orgName"
                  placeholder="Organisation Name"
                  id="orgName"
                  name="orgName"
                  value={details.orgName}
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="phoneNo">Phone (with country code)</label>

                <TextField
                  style={{ backgroundColor: "#F4F7FE" }}
                  required
                  fullWidth
                  placeholder="+919999999999"
                  id="phoneNo"
                  name="phoneNo"
                  autoComplete="phone"
                  value={details.phoneNo}
                  onChange={handleInput}
                />
              </Grid>
              <Grid item xs={12}>
                <label aria-expanded={false} htmlFor="industry">
                  Industry
                </label>
                <TextField
                  style={{ backgroundColor: "#F4F7FE" }}
                  required
                  select
                  fullWidth
                  placeholder="Industry"
                  id="industry"
                  name="industry"
                  autoComplete="industry"
                  value={details.industry}
                  onChange={handleInput}
                >
                  {industries?.map((data, index) => {
                    return (
                      <MenuItem aria-label={data} key={index} value={data}>
                        {data}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                {/* <InputLabel id="orgSize-label">Organisation Size</InputLabel> */}
                <label htmlFor="orgSize">Organisation Size</label>
                <TextField
                  style={{ backgroundColor: "#F4F7FE" }}
                  select
                  id="orgSize"
                  name="orgSize"
                  placeholder="Organisation Size"
                  value={details.orgSize}
                  onChange={handleInput}
                  sx={{ width: "100%" }}
                >
                  <MenuItem disabled value={""}>
                    Organisation Size
                  </MenuItem>

                  <MenuItem aria-label="1-10" value={"1-10"}>
                    1-10
                  </MenuItem>
                  <MenuItem aria-label="10-50" value={"10-50"}>
                    10-50
                  </MenuItem>
                  <MenuItem aria-label="50-100" value={"50-100"}>
                    50-500
                  </MenuItem>
                  <MenuItem aria-label="Above 500" value={"500+"}>
                    500+
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <ReCAPTCHA
                  style={{ width: "100%" }}
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  onChange={onCaptchaChange}
                />
              </Grid>

              <Grid item xs={12}>
                {error && (
                  <Alert icon={false} severity="error">
                    {error}
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              disabled={recaptcha ? true : false}
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: "70px" }}
              onClick={handleSubmit}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ) : (
        <Box>
          <img
            src={logo}
            alt="Portal Logo"
            style={{
              width: "220px",
              height: "50px",

              marginBottom: 4,
              marginTop: "30px",
              marginLeft: "-20px",
            }}
          />
          <Typography variant="h5">✅ We will contact you soon. </Typography>
          <Typography>
            We have received your request. We will reach out to you in next 2
            business days to understand more about your requirements and give
            you access to the portal.You can contact us on{" "}
            <a href="mailto:kartik@inclusivestem.org">
              kartik@inclusivestem.org
            </a>{" "}
            for more details.
          </Typography>
        </Box>
      )}
      <img
        style={{
          marginLeft: "auto",
          height: "720px",
        }}
        src={SideSvg}
      />
    </Container>
  );
}
