import {
  Alert,
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useContext, useEffect, useState } from "react";
import { auth, db, functions } from "../../firebaseConfig";
import { AuthContext } from "../Context/AuthContext";
import RevokeAccess from "../Members/Dialogs/RevokeAccess";
import "../Requests/request.css";

export default function AdminSecurity({ handleClick }) {
  const { userState } = useContext(AuthContext);
  //   console.log(userState);
  const [details, setDetails] = useState({});
  // const [updateDetails, setUpdateDetails] = useState({});
  const [disabled, setDisabled] = useState(true);
  // const [industries, setIndustries] = useState([]);
  // const fetchIndustries = async () => {
  //   const colRef = doc(db, "configs/industryType/");
  //   // const qurey = query(colRef+"/industryType")
  //   const snap = await getDoc(colRef);
  //   console.log("snap", snap.data());
  //   setIndustries(snap.data().industries);
  // };
  const fetchDetails = async () => {
    const orgDocRef = doc(db, "Organisations/" + userState.orgId);
    const orgSnap = await getDoc(orgDocRef);
    const userDocRef = doc(db, "Users/" + userState.uid);
    const userSnap = await getDoc(userDocRef);
    // console.log("userDocRef", userDocRef);
    // console.log("company details", orgSnap.data());
    // console.log("user details", userSnap.data());
    // console.log(orgSnap.data());
    setDetails({...orgSnap.data(),...userSnap.data()});
  };
  // console.log(details);
  useEffect(() => {
    fetchDetails();
    // fetchIndustries();
    
  }, []);

  const [openRevoke, setOpenRevoke] = useState(false);

  const handleRevoke = async () => {
    // console.log(userState);

    const revokeAccess = httpsCallable(functions, "revokeAccess");
    await revokeAccess({
      orgId: userState.orgId,
    });
    //   setClicked("Revoked");
    //   setTimeStamp(Date.now);
    // console.log("Revoked");
  };
  const handleClose = () => {
    setOpenRevoke(false);
  };
  const handleInput = (e) => {
    setDisabled(false);
    const { name, value } = e.target;
    // console.log(name, value);
    setDetails({
      ...details,
      [name]: value,
    });
    // setDetails
  };

  const handleSubmit = async () => {
    setDisabled(true)
    const docRef = doc(db, "Users/" + userState.uid);
      // console.log("docRef", docRef);
      await updateDoc(docRef, {
        name:details.name ? details.name : "",
        email:details.email,
        phoneNo:details.phoneNo? details.phoneNo: "",
        orgId:details.orgId,
      });
    // window.alert("Updated");
    // console.log(details);
  };

  return (
    <Container component="main">
      <CssBaseline />
      <h3
        style={{ position: "absolute", left: "360px", top: "120px" }}
        className="accessEmail"
      >
        Basic Info
      </h3>
      <div className="adminDiv">
        <Box sx={{ mt: 1 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={6}>
              <label htmlFor="Name">Your Name</label>
              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                fullWidth
                autoComplete="name"
                name="name"
                placeholder="Contact Name"
                id="Name"
                value={details.name}
                onChange={handleInput}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="email">Email id</label>
              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                disabled
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                value={details.email}
                onChange={handleInput}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="orgName">Organisation Name</label>
              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                disabled
                fullWidth
                placeholder="Organisation Name"
                id="orgName"
                name="orgName"
                autoComplete="orgName"
                value={details.orgName}
                onChange={handleInput}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="phoneNo">Phone (with country code)</label>

              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                fullWidth
                placeholder="+91 9999999999"
                id="phoneNo"
                name="phoneNo"
                autoComplete="phone"
                value={details.phoneNo}
                onChange={handleInput}
              />
            </Grid>
            <Grid item xs={6}>
              <label htmlFor="industry">Industry</label>
              <TextField
                style={{ backgroundColor: "#F4F7FE" }}
                required
                disabled
                fullWidth
                placeholder="Industry"
                id="industry"
                name="industry"
                autoComplete="industry"
                value={details.industry}
                onChange={handleInput}
              />
            </Grid>
            <Grid item xs={6}>
              {/* <InputLabel id="orgSize-label">Organisation Size</InputLabel> */}
              <label htmlFor="orgSize">Organisation Size</label>
              <Select
                style={{ backgroundColor: "#F4F7FE" }}
                select
                disabled
                id="orgSize"
                name="orgSize"
                placeholder="Organisation Size"
                value={`${details.orgSize}`}
                onChange={handleInput}
                sx={{ width: "100%" }}
              >
                {/* {console.log("details.orgSize", details.orgSize)} */}
                <MenuItem value={"1-10"}>1-10</MenuItem>
                <MenuItem value={"10-50"}>10-50</MenuItem>
                <MenuItem value={"50-100"}>50-500</MenuItem>
                <MenuItem value={"500+"}>500+</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              {/* {error && (
                  <Alert icon={false} severity="error">
                    {error}
                  </Alert>
                )} */}
            </Grid>
          </Grid>
          <Button
            type="submit"
            disabled={disabled}
            variant="contained"
            sx={{
              width: "400px",
              height: "46px",
              mt: 3,
              mb: 2,
              borderRadius: "70px",
            }}
            onClick={handleSubmit}
          >
            Save Info
          </Button>
        </Box>
        <div style={{ display: "block" }}>
          <h3 className="accessEmail">Reset Password</h3>
          <Button
            onClick={handleClick}
            style={{
              borderRadius: "70px",
              width: "439px",
              height: "46px",
              border: "1px solid #A3AED0",
            }}
            variant="outlined"
          >
            Reset My Password
          </Button>
        </div>

        {userState.role == "Admin" && (
          <div style={{ display: "block" }}>
            <h3 className="accessEmail">Data</h3>
            <Button
              onClick={() => setOpenRevoke(true)}
              style={{
                borderRadius: "70px",
                width: "439px",
                height: "46px",
                border: "1px solid #A3AED0",
              }}
              variant="outlined"
            >
              Delete Complete Data
            </Button>
          </div>
        )}
      </div>
      <br />
      {userState.role == "Admin" && (
        <RevokeAccess
          open={openRevoke}
          handleClick={handleRevoke}
          name={details.orgName}
          uid={userState.orgId}
          onClose={handleClose}
        />
      )}
    </Container>
  );
}
