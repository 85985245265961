import axios from "axios";
import { db, resetPassword, userStateValues } from "../firebaseConfig";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  setDoc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { useContext } from "react";
import { PmContext } from "../Components/Context/PmContext";


export const fetchRefreshTokenAndCloudIdFromFirestore = async (userId) => {
  try {
    // Define the document reference where the access_token is stored
    const docRef = doc(db, `Users/${userId}/pmTools/Jira`);
    // console.log("docref", docRef);

    // Fetch the document from Firestore
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Extract the access_token from the document data
      const data = docSnapshot.data();
      const refreshToken = data.refresh_token;
      const cloudId = data.cloud_id;

      if (refreshToken) {
        // console.log("Refresh token fetched successfully:");
        return [refreshToken, cloudId];
      } else {
        console.log("Refresh token not found in the document.");
        return null;
      }
    } else {
      console.log("Document does not exist.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching refresh token from Firestore:", error);
    return null;
  }
};

export const refreshTokens = async (userId, refreshToken, url) => {
  try {
    const payload = { refreshToken };
    const response = await axios.post(
      `${url}/jira/token/refresh`,
      JSON.stringify(payload),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const access_token = response.data.access_token;
    const refresh_token = response.data.refresh_token;
    const docRef = doc(db, `Users/${userId}/pmTools/Jira`);

    await updateDoc(docRef, { access_token, refresh_token }, { merge: true });

    // console.log("successfully updated refresh and access tokens in Firestore");
    // console.log("access token in access token function", access_token);

    return access_token;
  } catch (error) {
    console.error("Error storing parameters in Firestore:", error);
    return null;
  }
};

export const fetchJiraProjects = async (url, cloud_id, access_token) => {
  try {
    const response = await axios.get(`${url}/jira/projects/${cloud_id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("Jira projects", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Jira projects", error);
    return null;
  }
};

export const fetchUserJiraDetails = async (userId) => {
  const docRef = doc(db, `Users/${userId}/pmTools/Jira`);
  const response = await getDoc(docRef);
  // console.log("users/userid/pmtools/jira",response.data().access_token);
  return response.data();
}

export const fetchJiraProjectById = async (url, cloud_id, access_token, projectId) => {
  // console.log("current project id",projectId);
  try {
    const response = await axios.get(`${url}/jira/projects/${cloud_id}/${projectId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("Jira project", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Jira projects", error);
    return null;
  }
};

export const fetchJiraAssignees = async (url, cloud_id, access_token) => {
  try {
    const response = await axios.get(`${url}/jira/users/${cloud_id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("Jira users", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Jira users", error);
    return null;
  }
};

export const fetchJiraAssigneeById = async (url, cloud_id, access_token, userId) => {
  try {
    const response = await axios.get(`${url}/jira/users/${cloud_id}/${userId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("Jira user", response);
    return response.data;
  } catch (error) {
    console.error("Error fetching Jira projects", error);
    return null;
  }
};

export const fetchJiraEpics = async (url, cloud_id, access_token,projectId) => {
  try {
    const response = await axios.get(`${url}/jira/projects/hierarchy/${cloud_id}/${projectId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    // console.log("Jira project epics", response.data.hierarchy);
    const filteredEpics = response.data.hierarchy.filter(
      (issue) => issue.fields.issuetype.id === '10000'
    );
    // console.log("Epics",filteredEpics);
    return filteredEpics;
  } catch (error) {
    console.error("Error fetching Jira users", error);
    return null;
  }
};

export const fetchJiraStories = async (url, cloud_id, access_token, projectId, epicId) => {
  try {
    const response = await axios.get(`${url}/jira/projects/hierarchy/${cloud_id}/${projectId}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    // Assuming the response contains an array of Jira issues
    const epic = response.data.hierarchy.find((epic) => epic.id === epicId);
    // console.log("found epic",epic);
    return epic.children;
  } catch (error) {
    console.error("Error fetching Jira stories", error);
    return null;
  }
};

export const saveJiraDetails = async (docPath, jiraProjectId, jiraUserId, taskId, taskKey, epicId=null, storyId=null) => {
  // console.log("docpath",docPath);
  const docRef = doc(db, docPath);

  const data = {
    jiraProjectId,
    jiraUserId,
    taskId,
    taskKey
  };

  if(epicId !=null && storyId != null) {
    data.epicId = epicId;
    data.storyId = storyId;
  }
  

  try {
    await setDoc(docRef, data, { merge: true });
    console.log("Document created or updated with Jira details");
  } catch (error) {
    console.error("Error saving Jira details to Firestore:", error);
  }
};

export const saveJiraProjectMapping = async (docPath, jiraProjectId, jiraUserId, jiraProjectName, jiraUserName) => {
  // console.log("docpath",docPath);
  const docRef = doc(db, docPath);

  const data = {
    jiraProjectId,
    jiraUserId,
    jiraProjectName,
    jiraUserName
  };

  try {
    await setDoc(docRef, data, { merge: true });
    console.log("Document created or updated with Jira details");
  } catch (error) {
    console.error("Error saving Jira details to Firestore:", error);
  }
};

// export const createStory = async (
//   serverUrl,
//   accessToken,
//   cloudId,
//   jira,
//   tags,
//   parentStoryKey
// ) => {

//   console.log("inside create Issue",jira,tags,parentStoryKey);

//   const jiraIssue = {
//     fields: {
//       assignee: {
//         id: jira.assignee.accountId,
//       },
//       description: {
//         content: [
//           {
//             content: [
//               {
//                 text: jira.description,
//                 type: "text",
//               }
//             ],
//             type: "paragraph",
//           },
//         ],
//         type: "doc",
//         version: 1,
//       },
//       issuetype: {
//         id: "10001",
//       },
//       parent: {
//                 key: parentStoryKey,
//               },
//       // labels: tags,
//       priority: {
//         id: "3",
//       },
//       project: {
//         id: jira.project.id,
//       },
//       summary: jira.summary,
//     },
//     update: {},
//   };

//   try {
//     const response = await axios.post(
//       `${serverUrl}/jira/issue/create/${cloudId}`,
//       { jiraIssue },
//       {
//         headers: {
//           Authorization: `Bearer ${accessToken}`,
//           "Content-Type": "application/json",
//         },
//       }
//     );

//     const jsonResponse = response.data;
//     // document.getElementById('outputArea').textContent = JSON.stringify(jsonResponse, null, 2);
//     console.log("Issue Created Successfully", jsonResponse);
//     return jsonResponse;
//     // attachFile(jsonResponse.key);
//   } catch (error) {
//     console.log("error creating Jira issue", error);
//     return null;
//   }
// };


export const createIssue = async (
  serverUrl,
  accessToken,
  cloudId,
  jira,
  tags,
  parentStoryKey
) => {

  // console.log("inside create Issue",jira,tags,parentStoryKey);

  const jiraIssue = {
    fields: {
      assignee: {
        id: jira.assignee.accountId,
      },
      description: {
        content: [
          {
            content: [
              {
                text: jira.description,
                type: "text",
              }
            ],
            type: "paragraph",
          },
        ],
        type: "doc",
        version: 1,
      },
      issuetype: {
        id: "10001",
      },
      parent: {
                key: parentStoryKey,
              },
      labels: tags,
      priority: {
        id: "3",
      },
      project: {
        id: jira.project.id,
      },
      summary: jira.summary,
    },
    update: {},
  };

  try {
    const response = await axios.post(
      `${serverUrl}/jira/issue/create/${cloudId}`,
      { jiraIssue },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const jsonResponse = response.data;
    // document.getElementById('outputArea').textContent = JSON.stringify(jsonResponse, null, 2);
    console.log("Issue Created Successfully", jsonResponse);
    return jsonResponse;
    // attachFile(jsonResponse.key);
  } catch (error) {
    console.log("error creating Jira issue", error);
    return null;
  }
};

export const createSubTask = async (
  serverUrl,
  accessToken,
  cloudId,
  jira,
  jiraParentTaskKey
) => {

  // console.log("inside create Issue",jira);

  const jiraIssue = {
    fields: {
      assignee: {
        id: jira.assignee.accountId,
      },
      description: {
        content: [
          {
            content: [
              {
                text: jira.description,
                type: "text",
              }
            ],
            type: "paragraph",
          },
        ],
        type: "doc",
        version: 1,
      },
      issuetype: {
        id: "10003",
      },
      parent: {
                key: jiraParentTaskKey,
              },
      priority: {
        id: "3",
      },
      project: {
        id: jira.project.id,
      },
      summary: "Individual violation/warning",
    },
    update: {},
  };

  try {
    const response = await axios.post(
      `${serverUrl}/jira/issue/create/${cloudId}`,
      { jiraIssue },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    const jsonResponse = response.data;
    // document.getElementById('outputArea').textContent = JSON.stringify(jsonResponse, null, 2);
    console.log("Sub Task Created Successfully", jsonResponse);
    return jsonResponse;
    // attachFile(jsonResponse.key);
  } catch (error) {
    console.log("error creating Jira issue", error);
    return null;
  }
};

export const fetchJiraDetails = async (docPath) => {
  try {
    const docRef = doc(db, docPath);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      // Extract the access_token from the document data
      const data = docSnapshot.data();
      // console.log("task data",data);
      return data;
    }
  } catch (err) {
    console.log("error fatching details", err);
    return null;
  }
};

export const attachFile = async (serverUrl, accessToken,cloudId,fileUrl, issueId) => {
  // const formData = new FormData();
  // formData.append("file", fileInput);
  console.log("inside attach file fileUrl",fileUrl);
  try {

    const fileResponse = await fetch(fileUrl);
    // console.log("fileResponse",fileResponse);
      const fileBlob = await fileResponse.blob();
      console.log("fileblob",fileBlob);
      const fileName = "screenShot"; // Get the file name from the URL

      const file = new File([fileBlob], fileName, { type: fileBlob.type });

      const formData = new FormData();
      formData.append("file", file);

    const response = await axios.post(
      `${serverUrl}/jira/issue/attachment/${cloudId}/${issueId}`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      }
    );

    const jsonResponse = response.data;
    // document.getElementById('outputArea').textContent = JSON.stringify(jsonResponse, null, 2);
    console.log("Screenshot added Successfully", jsonResponse);
    return jsonResponse;
  } catch (error) {
    // Log detailed error information
    if (error.response) {
        console.error('Error Data:', error.response.data);
        console.error('Error Status:', error.response.status);
        console.error('Error Headers:', error.response.headers);
    } else {
        console.error('Error Message:', error.message);
    }
    // setOutput(`Error: ${error.response?.status} - ${error.message}`);
}
}

export const SetPmTool = async (userId,setEnableTool,enableTool) => {
  const res = await fetchRefreshTokenAndCloudIdFromFirestore(userId);
  // console.log("tokens and cloud if at schedule",res);
  if(res!=null && res!== undefined) {
    setEnableTool({
      ...enableTool,
      jira: true
    });
  } else {
    setEnableTool({
      ...enableTool,
      jira: false
    });
  }
}