import React, { useContext, useEffect, useState } from "react";
import "./EntireReport.css";
import backButton from "../../../../assets/back_button.svg";
import { Tab, Tabs, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Automated from "./Elements/Automated/Automated";
import { useLocation, useNavigate } from "react-router-dom";
import { getDownloadURL, ref } from "firebase/storage";
import { auth, db, userStateValues } from "../../../../firebaseConfig";
import Manual from "./Elements/Manual/Manual";
import axios from "axios";
import { collection, doc, getDoc, getDocs,onSnapshot } from "firebase/firestore";
import _ from "lodash";
import { AuthContext } from "../../../Context/AuthContext";
import LinearIndeterminate from "../../../Misc/LinearLoader/LinearLoader";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function EntireReport(props) {
  const { userState } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  // console.log("props",props);

  let currentFetchingMethod;
  if(props.id) {
    currentFetchingMethod = props;
  } else {
    currentFetchingMethod = location.state;
  }

  // console.log("inside entire report", currentFetchingMethod);
  // const goToLogin = () => navigate("/");

  // useEffect(() => {
  //   if (userState == userStateValues.loggedOut) {
  //     goToLogin();
  //   }
  // });

  const sanitizeUrl = (badUrl) => {
    // console.log("badUrl: ", badUrl);
    let res = "";
    for (let i = 0; i < badUrl.length; i++) {
      if (badUrl[i] != "/") res += badUrl[i];
      else if (i != badUrl.length - 1) {
        res += "_";
      }
    }
    return res;
  };
  const sanitizedUrl = sanitizeUrl(currentFetchingMethod.url);
  // console.log("Location State at entire report", currentFetchingMethod);

  const fails = location?.state?.fails;
  // console.log("FL", fails);
  const [Fails, setFails] = useState([]);
  const [Pass, setPass] = useState([]);
  const [Warnings, setWarnings] = useState([]);
  const [Manul, setManul] = useState([]);
  const [loading, setLoading] = useState(false);

  // const []
  const goBack = () => {
    if(currentFetchingMethod.id) {
      navigate('/');
    }else {
      navigate(-1);
    }
  };
  let SSurl;
  let downloadUrl;

  useEffect(() => {
    if (currentFetchingMethod.id) {
      console.log("id",currentFetchingMethod.id);
      getDataFromProps();
    } else {
      getData();
    }
  }, [currentFetchingMethod,props.loaction?.state]);

  useEffect(() => {
    // console.log("status of report", currentFetchingMethod.status);
    if (currentFetchingMethod?.status !== "Pending") {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [currentFetchingMethod]);


  const userID = currentFetchingMethod?.userID ?? "";

  const getDataFromProps = () => {

    console.log("get data from props");

    const failures = currentFetchingMethod.entireDoc.pages.fails
      ? currentFetchingMethod.entireDoc.pages.fails
      : [];
    const passed = currentFetchingMethod.entireDoc.pages.pass
      ? currentFetchingMethod.entireDoc.pages.pass
      : [];
    const warns = currentFetchingMethod.entireDoc.pages.warnings
      ? currentFetchingMethod.entireDoc.pages.warnings
      : [];

    if(currentFetchingMethod.entireDoc.downloadUrl) {
      axios.get(currentFetchingMethod.entireDoc.downloadUrl).then((res) => {
        const urls = res.data.callbackResponse.result.urls;
        const manual = res.data.callbackResponse.result.manual.all;
        setManul(manual);
      });
    }

    setWarnings(warns);
    setPass(passed);
    setFails(failures);
    // console.log("fails : ",fails);
  };

  const getData = async function () {

    console.log("get data from location.state");

    // console.log("LATEST", currentFetchingMethod.latestRequest);
    // const userID = auth.currentUser.uid;
    // console.log({ userID });
    if (!userID) {
      return;
    }

    const docRef = doc(
      db,
      "Projects/" +
        currentFetchingMethod.projectId +
        "/Reports/" +
        currentFetchingMethod.siteName +
        "/" +
        sanitizedUrl +
        "/" +
        currentFetchingMethod.latestRequest
    );
    const query = (await getDoc(docRef)).data();

    const failRef = collection(
      db,
      "Projects/" +
        currentFetchingMethod.projectId +
        "/Reports/" +
        currentFetchingMethod.siteName +
        "/" +
        sanitizedUrl +
        "/" +
        currentFetchingMethod.latestRequest +
        "/pages/" +
        sanitizedUrl +
        "/fails"
    );
    const passRef = collection(
      db,
      "Projects/" +
        currentFetchingMethod.projectId +
        "/Reports/" +
        currentFetchingMethod.siteName +
        "/" +
        sanitizedUrl +
        "/" +
        currentFetchingMethod.latestRequest +
        "/pages/" +
        sanitizedUrl +
        "/pass"
    );
    const warnRef = collection(
      db,
      "Projects/" +
        currentFetchingMethod.projectId +
        "/Reports/" +
        currentFetchingMethod.siteName +
        "/" +
        sanitizedUrl +
        "/" +
        currentFetchingMethod.latestRequest +
        "/pages/" +
        sanitizedUrl +
        "/warnings"
    );

    const fails = await getDocs(failRef);
    const passes = await getDocs(passRef);
    const warnings = await getDocs(warnRef);
    // console.log("fails inside entire report",fails);
    const manualArr = [];
    const failures = [];
    const passed = [];
    const warns = [];

    fails.forEach((doc) => {
      // failures.push(doc.data());
      // console.log("individual fails",doc.data());
      // console.log("id for individual fails",doc.id);
      const fail = {
        ...doc.data(),
        id: doc.id
      };
      failures.push(fail);
    });
    passes.forEach((doc) => {
      passed.push(doc.data());
    });
    warnings.forEach((doc) => {
      // console.log("individual warnings",doc.data());
      // console.log("id for individual warnings",doc.id);
      const warning = {
        ...doc.data(),
        id: doc.id
      };
      warns.push(warning);
    });
    setWarnings(warns);
    setPass(passed);
    // console.log("set fail called");
    setFails(failures);
    // console.log("fails",Fails);
    // console.log("Q", query.downloadUrl);

    axios.get(query.downloadUrl).then((res) => {
      const urls = res.data.callbackResponse.result.urls;
      const manual = res.data.callbackResponse.result.manual.all;
      setManul(manual);
    });
  };

  // console.log("Fails", Fails);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* {loading && (
        <div className="loader-container">
        <p>loader loaded</p>
          <LinearIndeterminate />
        </div>
      )} */}

      {!loading && (
        <div className="entire_report">
          <button
            aria-label="Back Button"
            className="buttonBack"
            onClick={goBack}
          >
            <img
              src={backButton}
              className="backButton"
              alt="Back button icon "
            />
          </button>{" "}
          <text
            className="latest_heading"
            aria-label="Back to previous page"
            onClick={goBack}
            style={{ cursor: "pointer" }}
          >
            {currentFetchingMethod.hasMore? "Partial": "Complete"} report of {currentFetchingMethod?.url} on{" "}
            {currentFetchingMethod?.lastScanned}
          </text>
          <div className="ER_Container">
            <div className="scanned_result_container">
              <br />

              <div className="tabs">
                <Tabs
                  TabIndicatorProps={{
                    style: {
                      color: "#11047A",
                      height: "4px",
                      borderRadius: "70px",
                      marginTop: "24px",
                    },
                  }}
                  TabScrollButtonProps={{
                    style: {
                      textTransform: "none",
                      fontStyle: "normal",
                      paddingBottom: "24px",
                    },
                  }}
                  value={value}
                  onChange={handleChange}
                  textColor="primary"
                  indicatorColor="primary"
                  aria-label="secondary tabs example"
                >
                  <Tab
                    sx={{
                      textDecoration: "none",
                      textTransform: "none",
                      letterSpacing: "-0.02em",
                      fontSize: "20px",
                      marginBottom: "24px",
                      marginLeft: "12px",
                      marginRight: "12px",
                    }}
                    label="Violations"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{
                      textDecoration: "none",
                      textTransform: "none",
                      letterSpacing: "-0.02em",
                      fontSize: "20px",
                      marginBottom: "24px",
                      marginLeft: "12px",
                      marginRight: "12px",
                    }}
                    label="Warnings"
                    {...a11yProps(1)}
                  />
                  <Tab
                    sx={{
                      textDecoration: "none",
                      textTransform: "none",
                      letterSpacing: "-0.02em",
                      fontSize: "20px",
                      marginBottom: "24px",
                      marginLeft: "12px",
                      marginRight: "12px",
                    }}
                    label="Passed"
                    {...a11yProps(2)}
                  />

                  <Tab
                    sx={{
                      textDecoration: "none",
                      textTransform: "none",
                      letterSpacing: "-0.02em",
                      fontSize: "20px",
                      marginBottom: "24px",
                      marginLeft: "12px",
                      marginRight: "12px",
                    }}
                    label="Manual"
                    {...a11yProps(3)}
                  />
                </Tabs>
              </div>
              <TabPanel value={value} index={0}>
                <Automated
                  latestRequest={currentFetchingMethod?.latestRequest}
                  to="details"
                  userID={userID}
                  downloadUrl={downloadUrl}
                  Fails={Fails}
                  siteName={currentFetchingMethod?.siteName}
                  url={currentFetchingMethod?.url}
                  issue_no={currentFetchingMethod?.issues}
                  projectId = {currentFetchingMethod.projectId}
                  path = {`Projects/${currentFetchingMethod.projectId}/Reports/${currentFetchingMethod.siteName}/${sanitizedUrl}/${currentFetchingMethod.latestRequest}/pages/${sanitizedUrl}/fails`}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Automated
                  latestRequest={currentFetchingMethod?.latestRequest}
                  to="details"
                  userID={userID}
                  downloadUrl={downloadUrl}
                  Fails={Warnings}
                  siteName={currentFetchingMethod?.siteName}
                  url={currentFetchingMethod?.url}
                  issue_no={currentFetchingMethod?.issues}
                  issueArr={Warnings}
                  projectId = {currentFetchingMethod.projectId}
                  path = {`Projects/${currentFetchingMethod.projectId}/Reports/${currentFetchingMethod.siteName}/${sanitizedUrl}/${currentFetchingMethod.latestRequest}/pages/${sanitizedUrl}/warnings`}
                />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Automated
                  latestRequest={currentFetchingMethod?.latestRequest}
                  to="pdetails"
                  userID={userID}
                  downloadUrl={downloadUrl}
                  Fails={Pass}
                  siteName={currentFetchingMethod?.siteName}
                  url={currentFetchingMethod?.url}
                  issue_no={currentFetchingMethod?.issues}
                  issueArr={Pass}
                  projectId = {currentFetchingMethod.projectId}
                />
              </TabPanel>
              <TabPanel value={value} index={3}>
                <Manual
                  latestRequest={currentFetchingMethod?.latestRequest}
                  downloadUrl={downloadUrl}
                  Fails={Manul}
                  userID={userID}
                  siteName={currentFetchingMethod?.siteName}
                  url={currentFetchingMethod?.url}
                  issue_no={currentFetchingMethod?.issues}
                  issueArr={Manul}
                  projectId = {currentFetchingMethod.projectId}
                />
              </TabPanel>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
