import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { doc, getDoc } from "firebase/firestore";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import backButton from "../../../../assets/back_button.svg";
import { AuthContext } from "../../../Context/AuthContext";

import { auth, db, userStateValues } from "../../../../firebaseConfig";

function Prev() {
  const { userState } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const sanitizeUrl = (badUrl) => {
    // console.log("badUrl: ", badUrl);
    let res = "";
    for (let i = 0; i < badUrl.length; i++) {
      if (badUrl[i] != "/") res += badUrl[i];
      else if (i != badUrl.length - 1) {
        res += "_";
      }
    }
    return res;
  };
  const sanitizedUrl = sanitizeUrl(location?.state?.url);

  // console.log("location STate at Prev.js", location.state);
  let rows;
  rows = location?.state?.rows;
  console.log({ rows });
  const goToLogin = () => navigate("/");

  useEffect(() => {
    if (userState == userStateValues.loggedOut) {
      goToLogin();
    }
    // else {
    //   // console.log("location state", location.state);
    // }
  });

  const viewReport = async (id, date, mode) => {
    const userID = location?.state?.userID ?? "";
    console.log({ userID });
    // console.log("ReqId", id);
    if (mode == "crawl") {
      const Pages = [];
      const docRef = doc(
        db,
        "Projects/" +
          location.state.projectId +
          "/Reports/" +
          location.state.siteName +
          "/" +
          sanitizedUrl +
          "/" +
          id
      );
      // console.log(docRef);
      const data = await getDoc(docRef);
      // const downloadUrl = data.data().downloadUrl;
      // console.log("DownloadUrl", downloadUrl);
      // console.log("Calling Api");
      axios
        .get(data.data().downloadUrl)
        .then((res) => {
          // console.log("Response recived");
          const urls = res.data.callbackResponse.result.urls;
          const manual = res.data.callbackResponse.result.manual;
          // console.log("MANUAL", manual);
          navigate("/reports/scanned-result", {
            state: {
              result: res.data.callbackResponse.result,
              siteName: location.state.siteName,
              url: location.state.url,
              lastScanned: location.state.lastScanned,
              latestRequest: id,
              pages: Pages,
              manual: manual,
              userID: userID,
              projectId: location.state.projectId
            },
          });
        })
        .catch((err) => {
          console.log("Error", err);
        });
    }
    navigate("/reports/scanned-result/entire-report", {
      state: {
        siteName: location.state.siteName,
        url: location.state.url,
        userID: userID,
        lastScanned: date,
        latestRequest: id,
        hasMore: location.state.hasMore,
        projectId: location.state.projectId
      },
    });
  };

  return (
    userState !== "" && (
      <div style={{ marginLeft: "180px", marginTop: "50px" }}>
        <button
          aria-label="Back Button"
          className="buttonBack"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={backButton}
            className="backButton"
            alt="Back button icon "
          />
        </button>{" "}
        <text className="latest_heading">
          Previous report history of {location.state.siteName}
        </text>
        <div
          className="inventory2"
          style={{ marginTop: "34px", padding: "32px" }}
        >
          <h2
            style={{
              fontSize: "24px",
              color: "#2B3674",
              fontFamily: "'DM Sans',sans-serrif",
            }}
          >
            List of Scans
          </h2>
          <TableContainer
            component={Paper}
            sx={{ border: "none", boxShadow: "none", marginTop: "30px" }}
          >
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ color: "#2B3674", opacity: "70%" }}>
                    Mode
                  </TableCell>
                  <TableCell sx={{ color: "#2B3674", opacity: "70%" }}>
                    Issue Count
                  </TableCell>
                  <TableCell sx={{ color: "#2B3674", opacity: "70%" }}>
                    Warnings Count
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: "#2B3674", opacity: "70%" }}
                  >
                    Tested against
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: "#2B3674", opacity: "70%" }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: "#2B3674", opacity: "70%" }}
                  >
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{ height: "25px", color: "#11047A" }}
                    >
                      {row.mode}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#11047A" }}
                    >
                      {row.issueCount}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ color: "#11047A" }}
                    >
                      {row.warningsCount}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ height: "25px", color: "#11047A" }}
                    >
                      {row.testedAgainst}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#11047A" }}>
                      {row.date}
                    </TableCell>
                    <TableCell align="left" sx={{ color: "#11047A" }}>
                      {console.log(row)}
                      {row.hasMore ? "Partial Result" : row.status}
                      {/* {row.status} */}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        color: "#11047A",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <a
                        onClick={() => {
                          viewReport(row.reqId, row.date, row.mode);
                        }}
                      >
                        {row.link}
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    )
  );
}

export default Prev;
