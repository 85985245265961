import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  getAuth,
} from "firebase/auth";
import LoginImage from "../../../assets/LoginImage.svg";
import Logo from "../../../assets/portal_logo.svg";
//portal_logo.svg
import "./style.css";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthContext";

export default function ResetPassword(props) {
  const {clicked,setClicked} = useContext(AuthContext)

  const auth = getAuth();
  const [password, setPassword] = useState({
    newPassword: "",
    cnfPassword: "",
  });
  const navigate = useNavigate();
  const goToHome = () => navigate("/");
  const [error, setError] = useState("");
  const [showPass, setShowPass] = useState([false, false]);
  const handleMouseDownPassword = (index) => {
    // console.log("test", index);
    setShowPass((prev) => {
      let newState = Array.from(prev);
      newState[index] = !prev[index];
      return newState;
    });
  };
  const handleInput = (e) => {
    setError("");
    const { name, value } = e.target;
    setPassword((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const changePassword = async () => {
    
    if (password.cnfPassword !== password.newPassword) return;
    if (password.cnfPassword.length < 8) {
      setError("Password must be atleast 8 characters");
      return;
    }
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    let value = params.oobCode;
    // can make is so that if a continue url is given we can redirect to the continue url
    // console.log(value);
    try {
      setClicked(true)
      const email = await verifyPasswordResetCode(auth, value);
      // console.log(email);
      await confirmPasswordReset(auth, value, password.cnfPassword);
      setClicked(false)
      goToHome();
      
    } catch (error) {
      setClicked(false)
      // console.log(error.code);
      setError(error.code);
    }
  };

  return (
    <div className="login-container">
      <div className="login-c-main">
        <img src={Logo} alt="Logo" />
        <h2>Set Password </h2>

        <div
          style={{
            width: "450px",
          }}
        >
          <label htmlFor="newPass">New Password</label>
          <TextField
            autoFocus
            required
            fullWidth={true}
            sx={{ backgroundColor: "#F4F7FE", mb: 2 }}
            name="newPassword"
            placeholder="Password"
            id="newpass"
            value={password.newPassword}
            onChange={handleInput}
            type={showPass[0] ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleMouseDownPassword(0)}
                    onMouseDown={() => handleMouseDownPassword(0)}
                  >
                    {showPass[0] ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <label htmlFor="cnfPass">Confirm New Password</label>
          <TextField
            fullWidth={true}
            sx={{ backgroundColor: "#F4F7FE", mb: 2 }}
            name="cnfPassword"
            placeholder="Confirm Password"
            id="cnfPass"
            value={password.cnfPassword}
            onChange={handleInput}
            type={showPass[1] ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleMouseDownPassword(1)}
                    onMouseDown={() => handleMouseDownPassword(1)}
                  >
                    {showPass[1] ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          {password.cnfPassword != password.newPassword && (
            <Alert icon={false} severity="error">
              Password doesn't Match
            </Alert>
          )}
          {error && (
            <Alert icon={false} severity="error">
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            disabled={clicked}
            sx={{ mt: 3, mb: 2, borderRadius: "70px" }}
            onClick={changePassword}
          >
            Continue
          </Button>
        </div>
      </div>
      <img className="login-c-image" src={LoginImage} />
    </div>
  );
}
