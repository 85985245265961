import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../firebaseConfig";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useNavigate,useLocation } from "react-router-dom";
import Logo from "../../assets/NCLUDE.AI.png";
import LoginImage from "../../assets/LoginImage.svg";
import "./PasswordMgmt/style.css";
import { AuthContext } from "../Context/AuthContext";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userState, clicked, setClicked } = useContext(AuthContext);

  useEffect(() => {
    if (userState && userState.set) {
      if(location?.state?.github === 'true') {
        console.log("inside login state",location.state.github);
        console.log(`/report/actions/${location.state.uuid}`);
        navigate(`/report/actions/${location.state.uuid}`,{
          state: {
            uid: localStorage.getItem('uid')
          }
        });
        return;
      }
      setClicked(false);
      console.log("navigating to schedule");
      navigate("/schedule");
    }
  }, [location.state, navigate, setClicked, userState]);

  const [error, setError] = useState("");
  const handleSubmit = function (e) {
    // console.log(credentials);
    e.preventDefault();
    setClicked(true);
    signInWithEmailAndPassword(auth, credentials.email, credentials.password)
      .then(async (user) => {
        const uid = user.user.uid;
        const usrDocRef = await getDoc(doc(db, "Users/" + uid));
        const usrDoc = usrDocRef.data();

        localStorage.setItem("uid", uid);
        // set roles in sessionStorage
        // console.log(usrDoc);
        if (usrDoc.status === "Active") {
          setClicked(false);
          console.log("Is Acitve", usrDoc.status);
          // console.log("userData", user);
          // console.log("Signed In as " + uid);
          if(location?.state?.github === 'true') {
            console.log("inside login state",location.state.github);
            console.log(`/report/actions/${location.state.uuid}`);
            navigate(`/report/actions/${location.state.uuid}`,{
              state: {
                uid: uid
              }
            });
            return;
          } else {
            navigate("/schedule", {
              uid: uid,
            });
          }
        } else {
          // signOut(auth);
          setClicked(false);
          setError("Id Inactive");
        }
      })
      .catch((error) => {
        setClicked(false);
        console.log(error);
        switch (error.code) {
          case "auth/user-not-found":
            setError("Email not found!");
            break;

          case "auth/wrong-password":
            setError("Incorrect Password!");
            break;

          case "auth/invalid-email":
            setError("Incorrect Email!");
            break;

          default:
            setError("Unknown Error");
            break;
        }
      });
  };

  const [credentials, setCredential] = useState({
    email: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false);
  const handleMouseDownPassword = () => setShowPass(!showPass);

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCredential({ ...credentials, [name]: value });
  };

  return (
    userState.uid === "" && (
      <div className="login-container">
        <div className="login-c-main">
          <img src={Logo} height={'15px'}/>
          <h2>Log In</h2>
          <form
            style={{
              width: "450px",
            }}
            onSubmit={handleSubmit}
          >
            <label htmlFor="email">Email Address</label>
            <TextField
              autoFocus
              required
              fullWidth={true}
              sx={{ backgroundColor: "#F4F7FE", mb: 3 }}
              autoComplete="email"
              name="email"
              placeholder="Email"
              id="email"
              value={credentials.email}
              onChange={handleInput}
            />
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <label htmlFor="pass">Password</label>
              <Link
                style={{ marginLeft: "auto", color: "#707EAE" }}
                href="/forgotPassword"
              >
                Forgot Password{" "}
              </Link>
            </div>
            <TextField
              required
              fullWidth={true}
              sx={{ backgroundColor: "#F4F7FE", mb: 3 }}
              name="password"
              placeholder="Password"
              id="pass"
              value={credentials.password}
              onChange={handleInput}
              type={showPass ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleMouseDownPassword(0)}
                      onMouseDown={() => handleMouseDownPassword(0)}
                    >
                      {showPass ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && (
              <Alert icon={false} severity="warning">
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth={true}
              disabled={clicked}
              variant="contained"
              sx={{ mt: 3, mb: 2, borderRadius: "70px" }}
              // onClick={changePassword}
            >
              {clicked ? "Please Wait" : "Log In"}
            </Button>
            <Link href="/register">New user? Register</Link>
          </form>
        </div>
        <img className="login-c-image" src={LoginImage} />
      </div>
    )
  );
}
