import React, { useState, useContext,useCallback, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Box } from "@mui/material";
import { functions } from "../../../firebaseConfig";
import { httpsCallable } from "firebase/functions";
import { AuthContext } from "../../Context/AuthContext";
import axios from "axios";
import { APIConst } from "../../../API const/APIConst";
import { PmContext } from "../../Context/PmContext";
import { doc, getDoc } from "firebase/firestore";
import { createIssue, fetchJiraAssignees, fetchJiraDetails, fetchJiraProjects, fetchRefreshTokenAndCloudIdFromFirestore, refreshToken, refreshTokens, saveJiraDetails, saveJiraProjectMapping, SetPmTool } from "../../../Utils/JiraFunctions";

const Projects = (props) => {
  const { onClose, open } = props;
  const { clicked, setClicked } = useContext(AuthContext);
  const { enableTool,setEnableTool } = useContext(PmContext);
  const handleClose = () => {
    setClicked(false);
    onClose();
  };
  const [details, setDetails] = useState({
   projectName: "",
   userId: props.userId,
   defaultStack: ""
  });
  const [jira,setJira] = useState({
    project: '',
    assignee : ''
  });
  const [jiraProjects,setJiraProjects] = useState([]);
  const [jiraAssignee,setJiraAssignee] = useState([]);
  const [submitButtonText,setSubmitButtonText] = useState('Add')



const getJiraProjects = async () => {
  const [refreshToken,cloudId] = await fetchRefreshTokenAndCloudIdFromFirestore(props.userId);
  const accessToken = await refreshTokens(props.userId,refreshToken,APIConst.jiraServerUrl);
  console.log("new access token",accessToken);
  const jiraProjects = await fetchJiraProjects(APIConst.jiraServerUrl,cloudId,accessToken);
  console.log("jira projects",jiraProjects);
  setJiraProjects(jiraProjects);
} 

  const getJiraAssignees = async () => {
    const [refreshToken,cloudId] = await fetchRefreshTokenAndCloudIdFromFirestore(props.userId);
    const accessToken = await refreshTokens(props.userId,refreshToken,APIConst.jiraServerUrl);
    const assignees = await fetchJiraAssignees(APIConst.jiraServerUrl,cloudId,accessToken);
    console.log('jira users',assignees);
    // Filter the assignees based on the accountType being 'atlassian'
    const filteredAssignees = assignees.filter(user => user.accountType === 'atlassian');
    setJiraAssignee(filteredAssignees);
  }


  const handleInput = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
    // console.log(details);
  };

  const handelInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log(name, value);

    setJira({ ...jira, [name]: value });
    console.log(jira);
    console.log(name,value);
  };
  const [res, setRes] = useState({});
  const createProject = async () => {

    setSubmitButtonText("Processing");

    try {
      // Prepare the payload
      const payload = {
        projectName: details.projectName,
        userId: details.userId,
        defaultStack: details.defaultStack
      };
  
      // Make the POST request using axios
      const response = await axios.post(APIConst.createProject, payload, {
        headers: {
          "Content-Type": "application/json",
          "x-api-key": "d1b2c3a4-e5f6-7890-g1h2-i3j4k5l6m7n8", // Add the x-api-key to the header
        },
        timeout: 70000, // Set the timeout to 70 seconds
      });
  
      console.log(response);
      if(props.setNewProjectId) {
        props.setNewProjectId(response.data.projectId);
      }
      
      // Save Jira details
      const docPath = `Projects/${response.data.projectId}`;
      await saveJiraProjectMapping(
        docPath,
        jira.project.id,
        jira.assignee.accountId,
        jira.project.name,
        jira.assignee.displayName,
      );
  
      setSubmitButtonText("Added");

      // Fetch updated projects
      if(props.fetchProjects) {
        await props.fetchProjects();
      }

  
      // Reset the form details and close the modal
      setDetails({
        projectName: "",
        userId: props.userId,
      });
      onClose();
  
    } catch (err) {
      // window.alert(err);
      console.log(err);
    }
  };

  const fetchProjectJiraDetails = async () => {
    const docPath = `Projects/${props.projectData.projId}`;
    const res = await fetchJiraDetails(docPath);
    if(res.jiraProjectId && res.jiraUserId) {
      const foundProject = jiraProjects.find(
        (project) => project.id === res.jiraProjectId
      );
      const foundUser = jiraAssignee.find(
        (user) => user.accountId === res.jiraUserId
      );
      console.log("found details",foundProject,foundUser);
      if(foundProject != undefined && foundUser != undefined) {
        setJira({
          ...jira,
          project: foundProject,
          assignee: foundUser,
        });
      }
    }
  }

  const editProject = async () => {

    setSubmitButtonText("Processing");

    const docPath = `Projects/${props.projectData.projId}`;
    console.log(docPath);
      await saveJiraProjectMapping(
        docPath,
        jira.project.id,
        jira.assignee.accountId,
        jira.project.name,
        jira.assignee.displayName,
      );
      onClose();
  }


  useEffect(()=> {
    if(open && enableTool.jira) {
      getJiraProjects();
      getJiraAssignees();
      console.log("project deeeeee",props.projectData);
    }
  },[open]);

  useEffect(() => {
    if(props.to === 'edit') {
      fetchProjectJiraDetails();
    }
  },[jiraProjects,jiraAssignee]);


  useEffect(() => {

    // const setPmTool = async () => {
    //   const res = await fetchRefreshTokenAndCloudIdFromFirestore(props.userId);
    //   console.log("tokens and cloud if at schedule",res);
    //   if(res!=null && res!== undefined) {
    //     setEnableTool({
    //       ...enableTool,
    //       jira: true
    //     });
    //   } else {
    //     setEnableTool({
    //       ...enableTool,
    //       jira: false
    //     });
    //   }
    // }


    SetPmTool(props.userId,setEnableTool,enableTool);
  },[]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: "20px",
          width: "400px",
          padding: "16px",
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <h1
        style={{
          flexDirection: "row",
          padding: "1.2rem",
          alignItems: "center",
          color: "#11047A",
          display: "flex",
          fontSize: "25px",
        }}
      >
        <p>{props.to==='create'?"Create New Project":"Edit project"}</p>
        <button
          onClick={handleClose}
          style={{
            width: "40px",
            height: "40px",
            background: "#f4f7fe",
            marginLeft: "auto",
            borderRadius: "12px",
            border: "none",
          }}
        >
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.43 0.244723C10.1145 -0.0707873 9.60485 -0.0707873 9.28934 0.244723L5.33333 4.19264L1.37732 0.236633C1.06181 -0.0788775 0.552143 -0.0788775 0.236633 0.236633C-0.0788775 0.552143 -0.0788775 1.06181 0.236633 1.37732L4.19264 5.33333L0.236633 9.28934C-0.0788775 9.60485 -0.0788775 10.1145 0.236633 10.43C0.552143 10.7455 1.06181 10.7455 1.37732 10.43L5.33333 6.47402L9.28934 10.43C9.60485 10.7455 10.1145 10.7455 10.43 10.43C10.7455 10.1145 10.7455 9.60485 10.43 9.28934L6.47402 5.33333L10.43 1.37732C10.7375 1.0699 10.7375 0.552143 10.43 0.244723Z"
              fill="#707EAE"
            />
          </svg>
        </button>
      </h1>
      <Box
        sx={{
          padding: "16px 24px",
        }}
      >
        {props.to==='create' && <>
        <TextField
          name="projectName"
          label="Project Name"
          value={details.projectName}
          onChange={handleInput}
          sx={{
            width: "100%",
            mt: "1em",
            color: "#11047A",
            background: "#F4F7FE",
          }}
        />
        <TextField
            select
            name="defaultStack"
            label="Default Stack"
            value={details.defaultStack}
            onChange={handleInput}
            sx={{
              width: "100%",
              mt: "1em",
              color: "#11047A",
              background: "#F4F7FE",
              maxHeight: "200px",
              overflowY: "auto"
            }}
          >
            <MenuItem value={null}></MenuItem>
            <MenuItem value="react">React</MenuItem>
            <MenuItem value="angular">Angular</MenuItem>
            <MenuItem value="next">Next.Js</MenuItem>
            <MenuItem value="vue">Vue</MenuItem>
          </TextField>
        </>  
        }
        {
          props.to==='edit' && <h2>
            {props.projectData.projectName}
          </h2>
        }

        
        {/* <TextField
          select
          name="role"
          label="Role"
          value={details.role}
          onChange={handleInput}
          sx={{
            width: "100%",
            mt: "1em",
            color: "#11047A",
            background: "#F4F7FE",
          }}
        >
          <MenuItem value={""}></MenuItem>
          <MenuItem value={"Admin"}>Admin</MenuItem>
          <MenuItem value={"Manager"}>Manager</MenuItem>
          <MenuItem value={"Developer"}>Developer</MenuItem>
          <MenuItem value={"Tester"}>Tester</MenuItem>
          <MenuItem value={"Viewer"}>Viewer</MenuItem>
        </TextField> */}
        {enableTool.jira && 
          <div>
          <TextField
            select
            name="project"
            label="Jira project"
            value={jira.project}
            onChange={handelInput}
            sx={{
              width: "100%",
              mt: "1em",
              color: "#11047A",
              background: "#F4F7FE",
              maxHeight: "200px",
              overflowY: "auto"
            }}
          >
            <MenuItem value={" "}></MenuItem>
            {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
            {jiraProjects.map((pro) => {
              return (
                <MenuItem key={pro.id} value={pro}>
                  {pro.name}
                </MenuItem>
              );
            })}
            {!jiraProjects.length && <MenuItem>No Projects</MenuItem>}
          </TextField>

          <TextField
            select
            name="assignee"
            label="Jira assignee"
            value={jira.assignee}
            onChange={handelInput}
            sx={{
              width: "100%",
              mt: "1em",
              color: "#11047A",
              background: "#F4F7FE",
              maxHeight: "200px",
              overflowY: "auto"
            }}
          >
            <MenuItem value={" "}></MenuItem>
            {/* <MenuItem value={"Admin"}>Admin</MenuItem> */}
            {jiraAssignee.map((pro) => {
              return (
                <MenuItem key={pro.accountId} value={pro}>
                  {pro.displayName}
                </MenuItem>
              );
            })}
            {!jiraAssignee.length && <MenuItem>No Members</MenuItem>}
          </TextField>
          </div>
        }
        <Button
          fullWidth
          variant="contained"
          disabled={clicked}
          sx={{
            mt: "1em",
            color: "#fff",
            background: "#11047A",
            borderRadius: "70px",
          }}
          onClick={props.to==='create'?createProject:editProject}
        >
          {submitButtonText}
        </Button>
      </Box>
    </Dialog>
  );
};

export default Projects;
