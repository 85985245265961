import { initializeApp } from "firebase/app";
import {
  collection,
  doc,
  getFirestore,
  setDoc,
  Timestamp,
  FieldValue,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import React from "react";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
import { getFunctions } from "firebase/functions";
/*
Firebase Configs Change this when Switching from dev to Prod or vice versa
*/

const prod = true;

const firebaseConfigDev = {
  apiKey: "AIzaSyBnLifgkEmh9l6Blrx5RsWYYXettRwX9fY",
  authDomain: "dev-web-accessibility-gcp.firebaseapp.com",
  projectId: "dev-web-accessibility-gcp",
  storageBucket: "dev-web-accessibility-gcp.appspot.com",
  messagingSenderId: "1061565560959",
  appId: "1:1061565560959:web:838b7a27f41978c9d0674a",
  measurementId: "G-JJ69S7Q32B",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyBY6Ukz_fMpXyHjuA1GOYndDyN-z1AFqHw",
  authDomain: "web-accessibility-gcp-prod.firebaseapp.com",
  projectId: "web-accessibility-gcp-prod",
  storageBucket: "web-accessibility-gcp-prod.appspot.com",
  messagingSenderId: "284523947007",
  appId: "1:284523947007:web:29ce8ca3139a533d20bdca",
  measurementId: "G-WJEEPX4K3S",
};

const app = prod
  ? initializeApp(firebaseConfigProd)
  : initializeApp(firebaseConfigDev);

export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app, "gs://istem-bucket");
export const functions = getFunctions(app, "asia-south1");

export const userStateValues = {
  set: "set",
  notSet: "notSet",
  loggedOut: "loggedOut",
};

/*
Following are the functions for Firebase Authentication
*/

export function login(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then(function (user) {
      console.log("Signed In as " + user.user.uid);
      console.log("SignIn Successfull");
    })
    .catch((error) => {
      console.log(error);
    });
}

export async function signup(firstName, lastName, email, isError) {
  try {
    const dateTime = new Date().getTime();
    // console.log(new Timestamp(Math.floor(dateTime / 1000), dateTime % 1000));
    const user = await createUserWithEmailAndPassword(auth, email, "password");

    const uid = user.user.uid;
    // console.log("User created with UId " + uid);

    const userRef = doc(db, "Users/" + uid);

    const userData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      signUpTimestamp: new Timestamp(
        Math.floor(dateTime / 1000),
        dateTime % 1000
      ),
      uid: uid,
    };

    setDoc(userRef, userData).then(
      "Firebase Instance Created for the User " + uid
    );

    const actionCodeSettings = {
      url: "http://localhost:3000/",
      handleCodeInApp: true,
    };

    await sendPasswordResetEmail(auth, email, actionCodeSettings);
  } catch (error) {
    console.log(error);
    if (error.code === "auth/email-already-in-use") {
      // console.log('aaa')
      isError.code = error.code;
    } else {
      console.log(error);
    }
  }
}

export function logout() {
  return signOut(auth);
}

export function resetPassword(email) {
  // return sendEmailVerification(auth,email)
  return sendPasswordResetEmail(auth, email);
}
