import "./App.css";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
// import Sidebar from './Components/Sidebar/Sidebar';
import Request from "./Components/Requests/Request";
import Approved from "./Components/Requests/Approved";
import Members from "./Components/Members/Members";
import Schedule from "./Components/Schedule/Schedule";
import Report from "./Components/Reports/Reports";
import Metrics from "./Components/Metrics/Metrics";
import Inventory from "./Components/Inventory/Inventory";
import Security from "./Components/Security/Security";
import Latest from "./Components/Reports/Elements/LatestReport/LatestReport";
import Scanned from "./Components/Reports/Elements/ScannedResult/ScannedResult";
import Entire from "./Components/Reports/Elements/ScannedResult/EntireReport";
import PageWise from "./Components/Reports/Elements/ScannedResult/PageWise";
import Details from "./Components/Reports/Elements/ScannedResult/Elements/Details/Details";
import Login from "./Components/Authentication/Login";
import SignUp from "./Components/Authentication/SignUp";
import Register from "./Components/Authentication/Register";
import ForgotPassword from "./Components/Authentication/PasswordMgmt/ForgotPassword";
import ResetPassword from "./Components/Authentication/PasswordMgmt/ResetPassword";
import PDetails from "./Components/Reports/Elements/ScannedResult/Elements/Details/PDetails";
import ManualDetails from "./Components/Reports/Elements/ScannedResult/Elements/Manual/ManualDetails";
import Prev from "./Components/Reports/Elements/Prev/Prev";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db, userStateValues } from "./firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { AuthContext } from "./Components/Context/AuthContext";
import PageNotFound from "./Components/404Page/PageNotFound";
import GithubActions from "./Components/Reports/Elements/githubAction";
import Settings from "./Components/Settings/Settings";
import LogOut from "./Components/Authentication/Logout";
function App() {
  const theme = createTheme({
    typography: {
      fontFamily: ["DM Sans", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#11047A",
      },
    },
  });

  const { setUserState, initialState } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  // console.log(location);
  // console.log(params);
  useEffect(() => {
    // console.log(location);

    if (location?.pathname.includes("/reports/")) {
      const arr = location?.pathname?.split("/");
      // console.log({ arr });
      if (arr.length == 2) return;
    }
    const unsub = onAuthStateChanged(auth, async (userCred) => {
      // console.log("Auth Listner Trigrieed", userCred);
      if (userCred) {
        const newState = { ...initialState };
        newState.uid = userCred.uid;
        let userDoc = await getDoc(doc(db, "Users/" + userCred.uid));
        let userData = userDoc.data();
        // console.log("userData in Database", userData);
        if (userData.status == "Active") {
          newState.role = userData.role;
          newState.orgId = userData.orgId;
          newState.name = userData.name;
          newState.email = userData.email;
          newState.phoneNo = userData.phoneNumber;
          newState.set = userData.status == "Active" ? true : false;
          // console.log(newState);
          // console.log("userCred", userCred);
          setUserState(newState);
          // console.log(location);
          // console.log(location?.pathname);
          if (location?.pathname == "/") {
            navigate("/schedule");
          }
        }
      } else {
        // console.log("bb", userCred);
        setUserState({
          role: "",
          uid: "",
          orgId: "",
          name: "",
          email: "",
          set: false,
        });
      }
    });
    return unsub;
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/logout" element={<LogOut />} />
          <Route path="/requests" element={<Request />} />
          <Route path="/approved" element={<Approved />} />
          <Route path="/members" element={<Members />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/report/actions/:uuid" element={<GithubActions />} />
          <Route path="/reports" element={<Report />} />
          <Route path="/reports/:id" element={<Report />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/inventory" element={<Inventory />} />

          <Route path="/metrics" element={<Metrics />} />
          <Route path="/security" element={<Security />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="reports/latest" element={<Latest />} />
          <Route path="reports/scanned-result" element={<Scanned />} />
          <Route
            path="/reports/scanned-result/:domain/:reqId"
            element={<Scanned />}
          />
          <Route path="reports/:id/prevReports" element={<Prev />} />
          <Route path="reports/prevReports" element={<Prev />} />
          <Route
            path="reports/scanned-result/entire-report"
            element={<Entire />}
          />
          <Route
            path="reports/scanned-result/pagewise-report"
            element={<PageWise />}
          />
          <Route
            path="reports/scanned-result/:domain/:reqId/pagewise-report"
            element={<PageWise />}
          />
          <Route
            path="reports/scanned-result/pagewise-report/details"
            element={<Details />}
          />
          <Route
            path="reports/scanned-result/:domain/:reqId/pagewise-report/details"
            element={<Details />}
          />
          <Route
            path="reports/scanned-result/entire-report/details"
            element={<Details />}
          />
          {/* Below routes dosn't have Authentication setup */}
          <Route
            path="reports/scanned-result/entire-report/mdetails"
            element={<ManualDetails />}
          />
          <Route
            path="reports/scanned-result/entire-report/pdetails"
            element={<PDetails />}
          />
          <Route
            path="reports/scanned-result/:domain/:reqId/pagewise-report/pdetails"
            element={<PDetails />}
          />
          <Route
            path="reports/scanned-result/pagewise-report/pdetails"
            element={<PDetails />}
          />
          <Route
            path="reports/scanned-result/:domain/:reqId/pagewise-report/mdetails"
            element={<ManualDetails />}
          />
          <Route
            path="reports/scanned-result/pagewise-report/mdetails"
            element={<ManualDetails />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
