import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import jira from "../../assets/atlassian-jira-logo-large.png";
import { alpha, styled } from "@mui/material/styles";
import { pink } from "@mui/material/colors";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { Visibility } from "@material-ui/icons";
import { PmContext } from "../Context/PmContext";
import { APIConst } from "../../API const/APIConst";
import "./Settings.css";

const label = { inputProps: { "aria-label": "Color switch demo" } };

export default function ActionAreaCard(props) {
  console.log(props.isLoggedIn);

  const disconnectJira = () => {
    if(props.isLoggedIn) {
      props.disconnect();
    }
    console.log("disconnected");
  };

  return (
    <Card sx={{ maxWidth: 345,padding: '2rem 1rem' }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="140"
          image={jira}
          alt="Jira"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            Jira
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Integrate jira tools into the web accessibility portal for seamless
            user experience
            <div style={{ marginTop: "2rem",display: 'flex', justifyContent: 'center' }}>
              {/* <Switch {...label} color="secondary" onClick={()=> {
              setSwitchChecked(!switchChecked);
              setEnableTool({...enableTool, jira:!enableTool.jira})
            }} 
            checked={switchChecked} 
            /> */}
              <Button
                variant="contained"
                style={{ width: '80%' }}
                href={props.isLoggedIn ? "" : APIConst.jiraAuthUrl}
                onClick={disconnectJira}
                className={props.isLoggedIn!== false ? "danger" : ""}
              >
                {props.isLoggedIn !== false ? "Disconnect" : "Connect"}
              </Button>
            </div>
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
