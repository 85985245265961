/* eslint-disable react/jsx-pascal-case */
import "./reports.css";
import React, { useContext,useEffect } from "react";
import Previous_Reports from "./Elements/Previous_Reports";
import Sidebar from "../Sidebar/Sidebar";
import { AuthContext } from "../Context/AuthContext";

export default function Reports() {
  

  return (
    // userState.set && (
    <>
      <Sidebar />
      <main>
        <div className="reports">
          <div className="top-item">
            <h1 className="heading">Reports</h1>
          </div>
          <div className="Container">
            <Previous_Reports />
          </div>
        </div>
      </main>
    </>
  );
  // );
}
