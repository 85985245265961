import React from "react";
import "./ScannedSnippet.css";
import { NavLink, useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { auth, db } from "../../../../../firebaseConfig";

export default function ScannedSnippet(props) {
  const navigate = useNavigate();

  const navigateNext = async () => {
    // console.log("Snip", props.result);
    // console.log("PWM", props.manual);
    const url = props.url;
    const reqId = props.latestRequest;
    let changeURL = "";
    for (let k = 0; k < url.length; k++) {
      if (url[k] !== "/") changeURL = changeURL.concat(url[k]);
      else {
        changeURL = changeURL.concat("_");
      }
    }
    const userID = auth?.currentUser?.uid ?? props?.userID ?? "";

    // console.log("Badurl", props.badUrl);
    // console.log("url", url);
    // console.log("reqID", reqId);
    // console.log("changeURL", changeURL);
    const failRef = collection(
      db,
      "Projects/" +
        props.projectId +
        "/Reports/" +
        props.siteName +
        "/" +
        props.badUrl +
        "/" +
        reqId +
        "/pages/" +
        changeURL +
        "/fails"
    );
    const passRef = collection(
      db,
      "Projects/" +
        props.projectId +
        "/Reports/" +
        props.siteName +
        "/" +
        props.badUrl +
        "/" +
        reqId +
        "/pages/" +
        changeURL +
        "/pass"
    );
    const warnRef = collection(
      db,
      "Projects/" +
        props.projectId +
        "/Reports/" +
        props.siteName +
        "/" +
        props.badUrl +
        "/" +
        reqId +
        "/pages/" +
        changeURL +
        "/warnings"
    );
    // console.log("FAILS ", failRef);
    // console.log("Passes", passRef);
    // console.log("Warnings", warnRef);
    const manualRef = collection(db, "Manuals");
    const fails = await getDocs(failRef);
    const passes = await getDocs(passRef);
    const warnings = await getDocs(warnRef);
    const manual = await getDocs(manualRef);
    const manualArr = [];
    const failures = [];
    const passed = [];
    const warns = [];
    // console.log("FAILS ", fails);
    // console.log("Passes", passes);
    // console.log("Warnings", warnings);
    // console.log("Manual Length", manualArr);
    // console.log("NAVIGATING");

    fails.forEach((doc) => {
      failures.push(doc.data());
    });
    passes.forEach((doc) => {
      passed.push(doc.data());
    });
    warnings.forEach((doc) => {
      warns.push(doc.data());
    });

    // const manualArr = props.manual;
    // const warns = props.warnings;

    // console.log("FAILS LENGTH", failures);
    // console.log("Passes Length", passed);
    // console.log("Warnings Length", warns);
    // console.log("Manual Length", manualArr);
    // console.log("NAVIGATING");
    navigate("pagewise-report", {
      state: {
        badUrl: props.badUrl,
        result: props.result,
        siteName: props.siteName,
        userID,
        url: props.url,
        manual: manualArr,
        fails: failures,
        passes: passed,
        warnings: warns,
        latestRequest: props.latestRequest,
        projectId: props.projectId
      },
    });
  };

  return (
    <div className="page_wise">
      <div className="page">
        <h2 className="PW_URL" style={{ fontSize: "16px" }}>
          {props.url}
        </h2>
        <div style={{ marginTop: "6px" }}>
          <text aria-label="error-count" className="PW_tag">
            Errors :<span className="history_issue">{props.issues}</span>
          </text>
        </div>
        <text aria-label="warning-count" className="PW_tag">
          Warnings :<span className="history_issue">{props.warns}</span>
        </text>
      </div>
      <div style={{ marginLeft: "16px", display: "inline-flex" }}>
        <button className={props.buttonType} onClick={navigateNext}>
          <text className={props.buttonLink} style={{ textDecoration: "none" }}>
            View Details
          </text>
        </button>
      </div>
    </div>
  );
}
