import React from 'react'
import './Illustration.css'
import illustration from '../../../assets/Illustration2.svg'


export default function Illustration() {
    return (
        <div className='illustration'>
            <div className='illus_contain'>
            <h1 className='illus_heading'>Tutorials and FAQs</h1>
            <button className='illus_button'>How Scheduling works?</button><br/>
            <button className='illus_button'>What accessibility Compilance standard should I select?</button><br/>
            <button className='illus_button'>What are Checkpoints?</button><br/>
            <button className='illus_button'>What is a document scan on webpage?</button><br/>
            <img className = 'illus_image' src={illustration} alt='illus'/>
            </div>
        </div>
    )
}
